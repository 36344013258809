import React, { Component } from 'react';
import './FormClickBox.css'
const FormClickBlock = props => {
    //console.log(props)
    return (
        <div 
            //className="FormClickBlock" 
            //className={this.state[props.id] ? 'your_className': null} 
            className = {props.clickedBoxFun && props.clickedBoxFun() || props.clickedBox ? 'FormClickBlock clicked': 'FormClickBlock'}
            id={props.id} 
            name={props.id}
            onClick = {e =>  props.onBoxClick(props.id, props.value)}
        >
            <div class="rowBox">
                <div class="colBox">
                    <p>{props.name}</p>
                </div>
                <div class="colBox">
                    <img src={props.img} alt={props.name}/>
                </div>
            </div>
        </div>
    )
}

export default FormClickBlock;