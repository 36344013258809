import React from 'react';
import './FormSelect.css';
import Tooltip from 'react-tooltip-lite';
import tp from './images/tooltip.png'

const FormInput = props => {
    let i = 0
    let vC = "col-2"
    if (props.valid) vC = "col-2"; else vC = "col-2 error"
return ( 
        <div className = "form-select">
            <div className="row">
                <div className="col-1">
                    <label htmlFor={props.id}>{props.label}
                        
                    <Tooltip content={props.tooltip} direction="left" className="tooltipIco" > 
                              <img src={tp} alt="tooltip"/>
                        </Tooltip>
                    </label>
                </div>
                <div className={vC}>
                    <input  
                        id={props.id}
                        name={props.id}
                        //min = {50}
                       // max = {300}
                       defaultValue={props.choosen}
                        type="number"
                    /* onChange={props.handleChange}*/
                    onChange={ e => props.onInputChange(e)}
                    />
                        
                        
                   
                    <div className="error-info">Uzupełnij pole prawidłowymi wartościami (50-300m)</div>
                </div>
            </div>
        </div>
)
    
}

export default FormInput;