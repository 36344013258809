import { getDataProviderValue } from './dataProvider'

const assert = (newValue, oldValue, description) => {
  for (const i in newValue) {
    if (oldValue[i] === null) {
      console.warn('Missing value ', { i }, description)
    }

    if (newValue[i] !== oldValue[i]) {
      console.warn(
        'Wrong value ',
        i,
        { newValue: newValue[i], oldValue: oldValue[i], i },
        description
      )
    }
  }
}

const parameters = () => {
  return getDataProviderValue('parameters')
}

const TABLE2 = () => {
  const oldValue = {
    1: 0.84,
    2: 0.84,
    7: 0.92,
    3: 0.7,
    5: 0.6,
    8: 0.98,
    9: 3,
    10: 2.8,
    11: 3.2,
    6: 0.76,
    4: 0.84,
    12: 0.33
  }

  assert(getDataProviderValue('type_of_heating_before'), oldValue, 'table2')

  return getDataProviderValue('type_of_heating_before')
}

const TABLE2_3 = () => {
  const oldValue =
    //Table 2: Type of heating - Efficiency After
    {
      1: 0.96, //Kocioł gazowy
      2: 0.94, //Kocioł olejowy
      7: 0.92, //Energia elektryczna
      3: 0.8, //Kocioł na biomasę (pelety)
      5: 0.82, //Kocioł węglowy
      8: 0.98, //Ciepło sieciowe
      9: 3, //Pompa ciepła
      10: 3.2, //Powietrzna pompa ciepła
      11: 3.6, //Gruntowa pompa ciepła
      6: 0.82, //Kocioł węglowy - ekogroszek
      4: 0.96, //Kocioł na gaz płynny LPG"
      12: 1, //kolektor
      0: 0.92,
      100: 0.84
    }

  assert(getDataProviderValue('type_of_heating_after'), oldValue, 'TABLE2_3')

  return getDataProviderValue('type_of_heating_after')
}

const TABLE2_4 = () => {
  const oldValue = {
    1: 0.2786,
    2: 0.6508,
    7: 0.7743,
    3: 0.4865,
    5: 0.2940,
    8: 0.3240,
    9: 0.7743,
    10: 0.7743,
    11: 0.7743,
    6: 0.3115,
    4: 0.5268,
    12: 0.7743,
    0: 0.7743,
    100: 0.2786
  }

  assert(getDataProviderValue('type_of_heating_fuel'), oldValue, 'TABLE2_4')

  return getDataProviderValue('type_of_heating_fuel')
}

const TABLE2_8 = () => {
  const oldValue = {
    //Table 2: Type of heating - Współczynnik emisji kgCO2/ MWh
    1: 199.55,
    2: 266.76,
    7: 778.0,
    3: 403.2,
    5: 338.58,
    8: 378.0,
    9: 719.0,
    10: 719.0,
    11: 719.0,
    6: 338.58,
    0: 719,
    100: 199.55,
    4: 227.16
  }

  assert(
    getDataProviderValue('type_of_heating_emission_co2'),
    oldValue,
    'TABLE2_8'
  )
  return getDataProviderValue('type_of_heating_emission_co2')
}

const TABLE2_9 = () => {
  const oldValue = {
    //Table 2: Type of heating - Współczynnik emisji kgPM/ MWh
    1: 0.001,
    2: 0.007,
    7: 0.029,
    3: 0.068,
    5: 1.498,
    8: 0.021,
    9: 0.029,
    10: 0.029,
    11: 0.029,
    6: 0.313,
    0: 0.029,
    100: 0.001,
    4: 0.004
  }

  assert(
    getDataProviderValue('type_of_heating_emission_pm'),
    oldValue,
    'TABLE2_9'
  )
  return getDataProviderValue('type_of_heating_emission_pm')
}

const TABLE2_10 = () => {
  const oldValue = {
    //Table 2: Type of heating - Współczynnik emisji kgLZO/ MWh
    1: 0.009,
    2: 0.002,
    7: 0.0,
    3: 0.032,
    5: 1.44,
    8: 0.0,
    9: 0.0,
    10: 0.0,
    11: 0.0,
    6: 0.443,
    0: 0,
    100: 0.009,
    4: 0.007
  }

  assert(
    getDataProviderValue('type_of_heating_emission_lzo'),
    oldValue,
    'TABLE2_10'
  )
  return getDataProviderValue('type_of_heating_emission_lzo')
}

const TABLE3 = () => {
  const oldValue = {
    1: 4118.6,
    2: 3547.5,
    3: 3729.4,
    4: 3986.2,
    5: 3623.3,
    6: 3938.5,
    7: 3395.0,
    8: 3413.9,
    9: 3528.9,
    10: 3908.5,
    11: 3729.0,
    12: 3665.2,
    13: 3988.7,
    14: 3903.2,
    15: 3758.9,
    16: 3461.7,
    17: 3487.9,
    18: 3643.3,
    19: 3609.5,
    20: 3662.5,
    21: 3331.9,
    22: 3640.0,
    23: 3786.6,
    24: 3760.1,
    25: 3789.4,
    26: 3758.9,
    27: 3671.7,
    28: 3980.2,
    29: 3914.6,
    30: 3464.8,
    31: 4104.6,
    32: 3342.0,
    33: 3801.2,
    34: 3610.5,
    35: 3596.2,
    36: 3627.8,
    37: 3818.7,
    38: 3400.2,
    39: 3525.1,
    40: 3870.3,
    41: 3584.8,
    42: 3880.0,
    43: 3342.4,
    44: 3729.9,
    45: 4299.6,
    46: 3355.1,
    47: 3725.6,
    48: 3282.2,
    49: 3303.4,
    50: 3832.4,
    51: 3671.6,
    52: 3652.2,
    53: 3637.8,
    54: 3516.8,
    55: 3876.7,
    56: 3660.6,
    57: 4587.0,
    58: 3990.4,
    59: 3579.0
  }

  assert(getDataProviderValue('table_3'), oldValue, 'TABLE3')
  return getDataProviderValue('table_3')
}

const TABLE2_11 = () => {
  const oldValue = {
    //Table 2: Type of heating - Współczynnik emisji kgLZO/ MWh
   
    1: 0.216,
    2: 0.349,
    7: 0.576,
    3: 0.0,
    5: 0.601,
    8: 0.36,
    9: 0.576,
    10: 0.576,
    11: 0.576,
    6: 0.986,
    0: 0.576,
    100: 0.84,
    4: 0.216,
    12: 778
  }

  assert(
    getDataProviderValue('type_of_heating_emission_lzo_2'),
    oldValue,
    'TABLE2_11'
  )
  return getDataProviderValue('type_of_heating_emission_lzo_2')
}

const TABLE_D = () => {
  const oldValue = {
    ///TABLE_D()[db5]
    1: 170,
    2: 155,
    3: 145,
    4: 120,
    5: 80,
    6: 65,
    7: 50,
    8: 35,
    9: 18,
    10: 15,
    11: 12
  }

  assert(getDataProviderValue('table_d'), oldValue, 'TABLE_D')
  return getDataProviderValue('table_d')
}

function fk51 ({
  wymianaOkien,
  typOkien,
  stopienOciepleniaDachu,
  ocieplenie,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  wiekBudynku
}) { // bład
  
  let ad36 = fad36(
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    ocieplenie,
    wiekBudynku
  )
  let ad37 = fad37(
    wymianaOkien,
    powierzchniaPoj,
    liczbaKondygnacji,
    typOkien,
    miasto,
    wiekBudynku
  )

  let ad38 = fad38(
    ocieplenie,
    liczbaKondygnacji,
    powierzchniaPoj,
    stopienOciepleniaDachu,
    miasto,
    wiekBudynku
  )

  let ad39 = fad39(ocieplenie, powierzchniaPoj, miasto)

  let k51 =
    k36(
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    ) +
    k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) +
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) +
    k39(powierzchniaPoj, miasto) -
    (ad36 + ad37 + ad38 + ad39)
    return k51;

}

function fat9 () {
  return 1 - parameters().AM9 - parameters().AP9 - parameters().AS9
}
function fam10 (wiekBudynku, stopienOciepleniaZew, ocieplenie) {
  let wynik = 1 //=JEŻELI(ORAZ(Dane!B10=AL7;Przedsiewziecia!C4="Nie");AM7/AM8;JEŻELI(LUB(Dane!B10=AL6;Przedsiewziecia!C4="Tak");AM6/AM8;1))
  let am6 = table6_func(wiekBudynku, 3)
  let am7 = table6_func(wiekBudynku, 2)
  let am8 = table6_func(wiekBudynku, 1)
  if (stopienOciepleniaZew == 2 && !oc(ocieplenie, 1)) wynik = am7 / am8
  else if (stopienOciepleniaZew == 3 || oc(ocieplenie, 1)) wynik = am6 / am8
  return wynik
}
function fam11 (wiekBudynku, stopienOciepleniaZew, ocieplenie) {
  let am10 = fam10(wiekBudynku, stopienOciepleniaZew, ocieplenie)
  return parameters().AM9 * am10
}
function fap10 (wiekBudynku, stopienOciepleniaDachu, ocieplenie) {
  let wynik = 1 //=JEŻELI(ORAZ(Dane!B11=AO7;Przedsiewziecia!C6="Nie");AP7/AP8;JEŻELI(LUB(Dane!B11=AO6;Przedsiewziecia!C6="Tak");AP6/AP8;1))
  let ap6 = table7_func(wiekBudynku, 3)
  let ap7 = table7_func(wiekBudynku, 2)
  let ap8 = table7_func(wiekBudynku, 1)
  if (stopienOciepleniaDachu == 2 && !oc(ocieplenie, 2)) wynik = ap7 / ap8
  else if (stopienOciepleniaDachu == 3 || oc(ocieplenie, 2)) wynik = ap6 / ap8
  return wynik
}
function fap11 (wiekBudynku, stopienOciepleniaDachu, ocieplenie) {
  let ap10 = fap10(wiekBudynku, stopienOciepleniaDachu, ocieplenie)
  return parameters().AP9 * ap10
}
function fas10 (wiekBudynku, okna, wymianaOkien) {
  let wynik = 1 //=JEŻELI(ORAZ(Dane!B12=AR7;Przedsiewziecia!C5="Nie");AS7/AS6;JEŻELI(LUB(Dane!B12=AR8;Przedsiewziecia!C5="Tak");AS8/AS6;1))
  let as6 = table8_func(wiekBudynku, 1)
  let as7 = table8_func(wiekBudynku, 2)
  let as8 = table8_func(wiekBudynku, 3)
  if (okna === 2 && !wymianaOkien) wynik = as7 / as8
  else if (okna === 3 || wymianaOkien) wynik = as8 / as6
  return wynik
}
function fas11 (wiekBudynku, okna, wymianaOkien) {
  let as10 = fas10(wiekBudynku, okna, wymianaOkien)
  return parameters().AS9 * as10
}
function fax13 (
  wiekBudynku,
  stopienOciepleniaZew,
  ocieplenie,
  stopienOciepleniaDachu,
  okna,
  wymianaOkien
) {
  let at11 =
    fam11(wiekBudynku, stopienOciepleniaZew, ocieplenie) +
    fap11(wiekBudynku, stopienOciepleniaDachu, ocieplenie) +
    fas11(wiekBudynku, okna, wymianaOkien) +
    fat9()
  let ax13 = TABLE_D()[wiekBudynku] * at11
  return ax13
}
export function lacznaPowOgrzewana (powPoj, lKon) {
  if (lKon > 0 && powPoj >= 50 && powPoj <= 300) return powPoj * lKon
  else return 0
}
export function oc (oci, v) {
  let wynik = false
  Array.from(oci).forEach(item => {
    if (item == v) wynik = true
  })

  return wynik
}
const format = num => {
  const n = String(num),
    p = n.indexOf('.')
  let wyn = n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
    p < 0 || i < p ? `${m} ` : m
  )
  wyn = wyn.replace('.', ',')

  if (!wyn.includes(',')) wyn += ',00'
  let temp = wyn.split(',')
  if (temp[1].length < 2) wyn += '0'
  return wyn
}
const format1 = num => {
  const n = String(num),
    p = n.indexOf('.')
  let wyn = n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
    p < 0 || i < p ? `${m} ` : m
  )

  return wyn
}
export function robocza (x, y) {
  return x > 0 ? format(Math.round(x * 100) / 100) : 'brak ' + y
}
export function robocza1 (x) {
  return x > 0 ? format(Math.round(x * 100) / 100) : 0
}
export function robocza2 (x) {
  return x > 0 ? format1(Math.round(x * 100) / 100) : 0
}
function table2_3_func (zrodlaCieplaO, zrodloCiepla) {
  //TABLE2_3()[zrodlaCieplaO]
  let wynik = 0
  if (zrodlaCieplaO == 0) wynik = TABLE2()[zrodloCiepla]
  else wynik = TABLE2_3()[zrodlaCieplaO]
  return wynik
}
function table2_4_func (zrodlaCieplaO, zrodloCiepla) {
  //TABLE2_3()[zrodlaCieplaO]
  let wynik = 0
  if (zrodlaCieplaO == 0) wynik = TABLE2_4()[zrodloCiepla]
  else wynik = TABLE2_4()[zrodlaCieplaO]
  return wynik
}
function table2_8_func (zrodlaCieplaO, zrodloCiepla) {
  //TABLE2_3()[zrodlaCieplaO]
  let wynik = 0
  if (zrodlaCieplaO == 0) wynik = TABLE2_8()[zrodloCiepla]
  else wynik = TABLE2_8()[zrodlaCieplaO]
  return wynik
}
function table2_9_func (zrodlaCieplaO, zrodloCiepla) {
  //TABLE2_3()[zrodlaCieplaO]
  let wynik = 0
  if (zrodlaCieplaO == 0) wynik = TABLE2_9()[zrodloCiepla]
  else wynik = TABLE2_9()[zrodlaCieplaO]
  return wynik
}
function table2_10_func (zrodlaCieplaO, zrodloCiepla) {
  //TABLE2_3()[zrodlaCieplaO]
  let wynik = 0
  if (zrodlaCieplaO == 0) wynik = TABLE2_10()[zrodloCiepla]
  else wynik = TABLE2_10()[zrodlaCieplaO]
  return wynik
}
function table2_11_func (zrodlaCieplaO, zrodloCiepla) {
  //TABLE2_3()[zrodlaCieplaO]
  let wynik = 0
  if (zrodlaCieplaO == 0) wynik = TABLE2_11()[zrodloCiepla]
  else wynik = TABLE2_11()[zrodlaCieplaO]
  return wynik
}
function table6_func (wiekBudynku, stopienOciepleniaZew) {
  let x = 0
  switch (Number(wiekBudynku)) {
    case 1:
      x = 1.5
      break
    case 2:
      x = 1.3
      break
    case 3:
      x = 1.2
      break
    case 4:
      x = 1.1
      break
    case 5:
      x = 0.75
      break
    case 6:
      x = 0.55
      break
    case 7:
      x = 0.3
      break
    case 8:
      x = 0.3
      break
    case 9:
      x = 0.25
      break
    case 10:
      x = 0.23
      break
    case 11:
      x = 0.2
      break
    default:
      x = 0
  }
  let co = 0.2 //3
  let no = x //1
  let czo = (co + no) / 2 //2

  switch (Number(stopienOciepleniaZew)) {
    case 1:
      return no
    case 2:
      return czo
    case 3:
      return co
  }
}
function table7_func (wiekBudynku, stopienOciepleniaDachu) {
  let x = 0
  switch (Number(wiekBudynku)) {
    case 1:
      x = 1.0
      break
    case 2:
      x = 0.9

      break
    case 3:
      x = 0.9

      break
    case 4:
      x = 0.9

      break
    case 5:
      x = 0.45

      break
    case 6:
      x = 0.3

      break
    case 7:
      x = 0.3

      break
    case 8:
      x = 0.25

      break
    case 9:
      x = 0.2

      break
    case 10:
      x = 0.18

      break
    case 11:
      x = 0.15
      break
  }
  let co = 0.15 //3
  let no = x //1
  let czo = Math.ceil(((co + no) / 2) * 100) / 100

  switch (Number(stopienOciepleniaDachu)) {
    case 1:
      return no
    case 2:
      return czo
    case 3:
      return co
  }
}
function table8_func (wiekBudynku, typOkien) {
  let x = 0
  switch (Number(wiekBudynku)) {
    case 1:
      x = 3.5
      break
    case 2:
      x = 3.0
      break
    case 3:
      x = 3.0
      break
    case 4:
      x = 3.0
      break
    case 5:
      x = 2.6
      break
    case 6:
      x = 2.6
      break
    case 7:
      x = 2.6
      break
    case 8:
      x = 1.7
      break
    case 9:
      x = 1.3
      break
    case 10:
      x = 1.1
      break
    case 11:
      x = 0.9
      break
  }
  let oknaStare = x //1
  let nowoczesne = 0.9 //3
  let oknaCzesc = (oknaStare + nowoczesne) / 2 //2

  switch (Number(typOkien)) {
    case 1:
      return oknaStare
    case 2:
      return oknaCzesc
    case 3:
      return nowoczesne
  }
}

const fd65 = ({wodaUz, zrodloCiepla, instalacjaCieplekWodUz, powierzchniaPoj, liczbaKondygnacji, wiekBudynku}) => {

  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  const isKolektorSloneczny = wodaUz == 12
  
  let r54 = parameters().M60 * b9 * parameters().C32
  let s54 =
    r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
  let k40 = s54
  let i40 = efficiencyBefore({zrodloCiepla, wiekBudynku});
  let j40 = TABLE2()[12] // kolektor słoneczny
  let k54 = parameters().M60 * b9 * parameters().C32
  let l54 = k54 / parameters().I54
  let i48 = efficiencyBefore({zrodloCiepla: wodaUz, wiekBudynku});
  const l40 = isKolektorSloneczny ? (k40 * (1 - j40)) / i40 : k40 / i40
  const d65 = isKolektorSloneczny ? l40 : l54 / i48 // K54/I48
  return d65
}

function fc17 (zrodloCiepla, wiekBudynku) {
  let wyn = 0
  if (zrodloCiepla != 5) wyn = TABLE2()[zrodloCiepla]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        wyn = 0.6

        break
      case 2:
        wyn = 0.6

        break
      case 3:
        wyn = 0.6

        break
      case 4:
        wyn = 0.6

        break
      case 5:
        wyn = 0.65

        break
      case 6:
        wyn = 0.65

        break
      case 7:
        wyn = 0.82

        break
      case 8:
        wyn = 0.82

        break
      case 9:
        wyn = 0.82

        break
      case 10:
        wyn = 0.82

        break
      case 11:
        wyn = 0.82

        break
      default:
        wyn = 1
    }
  }
  return wyn
}
function fl36 (
  zrodloCiepla,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  wiekBudynku
) {
  let x = efficiencyBefore({zrodloCiepla, wiekBudynku});

  return (
    k36(
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    ) /
    (parameters().I51 * x)
  )
}
function fs39 (
  ocieplenie,
  zrodloCiepla,
  powierzchniaPoj,
  miasto,
  instalacjaGrzewcza,
  zrodlaCieplaO,
  wiekBudynku
) {
  let s39 = 0
  //if (ocieplenie == 3){
  let x = 0
  //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T15;2;FAŁSZ))
  if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        x = 0.6

        break
      case 2:
        x = 0.6

        break
      case 3:
        x = 0.6

        break
      case 4:
        x = 0.6

        break
      case 5:
        x = 0.65

        break
      case 6:
        x = 0.65

        break
      case 7:
        x = 0.82

        break
      case 8:
        x = 0.82

        break
      case 9:
        x = 0.82

        break
      case 10:
        x = 0.82

        break
      case 11:
        x = 0.82

        break
      default:
        x = 0
    }
  }

  // let l39 = k39(powierzchniaPoj, miasto)/(parameters().I51*x)
  let e39 = powierzchniaPoj
  let j39 = 0.3
  //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  x = 0
  x = TABLE3()[miasto]
  let r39 = (e39 * j39 * 0.6 * x * parameters().C31) / 1000
  //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
  x = 0
  x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
  s39 = r39 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : 61.6 * 0.01))

  //}
  return s39
}
function fl39 ({ocieplenie, zrodloCiepla, powierzchniaPoj, miasto, wiekBudynku}) {
  let l39 = 0
  let x = efficiencyBefore({zrodloCiepla, wiekBudynku})
  l39 = k39(powierzchniaPoj, miasto) / (parameters().I51 * x)

  return l39
}
function fae39 (
  ocieplenie,
  zrodloCiepla,
  powierzchniaPoj,
  miasto,
  instalacjaGrzewcza,
  zrodlaCieplaO,
  wiekBudynku
) {
  let ae39 = 0

  if (oc(ocieplenie, 3)) {
    let l39 = fl39({
      ocieplenie,
      zrodloCiepla,
      powierzchniaPoj,
      miasto,
      wiekBudynku
  })

    let s39 = fs39(
      ocieplenie,
      zrodloCiepla,
      powierzchniaPoj,
      miasto,
      instalacjaGrzewcza,
      zrodlaCieplaO,
      wiekBudynku
    )
    let aa39 = l39 - s39
    ae39 = aa39
  }
  return ae39
}

export function oblicz (a) {
  if (a == 2) return a
  else return 'tu będą obliczenia'
}

function k36 (
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  wiekBudynku
) {
  //let e7 = 13*0.01
  //let e6 = 0.025 * 0.01
  //let f7 = 50
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let b14 =
    Math.round(
      b9 * (parameters().E7 + (b9 - parameters().F7) * parameters().E6) * 10
    ) / 10
  let c6 = b9
  let c13 = b14
  //let h15 = 40*0.01
  //let i15 = 50
  //let h14 = -0.1*0.01
  let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14

  let c14 = Math.round(c13 * h16 * 10) / 10
  let e36 =
    (2 * Math.sqrt(powierzchniaPoj) + 2 * Math.sqrt(powierzchniaPoj)) *
      3 *
      liczbaKondygnacji -
    b14 -
    c14
  let i36 = 0

  i36 = table6_func(wiekBudynku, stopienOciepleniaZew)

  //let c30 = 0.1;

  //let c31 = 16

  let m = 0
  //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  m = TABLE3()[miasto]
  let temp = (m * parameters().C31) / 1000

  let k36 = e36 * i36 * (1 + parameters().C30) * temp
  return k36
}

function k37 (
  typOkien,
  powierzchniaPoj,
  liczbaKondygnacji,
  miasto,
  wiekBudynku
) {
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let b14 =
    Math.round(
      b9 * (parameters().E7 + (b9 - parameters().F7) * parameters().E6) * 10
    ) / 10
  let c13 = b14
  let c6 = b9
  let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
  let c14 = Math.round(c13 * h16 * 10) / 10
  let e37 = c13 + c14
  let i37 = 0
  //=WYSZUKAJ.PIONOWO(Dane!B12;Tabele!$AR$6:$AS$8;2;FAŁSZ)
  i37 = table8_func(wiekBudynku, typOkien)

  let m = 0
  //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  m = TABLE3()[miasto]
  let temp = (m * parameters().C31) / 1000
  let k37 = (e37 * i37 * 1 + e37 * parameters().C30) * temp
  return k37
}

function k38 (
  liczbaKondygnacji,
  powierzchniaPoj,
  stopienOciepleniaDachu,
  miasto,
  wiekBudynku
) {
  // bład
  let e38 = 0
  let f10 = liczbaKondygnacji - Math.trunc(liczbaKondygnacji)
  if (f10 > 0) {
    e38 = powierzchniaPoj * Math.sqrt(2)
  } else e38 = powierzchniaPoj
  let i38 = 0
  /*for(let k in TABLE7) { //WYSZUKAJ.PIONOWO(Dane!B11;Tabele!$AO$6:$AP$8;2;FAŁSZ)
        if (k == stopienOciepleniaDachu) i38 = TABLE7[k]
      }*/
  i38 = table7_func(wiekBudynku, stopienOciepleniaDachu)
  let m = 0
  m = TABLE3()[miasto]

  let temp = (m * parameters().C31) / 1000
  let k38 =
    ((e38 * i38 * 0.8 + e38 * parameters().C30) * m * parameters().C31) / 1000
  return k38
}
function k39 (powierzchniaPoj, miasto) {
  let e39 = powierzchniaPoj
  let i39 = 2
  let m = 0
  for (let k in TABLE3()) {
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    if (k == miasto) m = TABLE3()[k]
  }
  let temp = (m * parameters().C31) / 1000
  let k39 = (e39 * i39 * 0.6 + e39 * parameters().C30) * temp
  return k39
}
function fad36 (
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  ocieplenie,
  wiekBudynku
) {
  let ad36 = 0

  let m = 0
  //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  m = TABLE3()[miasto]
  let tempM = (m * parameters().C31) / 1000
  if (oc(ocieplenie, 1)) {
    let c8 = Math.sqrt(powierzchniaPoj)
    let c9 = c8
    let c11 = (2 * c9 + 2 * c8) * 3 * liczbaKondygnacji
    let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
    let b14 =
      Math.round(
        b9 * (parameters().E7 + (b9 - parameters().F7) * parameters().E6) * 10
      ) / 10

    let c6 = b9
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14

    let c13 = b14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let e36 = c11 - b14 - c14
    let j36 = table6_func(wiekBudynku, 3)

    let r36 = e36 * j36 * 1 * tempM
    ad36 =
      k36(
        powierzchniaPoj,
        liczbaKondygnacji,
        stopienOciepleniaZew,
        miasto,
        wiekBudynku
      ) - r36 //z36
  }
  return ad36
}
function fad37 (
  wymianaOkien,
  powierzchniaPoj,
  liczbaKondygnacji,
  typOkien,
  miasto,
  wiekBudynku
) {
  let ad37 = 0
  if (wymianaOkien) {
    let m = 0
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = TABLE3()[miasto]
    let tempM = (m * parameters().C31) / 1000
    let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
    let c8 = Math.sqrt(powierzchniaPoj)
    //let c9 = c8
    //let c11 = (2*c9+2*c8)*3*liczbaKondygnacji
    let b14 =
      Math.round(
        b9 * (parameters().E7 + (b9 - parameters().F7) * parameters().E6) * 10
      ) / 10

    let c6 = b9
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14

    let c13 = b14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let e37 = c13 + c14
    let j37 = table8_func(wiekBudynku, 2)
    let r37 = e37 * j37 * 1 * tempM
    ad37 =
      k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) -
      r37 //z37
  }
  return ad37
}
function fad38 (
  ocieplenie,
  liczbaKondygnacji,
  powierzchniaPoj,
  stopienOciepleniaDachu,
  miasto,
  wiekBudynku
) {
  let ad38 = 0
  if (oc(ocieplenie, 2)) {
    let m = 0
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = TABLE3()[miasto]
    let tempM = (m * parameters().C31) / 1000
    //let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
    let b7 = liczbaKondygnacji
    let f10 = b7 - Math.trunc(b7)
    let e38 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj //c12
    let j38 = table7_func(wiekBudynku, 3)

    let r38 = e38 * j38 * 0.8 * tempM
    ad38 =
      k38(
        liczbaKondygnacji,
        powierzchniaPoj,
        stopienOciepleniaDachu,
        miasto,
        wiekBudynku
      ) - r38
  }

  return ad38
}
function fad39 (ocieplenie, powierzchniaPoj, miasto) {
  let ad39 = 0
  if (oc(ocieplenie, 3)) {
    let m = 0
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = TABLE3()[miasto]
    let tempM = (m * parameters().C31) / 1000
    let e39 = powierzchniaPoj
    let j39 = 0.3
    let r39 = e39 * j39 * 0.6 * tempM
    ad39 = k39(powierzchniaPoj, miasto) - r39
  }
  return ad39
}
function fr41 (
  wymianaOkien,
  typOkien,
  stopienOciepleniaDachu,
  instalacjaGrzewcza,
  ocieplenie,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  wiekBudynku
) {

  let k51 = fk51({
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  })

  let r41 =
    k51 /
    (instalacjaGrzewcza ? 0.874 : 0.616)
  return r41
}

export const efficiencyBefore = ({
  zrodloCiepla,
  wiekBudynku,
}) => {
  let temp = 0
  //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
  if (zrodloCiepla != 5) temp = TABLE2()[zrodloCiepla]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        temp = 0.6

        break
      case 2:
        temp = 0.6

        break
      case 3:
        temp = 0.6

        break
      case 4:
        temp = 0.6

        break
      case 5:
        temp = 0.65

        break
      case 6:
        temp = 0.65

        break
      case 7:
        temp = 0.82

        break
      case 8:
        temp = 0.82

        break
      case 9:
        temp = 0.82

        break
      case 10:
        temp = 0.82

        break
      case 11:
        temp = 0.82

        break
      default:
        temp = 0
    }
  }
  return temp
}
export function fd61 ({
  ocieplenie,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  typOkien,
  stopienOciepleniaDachu,
  zrodloCiepla,
  wiekBudynku,
  wymianaOkien
}) {
  let zk36 = k36(
    powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  wiekBudynku
  )
  let zk37 = k37(
    typOkien,
    powierzchniaPoj,
    liczbaKondygnacji,
    miasto,
    wiekBudynku
  )
  let zk38 = k38(
    liczbaKondygnacji,
    powierzchniaPoj,
    stopienOciepleniaDachu,
    miasto,
    wiekBudynku
  ) 
  let zk39 = k39(powierzchniaPoj, miasto)

  //let m64 = 0.5
  //let c32 = 1
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let m66 = parameters().C32 * b9
  let m67 = m66 * 3
  let m = 0
  //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  m = TABLE3()[miasto] //c16

  let temp = (m * parameters().C31) / 1000
  let k52 = m67 * parameters().M64 * 0.34 * temp

  let temp2 = efficiencyBefore({zrodloCiepla, wiekBudynku});

  //let i51 = 61.6*0.01
  let temp3 = parameters().I51 * temp2;
  let l36 = fl36(
    zrodloCiepla,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  ) 

  let l37 = zk37 / temp3

  let l38 = zk38 / temp3

  let l39 = fl39({ocieplenie, zrodloCiepla, powierzchniaPoj, miasto, wiekBudynku})

  let i52 = 0
  let c17 = fc17(zrodloCiepla, wiekBudynku)
  let l52 = (k52 / c17) * (1 - i52)
  
  let k51 = fk51({
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  })

  let l51 = k51 / parameters().I51
  let d61 = l36 + l37 + l38 + l39 + l51 + l52
 
  return d61
}

export function energyBefore ({
  ocieplenie,
  wodaUz,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  typOkien,
  stopienOciepleniaDachu,
  zrodloCiepla,
  wiekBudynku,
  fotowoltanika,
  wymianaOkien,
  zrodlaCieplaW,
  instalacjaCieplekWodUz
}) {
  //let i54 = 39*0.01
  //let m60 = 20
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let d61 = fd61({
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    typOkien,
    stopienOciepleniaDachu,
    zrodloCiepla,
    wiekBudynku,
    wymianaOkien
})
  
  let d65 = fd65({
    wodaUz,
    zrodlaCieplaW,
    zrodloCiepla,
    instalacjaCieplekWodUz,
    powierzchniaPoj,
    liczbaKondygnacji,
    wiekBudynku
  })
  let m61 = 40

  let c6 = b9
  let d67 = fotowoltanika ? m61 * c6 : 0
  let enBef = d61 + d65 + d67 // d70
  return Math.round(enBef)
}

export function energyAfter ({
  stopienOciepleniaDachu,
  typOkien,
  stopienOciepleniaZew,
  zrodloCiepla,
  wodaUz,
  powierzchniaPoj,
  liczbaKondygnacji,
  wiekBudynku,
  ocieplenie,
  miasto,
  wymianaOkien,
  instalacjaGrzewcza,
  zrodlaCieplaO,
  wentylacja,
  kolektory,
  instalacjaCieplekWodUz,
  zrodlaCieplaW,
  okna,
  fotowoltanika
}) {
  //let d61 = Number(energyBefore(powierzchniaPoj, liczbaKondygnacji, stopienOciepleniaZew, miasto, typOkien, stopienOciepleniaDachu, zrodloCiepla));

  let d61 = fd61({
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    typOkien,
    stopienOciepleniaDachu,
    zrodloCiepla,
    wiekBudynku,
    wymianaOkien
})

  let f61 = 0
  let f72 = d61 * 0.9
  let osC9 = zrodlaCieplaO
  let osC10 = zrodlaCieplaW
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let c6 = b9
  let b7 = liczbaKondygnacji
  let ax13 = 0 //WYSZUKAJ.POZIOMO(AX18;AX18:AX29;AK17+1)*AT11

  ax13 = fax13(
    wiekBudynku,
    stopienOciepleniaZew,
    ocieplenie,
    stopienOciepleniaDachu,
    okna,
    wymianaOkien
  )
  let e41 = Math.round(((c6 * ax13) / 1000) * 2) / 2

  let f41 = e41 * parameters().C41

  let e43 = e41
  let f43 = e43 * parameters().C43

  let e45 = e41
  let f45 = e45 * parameters().C45

  let e46 = Math.round(
    ((Math.floor(c6 / 50) + 1) * parameters().Q60 * parameters().S60) /
      parameters().O60
  )
  let f46 = e46 * parameters().C46

  let e47 = e41
  let f47 = e47 * parameters().C47
  let tempSuma =
    (osC9 == 3 ? f41 : 0) +
    (osC9 == 1 ? f43 : 0) +
    (osC9 == 10 ? f45 : 0) +
    (osC10 == 10 ? f46 : 0) +
    (osC9 == 11 ? f47 : 0)

  let b14 =
    Math.round(
      b9 * (parameters().E7 + (b9 - parameters().F7) * parameters().E6) * 10
    ) / 10
  // let h16 = H15+(c6-I15)*parameters().H14
  // let c13 = b14
  // let c14 = Math.round((c13*h16)*10)/10;
  let m = 0
  //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  m = TABLE3()[miasto]

  //let tempM = m*parameters().C31/1000

  let k51 = fk51({
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  })

  let r41 =
    k51 /
    (instalacjaGrzewcza ? 0.874 : 0.616)

  //let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let m66 = parameters().C32 * b9
  let m67 = m66 * 3

  let temp = (m * parameters().C31) / 1000
  let k52 = m67 * parameters().M64 * 0.34 * temp
  let f10 = b7 - Math.trunc(b7)
  
  let ae58 = 0
  let l51 = k51 / parameters().I51
  let k41 =
    (k36(
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    ) +
    k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) +
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) +
    k39(powierzchniaPoj, miasto))/ parameters().I51 + l51 * 0
    
  let i41 = fc17(zrodloCiepla, wiekBudynku)
  let l41 = k41 / i41
  if (tempSuma > 0) {
    //let i51 = 61.6*0.01

    //let h16 = H15+(c6-I15)*parameters().H14

    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = 0
    m = TABLE3()[miasto]

    //let tempM = m*parameters().C31/1000

    let s41 = r41 / TABLE2_3()[3]
    let aa41 = l41 - s41
    let ae41 = zrodlaCieplaO == 3 ? aa41 : 0
    let ae43 = 0
    if (zrodlaCieplaO == 1) {
      let i43 = fc17(zrodloCiepla, wiekBudynku)
      let k43 = k41
      let l43 = k43 / i43
      let r43 = r41
      let j43 = TABLE2_3()[1]
      let s43 = r43 / j43
      let aa43 = l43 - s43
      ae43 = aa43
    }
    let ae45 = 0
    if (Number(zrodlaCieplaO) == 10) {
      let k45 = k41
      let i45 = fc17(zrodloCiepla, wiekBudynku)
      let l45 = k45 / i45
      let r45 = r41
      let j45 = TABLE2_3()[10]
      let s45 = r45 / j45
      let aa45 = l45 - s45
      ae45 = aa45
    }

    let ae47 = 0
    if (zrodlaCieplaO == 11) {
      let k47 = k41
      let i47 = fc17(zrodloCiepla, wiekBudynku)
      let l47 = k47 / i47
      let r47 = r41
      let j47 = 3.6
      let s47 = r47 / j47
      let aa47 = l47 - s47
      ae47 = aa47
    }

    let ae52 = 0
    if (wentylacja) {
      //let i51 = 61.6 * 0.01
      let l52 = (k52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().I52)
      // let c32 = 1
      let m66 = parameters().C32 * b9
      let m67 = m66 * 3
      //let m64 = 0.5
      let r52 = (m67 * parameters().M64 * 0.34 * m * parameters().C31) / 1000
      //let j52 = 0.85
      let s52 = (r52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().J52)
      let aa52 = l52 - s52
      ae52 = aa52
    }
    let r51 = k51;
    let s51 =  r51 / (instalacjaGrzewcza ? parameters().J51 : parameters().I51)
    let aa51 = l51 - s51
    let ae51 = instalacjaGrzewcza ? aa51 : 0
    ae58 = ae41 + ae43 + ae45 + ae47 + ae51 + ae52
  } else {
    let k51 = fk51({
      wymianaOkien,
      typOkien,
      stopienOciepleniaDachu,
      ocieplenie,
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    })
    let m = 0
    for (let k in TABLE3()) {
      //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
      if (k == miasto) m = TABLE3()[k]
    }
    let tempM = (m * parameters().C31) / 1000
    let c13 = b14
    //let h15 = 0.4
    //let i15 = 50
    //let h14 = -0.1*0.01
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let ae52 = 0
    if (wentylacja) {
      let m66 = parameters().C32 * b9
      let m67 = m66 * 3
      let r52 = (m67 * parameters().M64 * 0.34 * m * parameters().C31) / 1000
      let m71 = r52
      let k52 = m71
      //let i51 = 61.6 * 0.01
      let x = 0
      if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
      else {
        switch (Number(wiekBudynku)) {
          case 1:
            x = 0.6

            break
          case 2:
            x = 0.6

            break
          case 3:
            x = 0.6

            break
          case 4:
            x = 0.6

            break
          case 5:
            x = 0.65

            break
          case 6:
            x = 0.65

            break
          case 7:
            x = 0.82

            break
          case 8:
            x = 0.82

            break
          case 9:
            x = 0.82

            break
          case 10:
            x = 0.82

            break
          case 11:
            x = 0.82

            break
          default:
            x = 0
        }
      }
      let l52 = (k52 / x) * (1 - parameters().I52)
      //let c32 = 1

      //let m64 = 0.5

      let s52 = (r52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().J52)
      let aa52 = l52 - s52
      ae52 = aa52
    }

    let ae51 = 0
    if (instalacjaGrzewcza) {
      //let i51 = 61.6 * 0.01
      let l51 = k51 / parameters().I51
      let r51 = k51
      let s51 = r51 / (instalacjaGrzewcza ? parameters().J51 : parameters().I51)
      let aa51 = l51 - s51
      ae51 = aa51
    }
    let ae36 = 0
    if (oc(ocieplenie, 1)) {
      //let i51 = 61.6 * 0.01
      let x = 0
      //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
      if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
      else {
        switch (Number(wiekBudynku)) {
          case 1:
            x = 0.6

            break
          case 2:
            x = 0.6

            break
          case 3:
            x = 0.6

            break
          case 4:
            x = 0.6

            break
          case 5:
            x = 0.65

            break
          case 6:
            x = 0.65

            break
          case 7:
            x = 0.82

            break
          case 8:
            x = 0.82

            break
          case 9:
            x = 0.82

            break
          case 10:
            x = 0.82

            break
          case 11:
            x = 0.82

            break
          default:
            x = 0
        }
      }

      let l36 = fl36(
        zrodloCiepla,
        powierzchniaPoj,
        liczbaKondygnacji,
        stopienOciepleniaZew,
        miasto,
        wiekBudynku
      )
      let c9 = Math.sqrt(powierzchniaPoj)
      let c8 = c9
      let b7 = liczbaKondygnacji
      let c11 = (2 * c9 + 2 * c8) * 3 * b7
      let e8 = parameters().E7 + (c6 - parameters().F7) * parameters().E6
      let b14 = Math.round(c6 * e8 * 10) / 10
      let e36 = c11 - b14 - c14
      let j36 = table6_func(wiekBudynku, 3)
      let r36 = e36 * j36 * 1 * tempM
      // for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
      //    if (k == zrodlaCieplaO)
      x = 0
      x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
      let s36 =
        r36 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))
      let aa36 = l36 - s36
      ae36 = aa36
    }

    let ae37 = 0
    if (wymianaOkien) {
      let x = 0
      //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
      if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
      else {
        switch (Number(wiekBudynku)) {
          case 1:
            x = 0.6

            break
          case 2:
            x = 0.6

            break
          case 3:
            x = 0.6

            break
          case 4:
            x = 0.6

            break
          case 5:
            x = 0.65

            break
          case 6:
            x = 0.65

            break
          case 7:
            x = 0.82

            break
          case 8:
            x = 0.82

            break
          case 9:
            x = 0.82

            break
          case 10:
            x = 0.82

            break
          case 11:
            x = 0.82

            break
          default:
            x = 0
        }
      }

      let l37 =
        k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) /
        (parameters().I51 * x)
      let e37 = c13 + c14
      let j37 = table8_func(wiekBudynku, 2)
      let r37 = e37 * j37 * 1 * tempM
      x = 0
      //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
      x = table2_3_func(zrodlaCieplaO, zrodloCiepla)

      let s37 =
        r37 / (x * (instalacjaGrzewcza ? parameters().J51 : parameters().I51))
      let aa37 = l37 - s37
      ae37 = aa37
    }

    let ae38 = 0
    if (oc(ocieplenie, 2)) {
      let x = efficiencyBefore({zrodloCiepla, wiekBudynku});
      let l38 =
        k38(
          liczbaKondygnacji,
          powierzchniaPoj,
          stopienOciepleniaDachu,
          miasto,
          wiekBudynku
        ) /
        (parameters().I51 * x)
      // for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
      //   if (k == 0)
      x = 0
      x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
      let e38 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj //c12
      let j38 = table7_func(wiekBudynku, 3)
      let r38 = e38 * j38 * 0.8 * tempM
      let s38 =
        r38 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))
      let aa38 = l38 - s38
      ae38 = aa38
    }

    let ae39 = fae39(
      ocieplenie,
      zrodloCiepla,
      powierzchniaPoj,
      miasto,
      instalacjaGrzewcza,
      zrodlaCieplaO,
      wiekBudynku
    )

    let tempS = ae36 + ae37 + ae38 + ae39 //SUMA(AE36:AE39)
    ae58 = tempS + ae51 + ae52

  }
  f61 = ae58 > f72 ? f72 : ae58

  let e61 = d61 - f61

  let x = 0
  
  let i48 = efficiencyBefore({zrodloCiepla: wodaUz, wiekBudynku});
  let k54 = parameters().M60 * b9 * parameters().C32
  let l54 = k54 / parameters().I54
  let d65 = fd65({
    wodaUz,
    zrodlaCieplaW,
    zrodloCiepla,
    instalacjaCieplekWodUz,
    powierzchniaPoj,
    liczbaKondygnacji,
    wiekBudynku
  })
  let e65 = fe65({
    kolektory,
    b9,
    instalacjaCieplekWodUz,
    miasto,
    zrodlaCieplaW,
    wodaUz,
    r41,
    d65,
    wiekBudynku,
    fotowoltanika,
    zrodloCiepla
})
  let m61 = 40
  let d67 = fotowoltanika ? m61 * c6 : 0
  let k53 = d67
  let l53 = k53
  let r53 = k53
  let z53 = l53 - r53
  let aa53 = z53
  let ae49 = 0
  let ae50 = 0
  let ae51 = 0
  let ae53 = fotowoltanika ? aa53 : 0
  let ae57 = ae49 + ae50 + ae51 * 0 + ae53
  let f67 = ae57
  let e67 = d67 - f67
  let enAft = e61 + e65 + e67

  return enAft
}

function fe65 ({
  kolektory,
  b9,
  instalacjaCieplekWodUz,
  zrodlaCieplaW,
  wodaUz,
  r41,
  d65,
  wiekBudynku,
  zrodloCiepla
}) {
  let ae40 = 0
  //let j54 = 0.56
  if (kolektory) {
    let r54 = parameters().M60 * b9 * parameters().C32
    let s54 =
      r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
    let k40 = s54
    let i40 = TABLE2()[zrodloCiepla]
    //=WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;2;FAŁSZ)

    let r40 = s54
    let j40 = TABLE2()[12] // kolektor słoneczny
    let l40 = zrodlaCieplaW == 12 ? (k40 * (1 - j40)) / i40 : k40 / i40
    let s40 = r40 * (1 - j40) / i40
    let aa40 = l40 - s40
    ae40 = wodaUz == 12 ? 0 : aa40
  }
  let ae42 = 0
  //JEŻELI(Przedsiewziecia!C10="Kocioł na biomasę (pelety)";AA42;0)*AL40
  if (zrodlaCieplaW == 3) {
    //let i54 = 39*0.01
    //let m60 = 20
    let k54 = parameters().M60 * b9 * parameters().C32
    let l54 = k54 / parameters().I54
    let k42 = l54
    let x = 0
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let i42 = x
    let l42 = k42 / i42
    let r42 = r41
    let j42 = 0.8
    let s42 = r42 / j42
    let aa42 = l42 - s42
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae42 = aa42 * al40
  }
  //JEŻELI(Przedsiewziecia!C10="Powietrzna pompa ciepła";AA46;0)*AL40
  let ae46 = 0
  //let m60 = 20
  let r54 = parameters().M60 * b9 * parameters().C32
  let s54 = r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
  if (zrodlaCieplaW == 10) {
    let k46 = s54
    let x = 0
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let i46 = x
    let l46 = k46 / i46
    let r46 = s54
    let j46 = 3.2
    let s46 = r46 / j46
    let aa46 = l46 - s46
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae46 = aa46 * al40
  }
  //JEŻELI(Przedsiewziecia!C10="Gruntowa pompa ciepła";AA48;0)*AL40
  let ae48 = 0
  if (zrodlaCieplaW == 11) {
    // let i54 = 39*0.01
    // let m60 = 20
    let k54 = parameters().M60 * b9 * parameters().C32
    let l54 = k54 / parameters().I54
    let k48 = l54
    let x = 0
    
    let i48 = efficiencyBefore({zrodloCiepla: wodaUz, wiekBudynku});
    let l48 = k48 / i48
    let r48 = s54
    let j48 = 3.6
    let s48 = r48 / j48
    let aa48 = l48 - s48
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae48 = aa48 * al40
  }
  let k54 = parameters().M60 * b9 * parameters().C32
  let l54 = k54 / parameters().I54
  //JEŻELI(Przedsiewziecia!C10="Kocioł gazowy";AA44;0)*AL40
  let ae44 = 0
  if (zrodlaCieplaW == 1) {
    // let i54 = 39*0.01
    // let m60 = 20
    let k44 = l54
    let x = 0
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let i44 = x
    let l44 = k44 / i44
    let r44 = s54
    let j44 = TABLE2_3()[1]
    let s44 = r44 / j44
    let aa44 = l44 - s44
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae44 = aa44 * al40
  }
  //JEŻELI(Przedsiewziecia!C16="Tak";AA54;0)
  let u54 = zrodlaCieplaW
  let aa54 = l54 - s54
  let ae54 = instalacjaCieplekWodUz ? aa54 : 0
  let ae59 = (ae40 + ae42 + ae44 + ae46 + ae48  + ae54)
  let e65 = d65 - ae59
  return e65
}

export function energyCostBef ({
  ocieplenie,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  typOkien,
  stopienOciepleniaDachu,
  zrodloCiepla,
  wodaUz,
  wiekBudynku,
  wymianaOkien,
  fotowoltanika,
}) {
  let d61 = fd61({
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    typOkien,
    stopienOciepleniaDachu,
    zrodloCiepla,
    wiekBudynku,
    wymianaOkien
})
  let x = 0
  //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;4;FAŁSZ)
  x = TABLE2_4()[zrodloCiepla]

  let d62 = d61 * x

  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let k54 = parameters().M60 * b9 * parameters().C32
  let l54 = k54 / parameters().I54

  let c20 = 0
  //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;4;FAŁSZ)
  c20 = TABLE2_4()[wodaUz]

  let c19 = efficiencyBefore({zrodloCiepla: wodaUz, wiekBudynku})
  
  let n54 = (l54 * c20) / c19;
  let tabeleM8 = TABLE2_4()[7] // Energia elektryczna

  let M61 = 40
  let d66 = n54
  let c6 = b9
  let d67 = fotowoltanika ? M61 * c6 : 0
  let d68 = d67 * tabeleM8

  let eCb = d62 + d66 + d68
  return eCb
}

export function energyCostSavings ({
  instalacjaGrzewcza,
  kolektory,
  stopienOciepleniaDachu,
  wentylacja,
  ocieplenie,
  typOkien,
  stopienOciepleniaZew,
  wymianaOkien,
  powierzchniaPoj,
  liczbaKondygnacji,
  miasto,
  zrodloCiepla,
  wodaUz,
  wiekBudynku,
  zrodlaCieplaO,
  zrodlaCieplaW,
  fotowoltanika,
  instalacjaCieplekWodUz,
  okna
}) {
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let c6 = b9

  let ax13 = 0 //WYSZUKAJ.POZIOMO(AX18;AX18:AX29;AK17+1)*AT11
  ax13 = fax13(
    wiekBudynku,
    stopienOciepleniaZew,
    ocieplenie,
    stopienOciepleniaDachu,
    okna,
    wymianaOkien
  )

  let e41 = Math.ceil(((c6 * ax13) / 1000) * 2) / 2
  //////console.log('e41',e41, 'c6',c6,'ax13',ax13)
  let f41 = e41 * parameters().C41
  //////console.log('f41',f41)
  //////console.log('C41',C41)
  let ac41 = zrodlaCieplaO == 3 ? f41 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł na biomasę (pelety)";F41;0)
  ////////////////////console.log('ac41',ac41)
  let ac42 = ac41

  let e43 = e41
  let f43 = e43 * parameters().C43
  let ac43 = zrodlaCieplaO == 1 ? f43 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł gazowy";F43;0)

  let ac44 = ac43

  let e45 = e41
  let f45 = e45 * parameters().C45
  let ac45 = zrodlaCieplaO == 10 ? f45 : 0 //JEŻELI(Przedsiewziecia!C9="Powietrzna pompa ciepła";F45;0)

  let e46 = (
    ((Math.floor(c6 / 50) + 1) * parameters().Q60 * parameters().S60) /
      parameters().O60
  ).toFixed(1)
  let f46 = e46 * parameters().C46
  let ac46 = zrodlaCieplaW == 10 ? f46 : 0 //JEŻELI(Przedsiewziecia!C10="Powietrzna pompa ciepła";F46;0)

  let e47 = e41
  let f47 = e47 * parameters().C47
  let ac47 = zrodlaCieplaO == 11 ? f47 : 0 //JEŻELI(Przedsiewziecia!C9="Gruntowa pompa ciepła";F47;0)

  let ac48 = ac47

  let sumaAC41AC48 = ac41 + ac43 + ac45 + ac46 + ac47;
  let k54 = parameters().M60 * b9 * parameters().C32
  let r54 = k54
  let s54 = r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
  let k40 = s54
  let i40 = TABLE2()[zrodloCiepla]  

  let j40 = TABLE2()[12] // kolektor słoneczny
  let l40 = wodaUz == 12 ? (k40 * (1 - j40)) / i40 : k40 / i40
  let x = 0
  //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;4;FAŁSZ)
  x = TABLE2_4()[wodaUz]

  let n40 = l40 * x
 
  let r40 = s54
  let s40 = r40 * (1 - j40) / i40
  let t40 = s40 * x //S40*WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;4;FAŁSZ)
  let ab40 = n40 - t40
  let af40 = kolektory ? ab40 : 0 //JEŻELI(Przedsiewziecia!C8="Tak";AB40;0)
  let k51 = fk51({
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  })

  let l51 = k51 / parameters().I51
  let k41 =
    (k36(
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    ) +
    k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) +
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) +
    k39(powierzchniaPoj, miasto))/ parameters().I51 + l51 * 0

  let i41 = fc17(zrodloCiepla, wiekBudynku)

  let l41 = k41 / i41
  let c18 = 0 //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;4;FAŁSZ)
  //=WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;4;FAŁSZ)
  c18 = TABLE2_4()[zrodloCiepla]

  let n41 = l41 * c18
  let ad36 = fad36(
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    ocieplenie,
    wiekBudynku
  )
  let b14 =
    Math.round(
      b9 * (parameters().E7 + (b9 - parameters().F7) * parameters().E6) * 10
    ) / 10
  let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
  let c13 = b14
  let c14 = Math.round(c13 * h16 * 10) / 10
  let m = 0
  //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  m = TABLE3()[miasto]

  let tempM = (m * parameters().C31) / 1000
 
  let ad37 = fad37(
    wymianaOkien,
    powierzchniaPoj,
    liczbaKondygnacji,
    typOkien,
    miasto,
    wiekBudynku
  )

  let ad38 = fad38(
    ocieplenie,
    liczbaKondygnacji,
    powierzchniaPoj,
    stopienOciepleniaDachu,
    miasto,
    wiekBudynku
  )

  let ad39 = fad39(ocieplenie, powierzchniaPoj, miasto)
  let r41 =
    k51 /
    (instalacjaGrzewcza ? parameters().J51 : parameters().I51)
  let s41 = r41 / TABLE2_3()[3]
  let t41 = s41 * TABLE2_4()[3]
  let ab41 = n41 - t41
  let af41 = zrodlaCieplaO == 3 ? ab41 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł na biomasę (pelety)";AB41;0)

  let l54 = k54 / parameters().I54
  let k42 = l54
  x = 0
  //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)
  // x = TABLE2()[wodaUz]
  if (wodaUz != 5) x = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        x = 0.6

        break
      case 2:
        x = 0.6

        break
      case 3:
        x = 0.6

        break
      case 4:
        x = 0.6

        break
      case 5:
        x = 0.65

        break
      case 6:
        x = 0.65

        break
      case 7:
        x = 0.82

        break
      case 8:
        x = 0.82

        break
      case 9:
        x = 0.82

        break
      case 10:
        x = 0.82

        break
      case 11:
        x = 0.82

        break
      default:
        x = 0
    }
  }

  let i42 = x
  let l42 = k42 / i42
  let c20 = 0
  //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;4;FAŁSZ)
  c20 = TABLE2_4()[wodaUz]

  let n42 = l42 * c20
  let r42 = s54
  let j42 = 0.8
  let s42 = r42 / j42
  let t42 = s42 * TABLE2_4()[3]
  let ab42 = n42 - t42

  let e40 = 1
  let c40 = 10000
  let f40 = e40 * c40
  let ac40 = kolektory ? f40 : 0
  let al40 = ac40 > 0 ? 0 : 1

  let af42 = (zrodlaCieplaW == 3 ? ab42 : 0) * al40 //JEŻELI(Przedsiewziecia!C10="Kocioł na biomasę (pelety)";AB42;0)*AL40

  let i43 = fc17(zrodloCiepla, wiekBudynku)
  let k43 = k41
  let l43 = k43 / i43
  let n43 = l43 * c18
  let r43 = r41
  let j43 = TABLE2_3()[1]
  let s43 = r43 / j43
  let t43 = s43 * TABLE2_4()[1]
  let ab43 = n43 - t43
  let af43 = zrodlaCieplaO == 1 ? ab43 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł gazowy";AB43;0)
  let k44 = l54
  x = 0
  if (wodaUz != 5) x = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        x = 0.6

        break
      case 2:
        x = 0.6

        break
      case 3:
        x = 0.6

        break
      case 4:
        x = 0.6

        break
      case 5:
        x = 0.65

        break
      case 6:
        x = 0.65

        break
      case 7:
        x = 0.82

        break
      case 8:
        x = 0.82

        break
      case 9:
        x = 0.82

        break
      case 10:
        x = 0.82

        break
      case 11:
        x = 0.82

        break
      default:
        x = 0
    }
  }
  let i44 = x
  let l44 = k44 / i44
  let n44 = l44 * c20
  let r44 = s54
  let j44 = TABLE2_3()[1]
  let s44 = r44 / j44
  let t44 = s44 * TABLE2_4()[1]
  let ab44 = n44 - t44
  let af44 = (zrodlaCieplaW == 1 ? ab44 : 0) * al40 //JEŻELI(Przedsiewziecia!C10="Kocioł gazowy";AB44;0)*AL40
  let k45 = k41
  let i45 = fc17(zrodloCiepla, wiekBudynku)
  let l45 = k45 / i45
  let n45 = l45 * c18
  let r45 = r41
  let j45 = TABLE2_3()[10]
  let s45 = r45 / j45
  let t45 = s45 *  TABLE2_4()[7]
  let ab45 = n45 - t45
  let af45 = zrodlaCieplaO == 10 ? ab45 : 0 //JEŻELI(Przedsiewziecia!C9="Powietrzna pompa ciepła";AB45;0)

  let k46 = s54
  x = 0
  if (wodaUz != 5) x = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        x = 0.6

        break
      case 2:
        x = 0.6

        break
      case 3:
        x = 0.6

        break
      case 4:
        x = 0.6

        break
      case 5:
        x = 0.65

        break
      case 6:
        x = 0.65

        break
      case 7:
        x = 0.82

        break
      case 8:
        x = 0.82

        break
      case 9:
        x = 0.82

        break
      case 10:
        x = 0.82

        break
      case 11:
        x = 0.82

        break
      default:
        x = 0
    }
  }
  let i46 = x
  let l46 = k46 / i46
  let n46 = l46 * c20
  let r46 = s54
  let j46 = 3.2
  let s46 = r46 / j46
  let t46 = s46 * TABLE2_4()[7]
  let ab46 = n46 - t46
  let af46 = (zrodlaCieplaW == 10 ? ab46 : 0) * al40 //JEŻELI(Przedsiewziecia!C10="Powietrzna pompa ciepła";AB46;0)*AL40

  let k47 = k41
  let i47 = fc17(zrodloCiepla, wiekBudynku)
  let l47 = k47 / i47
  let n47 = l47 * c18
  let r47 = r41
  let j47 = 3.6
  let s47 = r47 / j47
  let t47 = s47 * TABLE2_4()[7]
  let ab47 = n47 - t47
  let af47 = zrodlaCieplaO == 11 ? ab47 : 0 //JEŻELI(Przedsiewziecia!C9="Gruntowa pompa ciepła";AB47;0)

  let k48 = l54
  x = 0
  let i48 = efficiencyBefore({zrodloCiepla: wodaUz, wiekBudynku});
  let l48 = k48 / i48
  let n48 = l48 * c20
  let r48 = s54
  let j48 = 3.6
  let s48 = r48 / j48
  let t48 = s48 * TABLE2_4()[7]
  let ab48 = n48 - t48
  let af48 = (zrodlaCieplaW == 11 ? ab48 : 0) * al40 //=JEŻELI(Przedsiewziecia!C10="Gruntowa pompa ciepła";AB48;0)*AL40

  let af49 = 0 //(zrodlaCieplaW == 4 ? ab48 : 0)*al40//=JEŻELI(Przedsiewziecia!C11="Tak";AB49;0)
  let af50 = 0
  let c17 = fc17(zrodloCiepla, wiekBudynku)
  let n51 = (l51 * c18) / c17
  x = 0
  //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;4;FAŁSZ
  x = table2_4_func(zrodlaCieplaO, zrodloCiepla)

  let r51 = k51
  let s51 = r51 / (instalacjaGrzewcza ? parameters().J51 : parameters().I51)
  //let s51 = r51/parameters().I51
  let t51 = (s51 * c18) / c17
  let ab51 = n51 - t51
  let af51 = instalacjaGrzewcza ? ab51 : 0 //JEŻELI(Przedsiewziecia!C13="Tak";AB51;0)

  let m66 = parameters().C32 * b9
  let m67 = m66 * 3
  m = 0
  //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  m = TABLE3()[miasto]

  let temp = (m * parameters().C31) / 1000
  let k52 = m67 * parameters().M64 * 0.34 * temp
  let xx = 0
  if (zrodloCiepla != 5) xx = TABLE2()[zrodloCiepla]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        xx = 0.6

        break
      case 2:
        xx = 0.6

        break
      case 3:
        xx = 0.6

        break
      case 4:
        xx = 0.6

        break
      case 5:
        xx = 0.65

        break
      case 6:
        xx = 0.65

        break
      case 7:
        xx = 0.82

        break
      case 8:
        xx = 0.82

        break
      case 9:
        xx = 0.82

        break
      case 10:
        xx = 0.82

        break
      case 11:
        xx = 0.82

        break
      default:
        xx = 0
    }
  }
  let l52 = (k52 / xx) * (1 - parameters().I52)
  let n52 = l52 * c18
  //let m66 = parameters().C32*b9
  // let m67 = m66*3
  //let m64 = 0.5
  let r52 = (m67 * parameters().M64 * 0.34 * m * parameters().C31) / 1000

  let s52 = (r52 / fc17(zrodloCiepla, wiekBudynku)) * (wentylacja ? (1 - parameters().J52) : (1 - parameters().I52))
  let t52 = s52 * c18
  let ab52 = n52 - t52
  let af52 = wentylacja ? ab52 : 0 //JEŻELI(Przedsiewziecia!C14="Tak";AB52;0)
  let b7local = liczbaKondygnacji
  let f10local = b7local - Math.trunc(b7local)
  let c12 = f10local > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj;
  let e53 = Math.ceil(
    (c12 * parameters().F12 * liczbaKondygnacji) / 10000
  )
  let m61 = 40
  let d67 = fotowoltanika ? m61 * c6 : 0
  let k53 = d67
  let r53 = k53
  let l53 = k53
  let tabeleD9 = TABLE2_4()[7];
  let n53 = l53 * tabeleD9
  let s53 = fotowoltanika ? r53 - e53 * (1000 - 70) : 0
  let t53 = s53 * tabeleD9
  let ab53 = n53 - t53

  let af53 = fotowoltanika ? ab53 : 0 //=JEŻELI(Przedsiewziecia!C15="Tak";AB53;0)

  let c19 = 0
  /* for(let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;2;FAŁSZ)
        if (k == wodaUz) c19 = TABLE2()[k]
      }*/
  if (wodaUz != 5) c19 = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        c19 = 0.6

        break
      case 2:
        c19 = 0.6

        break
      case 3:
        c19 = 0.6

        break
      case 4:
        c19 = 0.6

        break
      case 5:
        c19 = 0.65

        break
      case 6:
        c19 = 0.65

        break
      case 7:
        c19 = 0.82

        break
      case 8:
        c19 = 0.82

        break
      case 9:
        c19 = 0.82

        break
      case 10:
        c19 = 0.82

        break
      case 11:
        c19 = 0.82

        break
      default:
        c19 = 0
    }
  }
  let n54 = (l54 * c20) / c19
  x = 0
  /*for(let k in TABLE2_4) { //WYSZUKAJ.PIONOWO($E$17;Tabele!$J$5:$T$18;4;FAŁSZ)/C19
        if (k == zrodlaCieplaW) x = TABLE2_4()[k]/c19
    }*/
  x = c20 / c19
  let t54 = s54 * x
  let ab54 = n54 - t54
  let af54 = instalacjaCieplekWodUz ? ab54 : 0 //=JEŻELI(Przedsiewziecia!C16="Tak";AB54;0)
  
  let sumaaf41af54 =
    af41 +
    af42 +
    af43 +
    af44 +
    af45 +
    af46 +
    af47 +
    af48 +
    af51 +
    af52 +
    af53 +
    af54

  
  let l36 = fl36(
    zrodloCiepla,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  )
  x = 0
  x = TABLE2_4()[zrodloCiepla]
  let n36 = l36 * x

  x = 0
  x = table2_4_func(zrodlaCieplaO, zrodloCiepla) //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;4;FAŁSZ

  let c8 = Math.sqrt(powierzchniaPoj)
  let c9 = c8
  let b7 = liczbaKondygnacji
  let c11 = (2 * c9 + 2 * c8) * 3 * b7
  let e36 = c11 - b14 - c14
  let j36 = table6_func(wiekBudynku, 3)
  let r36 = e36 * j36 * 1 * tempM
  x = 0
  x = table2_3_func(zrodlaCieplaO, zrodloCiepla) //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ

  let s36 = r36 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))
  x = 0
  x = table2_4_func(zrodlaCieplaO, zrodloCiepla)
  let t36 = s36 * x
  let ab36 = n36 - t36
  let af36 = oc(ocieplenie, 1) ? ab36 : 0 //JEŻELI(Przedsiewziecia!C4="Tak";AB36;0)

  x = 0
  if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        x = 0.6

        break
      case 2:
        x = 0.6

        break
      case 3:
        x = 0.6

        break
      case 4:
        x = 0.6

        break
      case 5:
        x = 0.65

        break
      case 6:
        x = 0.65

        break
      case 7:
        x = 0.82

        break
      case 8:
        x = 0.82

        break
      case 9:
        x = 0.82

        break
      case 10:
        x = 0.82

        break
      case 11:
        x = 0.82

        break
      default:
        x = 0
    }
  }
  let l37 =
    k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) /
    (parameters().I51 * x)
  x = 0
  //for(let k in TABLE2_4) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;4;FAŁSZ)
  //if (k == zrodlaCieplaO)
  x = TABLE2_4()[zrodloCiepla]
  //}

  let n37 = l37 * x
  x = 0
  /*for(let k in TABLE2_4) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;4;FAŁSZ
        if (k == 0) x = TABLE2_4()[k]
    }*/
  x = table2_4_func(zrodlaCieplaO, zrodloCiepla);

  let e37 = c13 + c14
  let j37 = table8_func(wiekBudynku, 2)
  let r37 = e37 * j37 * 1 * tempM
  let x1 = 0
  //for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
  //    if (k == 0)
  x1 = table2_3_func(zrodlaCieplaO, zrodloCiepla)
  //}

  let s37 = r37 / (x1 * (instalacjaGrzewcza ? 87.4 * 0.01 : 61.6 * 0.01))

  let t37 = s37 * x

  let ab37 = n37 - t37
  let af37 = wymianaOkien ? ab37 : 0 //=JEŻELI(Przedsiewziecia!C5="Tak";AB37;0)

  x = efficiencyBefore({zrodloCiepla, wiekBudynku});
  let l38 =
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) /
    (parameters().I51 * x)
  //for(let k in TABLE2_4) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T15;4;FAŁSZ)
  //  if (k == zrodlaCieplaO)
  x = TABLE2_4()[zrodloCiepla]
  //}
  let n38 = l38 * x
  x = 0
  //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
  x = table2_3_func(zrodlaCieplaO, zrodloCiepla)

  let f10 = liczbaKondygnacji - Math.trunc(liczbaKondygnacji)
  let e38 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj //c12
  let j38 = table7_func(wiekBudynku, 3)
  let r38 = e38 * j38 * 0.8 * tempM
  let s38 = r38 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))
  x = 0
  //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;4;FAŁSZ
  x = table2_4_func(zrodlaCieplaO, zrodloCiepla)
  let t38 = s38 * x //S38*WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;4;FAŁSZ)
  let ab38 = n38 - t38

  let af38 = oc(ocieplenie, 2) ? ab38 : 0 //=JEŻELI(Przedsiewziecia!C6="Tak";AB38;0)


  let l39 = fl39({ocieplenie, zrodloCiepla, powierzchniaPoj, miasto, wiekBudynku})
  //for(let k in TABLE2_4) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T15;4;FAŁSZ)
  //if (k == zrodlaCieplaO)
  x = 0
  x = TABLE2_4()[zrodloCiepla]
  // }
  let n39 = l39 * x
  //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;4;FAŁSZ
  x = 0
  x = table2_4_func(zrodlaCieplaO, zrodloCiepla)

  //let e39 = powierzchniaPoj
  //let j39 = 0.3
  //let r39 = (e39*j39*0.6)*tempM
  //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;4;FAŁSZ
  /*  x =0
               x = TABLE2_4()[zrodlaCieplaO]*/

  let s39 = fs39(
    ocieplenie,
    zrodloCiepla,
    powierzchniaPoj,
    miasto,
    instalacjaGrzewcza,
    zrodlaCieplaO,
    wiekBudynku
  )
  let t39 = s39 * x
  let ab39 = n39 - t39
  let af39 = oc(ocieplenie, 3) ? ab39 : 0 //=JEŻELI(Przedsiewziecia!C7="Tak";AB39;0)

  let sumaaf36af39 = af36 + af37 + af38 + af39

  let sumaaf49af54 = af49 + af50 + af51 + af52 + af53 + af54
  let af55 =
    sumaAC41AC48 > 0 ? af40 + sumaaf41af54 : sumaaf36af39 + af40 + sumaaf49af54
  return af55
}

export function smartfony (f70) {
  let f81 = Math.ceil((f70 * 1000) / parameters().H81)

  let wynik = f70 <= 0 ? 0 : f81 //=JEŻELI(Obliczenia!F70<=0;0;Obliczenia!F81)

  return wynik
}

export function co2Before ({
  ocieplenie,
  wodaUz,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  typOkien,
  stopienOciepleniaDachu,
  zrodloCiepla,
  wiekBudynku,
  fotowoltanika,
  wymianaOkien,
  zrodlaCieplaW,
  instalacjaCieplekWodUz
}) {
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let d61 = fd61({
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    typOkien,
    stopienOciepleniaDachu,
    zrodloCiepla,
    wiekBudynku,
    wymianaOkien,
})

  let x = 0
  let d65 = fd65({
    wodaUz,
    zrodlaCieplaW,
    zrodloCiepla,
    instalacjaCieplekWodUz,
    powierzchniaPoj,
    liczbaKondygnacji,
    wiekBudynku
  })
  let m61 = 40

  let c6 = b9
  let d67 = fotowoltanika ? m61 * c6 : 0
  x = 0 //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T19;8;FAŁSZ)
  /*for (let k in TABLE2_8){
         if (k == zrodloCiepla) x = TABLE2_8()[k]
    }*/
  x = TABLE2_8()[zrodloCiepla]
  let y = 0 //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;8;FAŁSZ)
  /*for (let k in TABLE2_8){
        if (k == wodaUz) y = TABLE2_8()[k]
    }*/
  y = TABLE2_8()[wodaUz]
  let q8 = TABLE2_8()[11]
  let d69 = (d61 * x + d65 * y + d67 * q8) / Math.pow(10, 6) //((D61*WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T19;8;FAŁSZ)+D65*WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;8;FAŁSZ))+D67*Tabele!Q8)/(10^6)
  return d69
}

export function co2After ({
  fotowoltanika,
  wentylacja,
  wymianaOkien,
  instalacjaGrzewcza,
  ocieplenie,
  zrodloCiepla,
  stopienOciepleniaZew,
  typOkien,
  stopienOciepleniaDachu,
  kolektory,
  instalacjaCieplekWodUz,
  miasto,
  powierzchniaPoj,
  liczbaKondygnacji,
  wiekBudynku,
  zrodlaCieplaO,
  zrodlaCieplaW,
  wodaUz,
  d69,
  okna
}) {
  //let d69 =
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let c6 = b9
  
  let k51 = fk51({
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  })

  let ax13 = 0 //WYSZUKAJ.POZIOMO(AX18;AX18:AX29;AK17+1)*AT11
  /*for(let k in TABLE_D) {
        if (k == wiekBudynku) ax13 = TABLE_D()[k]
      }*/
  ax13 = fax13(
    wiekBudynku,
    stopienOciepleniaZew,
    ocieplenie,
    stopienOciepleniaDachu,
    okna,
    wymianaOkien
  )
  let e41 = Math.ceil(((c6 * ax13) / 1000) * 2) / 2

  let f41 = e41 * parameters().C41
  let ac41 = zrodlaCieplaO == 3 ? f41 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł na biomasę (pelety)";F41;0)

  let ac42 = ac41

  let e43 = e41
  let f43 = e43 * parameters().C43
  let ac43 = zrodlaCieplaO == 1 ? f43 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł gazowy";F43;0)

  let ac44 = ac43

  let e45 = e41
  let f45 = e45 * parameters().C45
  let ac45 = zrodlaCieplaO == 10 ? f45 : 0 //JEŻELI(Przedsiewziecia!C9="Powietrzna pompa ciepła";F45;0)

  let e46 = Math.round(
    ((Math.floor(c6 / 50) + 1) * parameters().Q60 * parameters().S60) /
      parameters().O60
  )
  let f46 = e46 * parameters().C46
  let ac46 = zrodlaCieplaW == 10 ? f46 : 0 //JEŻELI(Przedsiewziecia!C10="Powietrzna pompa ciepła";F46;0)

  let e47 = e41
  let f47 = e47 * parameters().C47
  let ac47 = zrodlaCieplaO == 11 ? f47 : 0 //JEŻELI(Przedsiewziecia!C9="Gruntowa pompa ciepła";F47;0)

  let ac48 = ac47

  let sumaAC41AC48 = ac41 + ac43 + ac45 + ac46 + ac47 //+ ac48;
  let r54 = parameters().M60 * b9 * parameters().C32
  let s54 = r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
  let k40 = s54
  let i40 = TABLE2()[zrodloCiepla]
  let j40 = TABLE2()[12] // kolektor słoneczny
  let l40 = zrodlaCieplaW === 12 ? (k40 * (1- j40)) / i40 : k40 / i40
  let r40 = s54
    let s40 = r40 * (1 - j40) / i40
  let aa40 = l40 - s40
  let ae40 = kolektory ? aa40 : 0
  let c26 = 0 //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;8;FAŁSZ)

  //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;8;FAŁSZ)

  c26 = TABLE2_8()[wodaUz]

  let m40 = (l40 * c26) / Math.pow(10, 6)
  let v40 = (s40 * c26) / Math.pow(10, 6) //S40*$C$26/10^6
  let ah40 = ae40 !== 0 ? m40 - v40 : 0 //JEŻELI($AE40<>0;M40-V40;0)
  let l51 = k51 / parameters().I51
  let k41 =
    (k36(
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    ) +
    k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) +
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) +
    k39(powierzchniaPoj, miasto))/ parameters().I51 + l51 * 0
  let c17 = fc17(zrodloCiepla, wiekBudynku)
  let i41 = c17
  let l41 = k41 / i41
  //let h16 = H15+(c6-I15)*parameters().H14
  let m = 0
  // for(let k in TABLE3) { //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  //  if (k == miasto)
  m = TABLE3()[miasto]
  //  }

  let tempM = (m * parameters().C31) / 1000
  let r41 = fr41(
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    instalacjaGrzewcza,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  )
  let s41 = r41 / TABLE2_3()[3]
  let aa41 = l41 - s41
  let ae41 = zrodlaCieplaO == 3 ? aa41 : 0
  let c22 = 0 //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;8;FAŁSZ)
  /*for (let k in TABLE2_8) {
        if (k == zrodloCiepla)
            c22 = TABLE2_8()[k]
    }*/
  c22 = TABLE2_8()[zrodloCiepla]
  let x = 0
  x = TABLE2_8()[3] //WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;8;FAŁSZ)
  let m41 = (l41 * c22) / Math.pow(10, 6)
  let v41 = (s41 / Math.pow(10, 6)) * x
  let ah41 = ae41 != 0 ? m41 - v41 : 0 //JEŻELI($AE41<>0;M41-V41;0)

  let k54 = parameters().M60 * b9 * parameters().C32
  let l54 = k54 / parameters().I54
  let k42 = l54
  x = 0
  //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)

  if (wodaUz != 5) x = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        x = 0.6

        break
      case 2:
        x = 0.6

        break
      case 3:
        x = 0.6

        break
      case 4:
        x = 0.6

        break
      case 5:
        x = 0.65

        break
      case 6:
        x = 0.65

        break
      case 7:
        x = 0.82

        break
      case 8:
        x = 0.82

        break
      case 9:
        x = 0.82

        break
      case 10:
        x = 0.82

        break
      case 11:
        x = 0.82

        break
      default:
        x = 0
    }
  }
  let i42 = x
  let l42 = k42 / i42
  let r42 = s54
  let j42 = 0.8
  let s42 = r42 / j42
  let aa42 = l42 - s42
  let e40 = 1
  let c40 = 10000
  let f40 = e40 * c40
  let ac40 = kolektory ? f40 : 0
  let al40 = ac40 > 0 ? 0 : 1
  let ae42 = (zrodlaCieplaW == 3 ? aa42 : 0) * al40
  let m42 = (l42 * c26) / Math.pow(10, 6)

  x = TABLE2_8()[3] //WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;8;FAŁSZ)

  let v42 = (s42 / Math.pow(10, 6)) * x //S42/10^6*WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;8;FAŁSZ)
  let ah42 = ae42 != 0 ? m42 - v42 : 0 //JEŻELI($AE42<>0;M42-V42;0)
  let ae43 = 0
  let l43 = 0
  let s43 = 0
  if (zrodlaCieplaO == 1) {
    let i43 = fc17(zrodloCiepla, wiekBudynku)
    let k43 = k41
    l43 = k43 / i43
    let r43 = r41
    let j43 = TABLE2_3()[1]
    s43 = r43 / j43
    let aa43 = l43 - s43
    ae43 = aa43
  }
  let m43 = (l43 * c22) / Math.pow(10, 6)
  x = TABLE2_8()[1] //WYSZUKAJ.PIONOWO(U43;Tabele!$J$5:$T$18;8;FAŁSZ)
  let v43 = (s43 / Math.pow(10, 6)) * x //S43/10^6*WYSZUKAJ.PIONOWO(U43;Tabele!$J$5:$T$18;8;FAŁSZ)
  let ah43 = ae43 != 0 ? m43 - v43 : 0 //JEŻELI($AE43<>0;M43-V43;0)

  let ae44 = 0
  let l44 = 0
  let s44 = 0
  if (zrodlaCieplaW == 1) {
    // let i54 = 39*0.01
    // let m60 = 20
    let k44 = l54
    let x = 0
    //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)

    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }

    let i44 = x
    l44 = k44 / i44
    let r44 = s54
    let j44 = TABLE2_3()[1]
    s44 = r44 / j44
    let aa44 = l44 - s44
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae44 = aa44 * al40
  }
  let m44 = (l44 * c26) / Math.pow(10, 6)
  x = TABLE2_8()[1] //WYSZUKAJ.PIONOWO(U44;Tabele!$J$5:$T$18;8;FAŁSZ)
  let v44 = (s44 / Math.pow(10, 6)) * x //S44/10^6*WYSZUKAJ.PIONOWO(U44;Tabele!$J$5:$T$18;8;FAŁSZ)
  let ah44 = ae44 != 0 ? m44 - v44 : 0 //=JEŻELI($AE44<>0;M44-V44;0)

  let ae45 = 0
  let l45 = 0
  let s45 = 0
  if (zrodlaCieplaO == 10) {
    let k45 = k41
    let i45 = fc17(zrodloCiepla, wiekBudynku)
    l45 = k45 / i45
    let r45 = r41
    let j45 = TABLE2_3()[10]
    s45 = r45 / j45
    let aa45 = l45 - s45
    ae45 = aa45
  }
  let m45 = (l45 * c22) / Math.pow(10, 6)
  x = TABLE2_8()[7] //WYSZUKAJ.PIONOWO(U45;Tabele!$J$5:$T$18;8;FAŁSZ)
  let v45 = (s45 / Math.pow(10, 6)) * x
  let ah45 = ae45 != 0 ? m45 - v45 : 0 //=JEŻELI($AE45<>0;M45-V45;0)
  let ae46 = 0
  let l46 = 0
  //let m60 = 20
  //let r54 = parameters().M60 * b9 * parameters().C32
  //let s54 = r54 / (instalacjaCieplekWodUz == 1 ? parameters().J54 : parameters().I54)
  let s46 = 0
  if (zrodlaCieplaW == 10) {
    let k46 = s54
    let x = 0
    /*for (let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)
            if (k == wodaUz)
                x = TABLE2()[k]
        }*/
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let i46 = x
    l46 = k46 / i46
    let r46 = s54
    let j46 = 3.2
    s46 = r46 / j46
    let aa46 = l46 - s46
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae46 = aa46 * al40
  }
  let m46 = (l46 * c26) / Math.pow(10, 6)
  x = TABLE2_8()[7] //WYSZUKAJ.PIONOWO(U46;Tabele!$J$5:$T$18;8;FAŁSZ)
  let v46 = (s46 / Math.pow(10, 6)) * x //=S46/10^6*WYSZUKAJ.PIONOWO(U46;Tabele!$J$5:$T$18;8;FAŁSZ)
  let ah46 = ae46 != 0 ? m46 - v46 : 0 //JEŻELI($AE46<>0;M46-V46;0)
  let ae47 = 0
  let l47 = 0
  let s47 = 0
  if (zrodlaCieplaO == 11) {
    let k47 = k41
    let i47 = fc17(zrodloCiepla, wiekBudynku)
    l47 = k47 / i47
    let r47 = r41
    let j47 = 3.6
    s47 = r47 / j47
    let aa47 = l47 - s47
    ae47 = aa47
  }
  //}
  let m47 = (l47 * c22) / Math.pow(10, 6)
  x = TABLE2_8()[7] //WYSZUKAJ.PIONOWO(U46;Tabele!$J$5:$T$18;8;FAŁSZ)
  let v47 = (s47 / Math.pow(10, 6)) * x
  let ah47 = ae47 != 0 ? m47 - v47 : 0 //JEŻELI($AE47<>0;M47-V47;0)

  let ae48 = 0
  let l48 = 0
  let s48 = 0
  //let l54 = k54 / parameters().I54
  if (zrodlaCieplaW == 11) {
    // let i54 = 39*0.01
    // let m60 = 20
    let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
    let k54 = parameters().M60 * b9 * parameters().C32

    let m66 = parameters().C32 * b9
    let m67 = m66 * 3
    let m = 0
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = TABLE3()[miasto]

    let temp = (m * parameters().C31) / 1000
    let k52 = m67 * parameters().M64 * 0.34 * temp
    let k48 = l54
    let x = 0
    let i48 = efficiencyBefore({zrodloCiepla: wodaUz, wiekBudynku});
    l48 = k48 / i48
    let r48 = s54
    let j48 = 3.6
    s48 = r48 / j48
    let aa48 = l48 - s48
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae48 = aa48 * al40
  }
  let m48 = (l48 * c26) / Math.pow(10, 6)
  x = TABLE2_8()[7] //WYSZUKAJ.PIONOWO(U48;Tabele!$J$5:$T$18;8;FAŁSZ)
  let v48 = (s48 / Math.pow(10, 6)) * x
  let ah48 = ae48 != 0 ? m48 - v48 : 0 // JEŻELI($AE48<>0;M48-V48;0)

  let ah49 = 0
  let ah50 = 0

  let ae51 = 0
  //let l51 = 0
  let s51 = 0
  if (instalacjaGrzewcza) {
    //let i51 = 61.6 * 0.01
    l51 = k51 / parameters().I51
    let r51 = k51
    s51 = r51 / (instalacjaGrzewcza ? parameters().J51 : parameters().I51)
    let aa51 = l51 - s51
    ae51 = aa51
  }
  let m51 = (l51 * c22) / Math.pow(10, 6)
  let v51 = (s51 * c22) / Math.pow(10, 6)
  
  let ah51 = ae51 !== 0 ? m51 - v51 : 0 //EŻELI($AE51<>0;M51-V51;0)
  ////////////////console.log('aah511',ah51)

  let ae52 = 0
  let l52 = 0
  let s52 = 0
  if (wentylacja) {
    //let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
    //   let m66 = parameters().C32*b9
    //   let m67 = m66*3
    let m = 0
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = TABLE3()[miasto]

    let temp = (m * parameters().C31) / 1000
    let m66 = parameters().C32 * b9
    let m67 = m66 * 3
    let k52 = m67 * parameters().M64 * 0.34 * temp
    let i51 = 61.6 * 0.01
    l52 = (k52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().I52)
    // let c32 = 1

    //let m64 = 0.5
    let r52 = (m67 * parameters().M64 * 0.34 * m * parameters().C31) / 1000
    //let j52 = 0.85
    s52 = (r52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().J52)
    let aa52 = l52 - s52
    ae52 = aa52
  }
  let m52 = (l52 * c22) / Math.pow(10, 6)
  let v52 = (s52 / Math.pow(10, 6)) * c22
  let ah52 = ae52 !== 0 ? m52 - v52 : 0 //JEŻELI($AE52<>0;M52-V52;0)

  let b7local = liczbaKondygnacji
  let f10 = b7local - Math.trunc(b7local)
  let c12 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj;
  let e53 = Math.ceil(
    (c12 * parameters().F12 * liczbaKondygnacji) / 10000
  )
  let m61 = 40
  let d67 = fotowoltanika ? m61 * c6 : 0
  let k53 = d67
  let r53 = k53
  let l53 = k53
  x = TABLE2_8()[7] //WYSZUKAJ.PIONOWO(U46;Tabele!$J$5:$T$18;8;FAŁSZ)
  let m53 = (l53 / Math.pow(10, 6)) * x //=L53/10^6*WYSZUKAJ.PIONOWO($U$53;Tabele!$J$5:$T$18;8;FAŁSZ)
  let s53 = fotowoltanika ? r53 - e53 * (1000 - 70) : 0
  let v53 = (s53 / Math.pow(10, 6)) * x
  let ah53 = fotowoltanika ? m53 - v53 : 0 //=JEŻELI(Przedsiewziecia!C15="Tak";M53-V53;0)
  let u54 = zrodlaCieplaW
  let aa54 = l54 - s54
  let ae54 = instalacjaCieplekWodUz ? /*l54 - s54*/ aa54 : 0
  let c19 = 0
  /*for(let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;2;FAŁSZ)
        if (k == wodaUz) c19 = TABLE2()[k]
      }*/
  if (wodaUz != 5) c19 = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        c19 = 0.6

        break
      case 2:
        c19 = 0.6

        break
      case 3:
        c19 = 0.6

        break
      case 4:
        c19 = 0.6

        break
      case 5:
        c19 = 0.65

        break
      case 6:
        c19 = 0.65

        break
      case 7:
        c19 = 0.82

        break
      case 8:
        c19 = 0.82

        break
      case 9:
        c19 = 0.82

        break
      case 10:
        c19 = 0.82

        break
      case 11:
        c19 = 0.82

        break
      default:
        c19 = 0
    }
  }
  let m54 = (l54 * c26) / c19 / Math.pow(10, 6)
  let z = 0
  /*for(let k in TABLE2_8) { //WYSZUKAJ.PIONOWO($E$17;Tabele!$J$5:$T$18;8;FAŁSZ)
        if (k == zrodlaCieplaW) z = TABLE2_8()[k]
      }*/
  z = TABLE2_8()[wodaUz]
  let w = 0
  /*for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$17;Tabele!$J$5:$T$18;3;FAŁSZ)
          if (k == zrodlaCieplaW) w = TABLE2_3()[k]
        }*/
  w = TABLE2()[wodaUz] // bo  Istniejące źródło ciepła dla c.w.u.
  let v54 = (s54 * z) / (Math.pow(10, 6) * w)  
  let ah54 = ae54 != 0 ? m54 - v54 : 0 //JEŻELI($AE54<>0;M54-V54;0)
  let ae36 = 0
  let l36 = fl36(
    zrodloCiepla,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  )
  let s36 = 0
  if (oc(ocieplenie, 1)) {
    //let i51 = 61.6 * 0.01
    let x = 0
    /*for(let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
            if (k == zrodloCiepla) x = TABLE2()[k]
        }*/
    if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    //l36 = k36(powierzchniaPoj, liczbaKondygnacji, stopienOciepleniaZew, miasto)/(parameters().I51*x)
    let c9 = Math.sqrt(powierzchniaPoj)
    let c8 = c9
    let b7 = liczbaKondygnacji
    let c11 = (2 * c9 + 2 * c8) * 3 * b7
    let e8 = parameters().E7 + (c6 - parameters().F7) * parameters().E6
    let b14 = Math.round(c6 * e8 * 10) / 10
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
    let c13 = b14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let e36 = c11 - b14 - c14
    let j36 = table6_func(wiekBudynku, 3)
    let r36 = e36 * j36 * 1 * tempM
    /* for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
            if (k == 0) x = TABLE2_3()[k]
        }*/
    x = 0
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
    s36 = r36 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))

    let aa36 = l36 - s36
    ae36 = aa36
  }
  let m36 = (l36 * c22) / Math.pow(10, 6)
  x = 0
  /*for(let k in TABLE2_8) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;8;FAŁSZ
        if (k == 0) x = TABLE2_8()[k]
    }*/
  x = table2_8_func(zrodlaCieplaO, zrodloCiepla)
  let v36 = (s36 / Math.pow(10, 6)) * x
  let ah36 = ae36 != 0 ? m36 - v36 : 0 //JEŻELI($AE36<>0;M36-V36;0)
  let c9 = Math.sqrt(powierzchniaPoj)
  let c8 = c9
  let b7 = liczbaKondygnacji
  // let c11 = (2*c9+2*c8)*3*b7
  let e8 = parameters().E7 + (c6 - parameters().F7) * parameters().E6
  let b14 = Math.round(c6 * e8 * 10) / 10

  let ae37 = 0
  let l37 = 0
  let s37 = 0
  if (wymianaOkien) {
    let x = 0
    /*for(let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
                if (k == zrodloCiepla) x = TABLE2()[k]
            }*/
    if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    l37 =
      k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) /
      (parameters().I51 * x)
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
    let c13 = b14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let e37 = c13 + c14
    let j37 = table8_func(wiekBudynku, 2)
    let r37 = e37 * j37 * 1 * tempM
    /*for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
                if (k == 0) x = TABLE2_3()[k]
            }*/
    x = 0
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
    s37 = r37 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : 61.6 * 0.01))
    let aa37 = l37 - s37
    ae37 = aa37
  }

  let ae38 = 0
  let l38 = 0
  let s38 = 0
  if (oc(ocieplenie, 2)) {
    let x = 0
    /*for(let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T15;2;FAŁSZ))
                if (k == zrodloCiepla) x = TABLE2()[k]
            }*/
   x = efficiencyBefore({zrodloCiepla, wiekBudynku});
    l38 =
      k38(
        liczbaKondygnacji,
        powierzchniaPoj,
        stopienOciepleniaDachu,
        miasto,
        wiekBudynku
      ) /
      (parameters().I51 * x)
    /*for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
                if (k == 0) x = TABLE2_3()[k]
            }*/
    x = 0
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
    let b7local = liczbaKondygnacji
    let f10 = b7local - Math.trunc(b7local)
    let e38 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj //c12
    let j38 = table7_func(wiekBudynku, 3)
    let r38 = e38 * j38 * 0.8 * tempM
    s38 = r38 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))
    let aa38 = l38 - s38
    ae38 = aa38
  }

  let ae39 = fae39(
    ocieplenie,
    zrodloCiepla,
    powierzchniaPoj,
    miasto,
    instalacjaGrzewcza,
    zrodlaCieplaO,
    wiekBudynku
  )

  let m37 = (l37 * c22) / Math.pow(10, 6)
  x = 0
  /*for(let k in TABLE2_8) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;8;FAŁSZ
        if (k == 0) x = TABLE2_8()[k]
    }*/
  x = table2_8_func(zrodlaCieplaO, zrodloCiepla)
  let v37 = (s37 / Math.pow(10, 6)) * x
  let ah37 = ae37 != 0 ? m37 - v37 : 0 //=JEŻELI($AE37<>0;M37-V37;0)

  let m38 = (l38 * c22) / Math.pow(10, 6)
  let v38 = (s38 / Math.pow(10, 6)) * x
  let ah38 = ae38 != 0 ? m38 - v38 : 0 //=JEŻELI($AE37<>0;M37-V37;0)
  ////////////////////console.log('ah38',ah38)
  ////////////////////console.log('m38',m38,'v38',v38)
  let l39 = fl39({ocieplenie, zrodloCiepla, powierzchniaPoj, miasto, wiekBudynku})
  let s39 = fs39(
    ocieplenie,
    zrodloCiepla,
    powierzchniaPoj,
    miasto,
    instalacjaGrzewcza,
    zrodlaCieplaO,
    wiekBudynku
  )
  let m39 = (l39 * c22) / Math.pow(10, 6)
  let v39 = (s39 / Math.pow(10, 6)) * x
  let ah39 = ae39 != 0 ? m39 - v39 : 0 //=JEŻELI($AE37<>0;M37-V37;0)

  let sumaAH41AH54 =
    ah41 +
    ah42 +
    ah43 +
    ah44 +
    ah45 +
    ah46 +
    ah47 +
    ah48 +
    ah49 +
    ah51 +
    ah52 +
    ah53 + 
    ah54 //SUMA(AH41:AH54)

  let sumaAH41AH48 =
    ah41 +
    ah42 +
    ah43 +
    ah44 +
    ah45 +
    ah46 +
    ah47 +
    ah48
  
  let sumaAH51AH54 = ah51 + ah52 + ah53 + ah54

  let sumaAH36AH39 = ah36 + ah37 + ah38 + ah39

  let sumaAH49AH54 = ah49 + ah50 + ah51 + ah52 + ah53 + ah54
  let ah55 =
    sumaAC41AC48 > 0 ? ah40 + sumaAH41AH48 + sumaAH51AH54 : sumaAH36AH39 + ah40 + sumaAH49AH54 //EŻELI(SUMA(AC41:AC48)>0;AH40+SUMA(AH41:AH54);SUMA(AH36:AH39)+AH40+SUMA(AH49:AH54))
    let ah60 = ah55 - ah53 
  let f71 = d69 * 0.9
  let f69 = ah60 > f71 ? f71 + ah53 : ah60 + ah53 //=JEŻELI(AH60>F71;F71+AH53;AH60+AH53)
  return f69
}

export function drzewka (f69) {
  //JEŻELI(AH60>F71;F71+AH53;AH60+AH53) - f69
  return Math.ceil((f69 * 1000) / 127.8) //ZAOKR.W.GÓRĘ.MATEMATYCZNE(F69*1000/$H$77;1)
}

export function pylBefore ({
  ocieplenie,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  typOkien,
  stopienOciepleniaDachu,
  zrodloCiepla,
  wodaUz,
  zrodlaCieplaW,
  zrodlaCieplaO,
  wiekBudynku,
  fotowoltanika,
  wymianaOkien,
  instalacjaCieplekWodUz
}) {
  let d61 = fd61({
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    typOkien,
    stopienOciepleniaDachu,
    zrodloCiepla,
    wiekBudynku,
    wymianaOkien
})
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let x = 0
  let d65 = fd65({
    wodaUz,
    zrodlaCieplaW,
    zrodloCiepla,
    instalacjaCieplekWodUz,
    powierzchniaPoj,
    liczbaKondygnacji,
    wiekBudynku
  })
  let m61 = 40

  let c6 = b9
  let d67 = fotowoltanika ? m61 * c6 : 0

  x = 0 //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T19;9;FAŁSZ)
  x = TABLE2_9()[zrodloCiepla]

  let y = 0 //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;9;FAŁSZ)
  y = TABLE2_9()[wodaUz]
  let tableR8 = TABLE2_9()[9]

  let d73 = (d61 * x + d65 * y + d67 * tableR8) / Math.pow(10, 3)

  return d73
}

export function pylAfter (
  d73, {
  zrodlaCieplaO,
  zrodlaCieplaW,
  powierzchniaPoj,
  liczbaKondygnacji,
  wiekBudynku,
  kolektory,
  instalacjaCieplekWodUz,
  miasto,
  zrodloCiepla,
  wodaUz,
  instalacjaGrzewcza,
  wentylacja,
  fotowoltanika,
  stopienOciepleniaZew,
  ocieplenie,
  wymianaOkien,
  typOkien,
  stopienOciepleniaDachu,
  okna
  }) {
  let m = 0
  m = TABLE3()[miasto] //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)

  let k51 = fk51({
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  })

  let tempM = (m * parameters().C31) / 1000
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let c6 = b9

  let ax13 = 0 //WYSZUKAJ.POZIOMO(AX18;AX18:AX29;AK17+1)*AT11
  /* for(let k in TABLE_D) {
        if (k == wiekBudynku) ax13 = TABLE_D()[k]
      }*/
  ax13 = fax13(
    wiekBudynku,
    stopienOciepleniaZew,
    ocieplenie,
    stopienOciepleniaDachu,
    okna,
    wymianaOkien
  )
  let e41 = Math.ceil(((c6 * ax13) / 1000) * 2) / 2

  let f41 = e41 * parameters().C41
  let ac41 = zrodlaCieplaO == 3 ? f41 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł na biomasę (pelety)";F41;0)

  // let ac42 = ac41

  let e43 = e41
  let f43 = e43 * parameters().C43
  let ac43 = zrodlaCieplaO == 1 ? f43 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł gazowy";F43;0)

  // let ac44 = ac43

  let e45 = e41
  let f45 = e45 * parameters().C45
  let ac45 = zrodlaCieplaO == 10 ? f45 : 0 //JEŻELI(Przedsiewziecia!C9="Powietrzna pompa ciepła";F45;0)

  let e46 = Math.round(
    ((Math.floor(c6 / 50) + 1) * parameters().Q60 * parameters().S60) /
      parameters().O60
  )
  let f46 = e46 * parameters().C46
  let ac46 = zrodlaCieplaW == 10 ? f46 : 0 //JEŻELI(Przedsiewziecia!C10="Powietrzna pompa ciepła";F46;0)

  let e47 = e41
  let f47 = e47 * parameters().C47
  let ac47 = zrodlaCieplaO == 11 ? f47 : 0 //JEŻELI(Przedsiewziecia!C9="Gruntowa pompa ciepła";F47;0)

  let ac48 = ac47

  let sumaAC41AC48 = ac41 + ac43 + ac45 + ac46 + ac47;

  let ae40 = 0
  let l40 = 0
  let s40 = 0
  //let j54 = 0.56
  if (kolektory) {
    let r54 = parameters().M60 * b9 * parameters().C32
    let s54 =
      r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
    let k40 = s54    
    let i40 = TABLE2()[zrodloCiepla]
    let j40 = TABLE2()[12] // kolektor słoneczny
    l40 = zrodlaCieplaW === 12 ? (k40 * (1- j40)) / i40 : k40 / i40
    let r40 = s54
    s40 = r40 * (1 - j40) / i40
    let aa40 = l40 - s40
    ae40 = aa40
  }

  let c27 = 0 //=WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;9;FAŁSZ)

  c27 = TABLE2_9()[wodaUz]

  let o40 = (l40 * c27) / 1000
  let w40 = (s40 * c27) / 1000
  let ai40 = ae40 != 0 ? o40 - w40 : 0 //=JEŻELI($AE40<>0;O40-W40;0)
  let l51 = k51 / parameters().I51
  let k41 =
    (k36(
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    ) +
    k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) +
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) +
    k39(powierzchniaPoj, miasto))/ parameters().I51 + l51 * 0
  let c17 = fc17(zrodloCiepla, wiekBudynku)
  let i41 = c17
  let l41 = k41 / i41
  //let h16 = H15+(c6-I15)*parameters().H14

  for (let k in TABLE3) {
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    if (k == miasto) m = TABLE3()[k]
  }

  //let tempM = m*parameters().C31/1000
  let r41 = fr41(
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    instalacjaGrzewcza,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  )
  let s41 = r41 / TABLE2_3()[3]
  let aa41 = l41 - s41
  ////////////////console.log('aa41',aa41)
  let ae41 = zrodlaCieplaO == 3 ? aa41 : 0
  let c23 = 0 //=WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;9;FAŁSZ)
  c23 = TABLE2_9()[zrodloCiepla]

  let o41 = (l41 * c23) / 1000
  let w41 = (s41 / 1000) * TABLE2_9()[3] //S41/10^3*WYSZUKAJ.PIONOWO(U41;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai41 = ae41 !== 0 ? o41 - w41 : 0 //=JEŻELI($AE41<>0;O41-W41;0)
  ////////////////console.log('ai41',ai41)
  let c22 = 0 //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;8;FAŁSZ)

  c22 = TABLE2_8()[zrodloCiepla]

  let x = 0
  x = TABLE2_8()[3] //WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;8;FAŁSZ)
  let m41 = (l41 * c22) / Math.pow(10, 6)
  let v41 = (s41 / Math.pow(10, 6)) * x
  let ah41 = ae41 != 0 ? m41 - v41 : 0 //JEŻELI($AE41<>0;M41-V41;0)

  let k54 = parameters().M60 * b9 * parameters().C32
  let l54 = k54 / parameters().I54
  let k42 = l54
  x = 0
  //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)

  //       x = TABLE2()[wodaUz]
  if (wodaUz != 5) x = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        x = 0.6

        break
      case 2:
        x = 0.6

        break
      case 3:
        x = 0.6

        break
      case 4:
        x = 0.6

        break
      case 5:
        x = 0.65

        break
      case 6:
        x = 0.65

        break
      case 7:
        x = 0.82

        break
      case 8:
        x = 0.82

        break
      case 9:
        x = 0.82

        break
      case 10:
        x = 0.82

        break
      case 11:
        x = 0.82

        break
      default:
        x = 0
    }
  }
  let i42 = x
  let l42 = k42 / i42
  let r42 = r41
  let j42 = 0.8
  let s42 = r42 / j42
  let aa42 = l42 - s42
  let e40 = 1
  let c40 = 10000
  let f40 = e40 * c40
  let ac40 = kolektory ? f40 : 0
  let al40 = ac40 > 0 ? 0 : 1
  let ae42 = (zrodlaCieplaW == 3 ? aa42 : 0) * al40
  let o42 = (l42 * c27) / 1000
  let w42 = (s42 / 1000) * TABLE2_9()[3] //WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;9;FAŁSZ)

  let ai42 = ae42 != 0 ? o42 - w42 : 0

  let ae43 = 0
  let l43 = 0
  let s43 = 0
  if (zrodlaCieplaO == 1) {
    let i43 = fc17(zrodloCiepla, wiekBudynku)
    let k43 = k41
    l43 = k43 / i43
    let r43 = r41
    let j43 = TABLE2_3()[1]
    s43 = r43 / j43
    let aa43 = l43 - s43
    ae43 = aa43
  }

  let o43 = (l43 * c23) / 1000
  let w43 = (s43 / 1000) * TABLE2_9()[1] //WYSZUKAJ.PIONOWO(U43;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai43 = ae43 != 0 ? o43 - w43 : 0

  let ae44 = 0
  let l44 = 0
  let s44 = 0
  if (zrodlaCieplaW == 1) {
    // let i54 = 39*0.01
    // let m60 = 20
    let k44 = l54
    let x = 0
    /* for (let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)
            if (k == wodaUz)
                x = TABLE2()[k]
        }*/
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let i44 = x
    l44 = k44 / i44
    let r44 = s54
    let j44 = TABLE2_3()[1]
    s44 = r44 / j44
    let aa44 = l44 - s44
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae44 = aa44 * al40
  }
  let o44 = (l44 * c27) / 1000
  let w44 = (s44 / 1000) * TABLE2_9()[1] //WYSZUKAJ.PIONOWO(U44;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai44 = ae44 != 0 ? o44 - w44 : 0

  let ae45 = 0
  let l45 = 0
  let s45 = 0
  if (zrodlaCieplaO == 10) {
    let k45 = k41
    let i45 = fc17(zrodloCiepla, wiekBudynku)
    l45 = k45 / i45
    let r45 = r41
    let j45 = TABLE2_3()[10]
    s45 = r45 / j45
    let aa45 = l45 - s45
    ae45 = aa45
  }
  let o45 = (l45 * c23) / 1000
  let w45 = (s45 / 1000) * TABLE2_9()[7] //WYSZUKAJ.PIONOWO(U45;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai45 = ae45 != 0 ? o45 - w45 : 0

  let ae46 = 0
  //let m60 = 20
  let s46 = 0
  let l46 = 0
  let r54 = parameters().M60 * b9 * parameters().C32
  let s54 = r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
  if (zrodlaCieplaW == 10) {
    let k46 = s54
    let x = 0
    /*for (let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)
            if (k == wodaUz)
                x = TABLE2()[k]
        }*/
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let i46 = x
    l46 = k46 / i46
    ////////////////console.log('l46',l46)
    let r46 = s54
    let j46 = 3.2
    s46 = r46 / j46
    let aa46 = l46 - s46
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae46 = aa46 * al40
  }
  let o46 = (l46 * c27) / 1000
  let w46 = (s46 / 1000) * TABLE2_9()[7] //WYSZUKAJ.PIONOWO(U46;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai46 = ae46 != 0 ? o46 - w46 : 0
  let ae47 = 0
  let l47 = 0
  let s47 = 0
  if (zrodlaCieplaO == 11) {
    let k47 = k41
    let i47 = fc17(zrodloCiepla, wiekBudynku)
    l47 = k47 / i47
    let r47 = r41
    let j47 = 3.6
    s47 = r47 / j47
    let aa47 = l47 - s47
    ae47 = aa47
  }
  let o47 = (l47 * c23) / 1000
  let w47 = (s47 / 1000) * TABLE2_9()[7] //*WYSZUKAJ.PIONOWO(U47;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai47 = ae47 != 0 ? o47 - w47 : 0

  let ae48 = 0
  let s48 = 0
  let l48 = 0
  k54 = parameters().M60 * b9 * parameters().C32
  l54 = k54 / parameters().I54
  if (zrodlaCieplaW == 11) {
    // let i54 = 39*0.01
    // let m60 = 20

    let k48 = l54
    let x = 0
    let i48 = efficiencyBefore({zrodloCiepla: wodaUz, wiekBudynku});
    l48 = k48 / i48
    let r48 = s54
    let j48 = 3.6
    s48 = r48 / j48
    let aa48 = l48 - s48
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae48 = aa48 * al40
  }
  let o48 = (l48 * c27) / 1000
  let w48 = (s48 / 1000) * TABLE2_9()[7] //*WYSZUKAJ.PIONOWO(U48;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai48 = ae48 != 0 ? o48 - w48 : 0

  let ai49 = 0
  let ai50 = 0

  let ae51 = 0
  l51 = k51 / parameters().I51
  let r51 = k51
  let s51 = r51 / (instalacjaGrzewcza ? parameters().J51 : parameters().I51)
  let aa51 = l51 - s51
  ae51 = aa51

  let o51 = (l51 * c23) / 1000
  let w51 = (s51 * c23) / 1000

  let ai51 = ae51 !== 0 ? o51 - w51 : 0

  let ae52 = 0
  let l52 = 0
  let s52 = 0
  if (wentylacja) {
    let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
    let m66 = parameters().C32 * b9
    let m67 = m66 * 3
    let m = 0
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = TABLE3()[miasto]

    let temp = (m * parameters().C31) / 1000
    let k52 = m67 * parameters().M64 * 0.34 * temp
    //let i51 = 61.6 * 0.01
    l52 = (k52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().I52)
    // let c32 = 1
    // let m66 = parameters().C32*b9
    //  let m67 = m66*3
    //let m64 = 0.5
    let r52 = (m67 * parameters().M64 * 0.34 * m * parameters().C31) / 1000
    // let j52 = 0.85
    s52 = (r52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().J52)
    let aa52 = l52 - s52
    ae52 = aa52
  }
  let o52 = (l52 * c23) / 1000
  x = 0
  /*for(let k in TABLE2_9) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;8;FAŁSZ
        if (k == 0) x = TABLE2_9()[k]
    }*/
  x = table2_9_func(zrodlaCieplaO, zrodloCiepla)
  let w52 = (s52 / 1000) * c27 // *WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;9;FAŁSZ)
  //let o52 = l52*c23/1000
  let ai52 = ae52 != 0 ? o52 - w52 : 0
let b7local = liczbaKondygnacji
  let f10 = b7local - Math.trunc(b7local)
  let c12 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj;
  let e53 = Math.ceil(
    (c12 * parameters().F12 * liczbaKondygnacji) / 10000
  )
  let m61 = 40
  let d67 = fotowoltanika ? m61 * c6 : 0
  let k53 = d67
  let r53 = k53
  let l53 = k53
  let s53 = fotowoltanika ? r53 - e53 * (1000 - 70) : 0
  let w53 = (s53 / Math.pow(10, 3)) * TABLE2_9()[7]
  let o53 = (l53 / 1000) * TABLE2_9()[7] //WYSZUKAJ.PIONOWO($U$53;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai53 = fotowoltanika ? o53 - w53 : 0 //JEŻELI(Przedsiewziecia!C15="Tak";O53-W53;0)

  let u54 = zrodlaCieplaW
  let aa54 = l54 - s54
  let ae54 = instalacjaCieplekWodUz ? /*l54 - s54*/ aa54 : 0
  // let c19 = TABLE2()[wodaUz]
  let c19 = 0
  if (wodaUz != 5) c19 = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        c19 = 0.6

        break
      case 2:
        c19 = 0.6

        break
      case 3:
        c19 = 0.6

        break
      case 4:
        c19 = 0.6

        break
      case 5:
        c19 = 0.65

        break
      case 6:
        c19 = 0.65

        break
      case 7:
        c19 = 0.82

        break
      case 8:
        c19 = 0.82

        break
      case 9:
        c19 = 0.82

        break
      case 10:
        c19 = 0.82

        break
      case 11:
        c19 = 0.82

        break
      default:
        c19 = 0
    }
  }
  let o54 = (l54 * c27) / c19 / 1000
  let w = 0
  /*  for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$17;Tabele!$J$5:$T$18;3;FAŁSZ)
          if (k == zrodlaCieplaW) w = TABLE2_3()[k]
        }*/
  w = table2_3_func(zrodlaCieplaW, wodaUz)
  let w54 = (s54 / (1000 * w)) * c27
  let ai54 = ae54 != 0 ? o54 - w54 : 0

  let sumaAI41AI54 =
    ai41 +
    ai42 +
    ai43 +
    ai44 +
    ai45 +
    ai46 +
    ai47 +
    ai48 +
    ai51 + 
    ai52 + 
    ai53 +
    ai54
  let sumaAI49AI54 = ai49 + ai50 + ai51 + ai52 + ai53 + ai54 
 
  let ae36 = 0
  let l36 = fl36(
    zrodloCiepla,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  )
  let s36 = 0
  if (oc(ocieplenie, 1)) {
    //let i51 = 61.6 * 0.01
    let x = 0
    /*for(let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
            if (k == zrodloCiepla) x = TABLE2()[k]
        }*/
    if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }

    let c9 = Math.sqrt(powierzchniaPoj)
    let c8 = c9
    let b7 = liczbaKondygnacji
    let c11 = (2 * c9 + 2 * c8) * 3 * b7
    let e8 = parameters().E7 + (c6 - parameters().F7) * parameters().E6
    let b14 = Math.round(c6 * e8 * 10) / 10
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
    let c13 = b14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let e36 = c11 - b14 - c14
    let j36 = table6_func(wiekBudynku, 3)
    let r36 = e36 * j36 * 1 * tempM
    x = 0
    /*for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
            if (k == 0) x = TABLE2_3()[k]
        }*/
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
    s36 = r36 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))

    let aa36 = l36 - s36
    ae36 = aa36
  }
  let o36 = (l36 * c23) / 1000
  x = 0
  /*for(let k in TABLE2_9) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;9;FAŁSZ
            if (k == 0) x = TABLE2_9()[k]
        }*/
  x = table2_9_func(zrodlaCieplaO, zrodloCiepla)
  let w36 = (s36 / 1000) * x //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai36 = ae36 != 0 ? o36 - w36 : 0 //JEŻELI($AE36<>0;O36-W36;0)

  let ae37 = 0
  let l37 = 0
  let s37 = 0
  let c9 = Math.sqrt(powierzchniaPoj)
  let c8 = c9
  let b7 = liczbaKondygnacji
  let c11 = (2 * c9 + 2 * c8) * 3 * b7
  let e8 = parameters().E7 + (c6 - parameters().F7) * parameters().E6
  let b14 = Math.round(c6 * e8 * 10) / 10
  if (wymianaOkien) {
    let x = 0
    /*for(let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
                if (k == zrodloCiepla) x = TABLE2()[k]
            }*/
    if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    l37 =
      k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) /
      (parameters().I51 * x)
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
    let c13 = b14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let e37 = c13 + c14
    let j37 = table8_func(wiekBudynku, 2)
    let r37 = e37 * j37 * 1 * tempM
    /*for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
                if (k == 0) x = TABLE2_3()[k]
            }*/
    x = 0
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
    s37 = r37 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : 61.6 * 0.01))
    let aa37 = l37 - s37
    ae37 = aa37
  }
  let o37 = (l37 * c23) / 1000
  x = 0
  /* for(let k in TABLE2_9) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;9;FAŁSZ
                if (k == 0) x = TABLE2_9()[k]
            }*/
  x = table2_9_func(zrodlaCieplaO, zrodloCiepla)
  let w37 = (s37 / 1000) * x //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai37 = ae37 != 0 ? o37 - w37 : 0 //JEŻELI($AE36<>0;O36-W36;0)

  let ae38 = 0
  x = 0
  let s38 = 0
  /* for(let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T15;2;FAŁSZ))
                if (k == zrodloCiepla) x = TABLE2()[k]
            }*/
  x = efficiencyBefore({zrodloCiepla, wiekBudynku});
  let l38 =
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) /
    (parameters().I51 * x)
  if (oc(ocieplenie, 2)) {
    /*for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
                if (k == 0) x = TABLE2_3()[k]
            }*/
    x = 0
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)

    let b7local = liczbaKondygnacji
    let f10 = b7local - Math.trunc(b7local)
    let e38 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj //c12
    let j38 = table7_func(wiekBudynku, 3)
    let r38 = e38 * j38 * 0.8 * tempM
    s38 = r38 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))
    let aa38 = l38 - s38
    ae38 = aa38
  }
  let o38 = (l38 * c23) / 1000
  x = 0
  /*  for(let k in TABLE2_9) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;9;FAŁSZ
                if (k == 0) x = TABLE2_9()[k]
            }*/
  x = table2_9_func(zrodlaCieplaO, zrodloCiepla)
  let w38 = (s38 / 1000) * x // WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai38 = ae38 != 0 ? o38 - w38 : 0

  let ae39 = fae39(
    ocieplenie,
    zrodloCiepla,
    powierzchniaPoj,
    miasto,
    instalacjaGrzewcza,
    zrodlaCieplaO,
    wiekBudynku
  )
  let l39 = fl39({ocieplenie, zrodloCiepla, powierzchniaPoj, miasto, wiekBudynku})
  let s39 = fs39(
    ocieplenie,
    zrodloCiepla,
    powierzchniaPoj,
    miasto,
    instalacjaGrzewcza,
    zrodlaCieplaO,
    wiekBudynku
  )
  let o39 = (l39 * c23) / 1000
  x = 0
  //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;9;FAŁSZ
  x = table2_9_func(zrodlaCieplaO, zrodloCiepla)

  let w39 = (s39 / 1000) * x //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;9;FAŁSZ)
  let ai39 = ae39 != 0 ? o39 - w39 : 0

  let sumaAI36AI39 = ai36 + ai37 + ai38 + ai39

  let ai55 =
    sumaAC41AC48 > 0 ? ai40 + sumaAI41AI54 : sumaAI36AI39 + ai40 + sumaAI49AI54 //=JEŻELI(SUMA($AC41:$AC48)>0;AI40+SUMA(AI41:AI54);SUMA(AI36:AI39)+AI40+SUMA(AI49:AI54))
  let ai60 = ai55 - ai53
  let f73 = ai60 + ai53
  let e73 = d73 - f73 //D73(pylBef)-F73
  return e73
}

export function lzoBefore ({
  ocieplenie,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  typOkien,
  stopienOciepleniaDachu,
  zrodloCiepla,
  zrodlaCieplaO,
  wodaUz,
  zrodlaCieplaW,
  wiekBudynku,
  fotowoltanika,
  wymianaOkien,
  instalacjaCieplekWodUz
}) {
  let d61 = fd61({
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    typOkien,
    stopienOciepleniaDachu,
    zrodloCiepla,
    wiekBudynku,
    wymianaOkien
   } )
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let x = 0
  let d65 = fd65({
    wodaUz,
    zrodlaCieplaW,
    zrodloCiepla,
    instalacjaCieplekWodUz,
    powierzchniaPoj,
    liczbaKondygnacji,
    wiekBudynku
  })
  let m61 = 40

  let c6 = b9
  let d67 = fotowoltanika ? m61 * c6 : 0

  x = 0 //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T19;10;FAŁSZ)
  x = TABLE2_10()[zrodloCiepla]

  let y = 0 //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;10;FAŁSZ)
  y = TABLE2_10()[wodaUz]

  let tableS8 = TABLE2_10()[8]

  let d74 = (d61 * x + d65 * y + d67 * tableS8) / Math.pow(10, 3) //=((D61*WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T19;10;FAŁSZ)+D65*WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T19;10;FAŁSZ))+D67*Tabele!S8)/(10^3)

  return d74
}

export function lzoAfter (
  d74, {
  wentylacja,
  fotowoltanika,
  stopienOciepleniaZew,
  wodaUz,
  kolektory,
  instalacjaCieplekWodUz,
  powierzchniaPoj,
  liczbaKondygnacji,
  wiekBudynku,
  zrodlaCieplaO,
  zrodlaCieplaW,
  zrodloCiepla,
  miasto,
  wymianaOkien,
  typOkien,
  stopienOciepleniaDachu,
  instalacjaGrzewcza,
  ocieplenie,
  okna
  }) {
  let m = 0
  m = TABLE3()[miasto]

  let k51 = fk51({
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  })
  let tempM = (m * parameters().C31) / 1000
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let c6 = b9

  let ax13 = 0 //WYSZUKAJ.POZIOMO(AX18;AX18:AX29;AK17+1)*AT11

  ax13 = fax13(
    wiekBudynku,
    stopienOciepleniaZew,
    ocieplenie,
    stopienOciepleniaDachu,
    okna,
    wymianaOkien
  )

  let e41 = Math.ceil(((c6 * ax13) / 1000) * 2) / 2

  let f41 = e41 * parameters().C41
  let ac41 = zrodlaCieplaO == 3 ? f41 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł na biomasę (pelety)";F41;0)

  let ac42 = ac41

  let e43 = e41
  let f43 = e43 * parameters().C43
  let ac43 = zrodlaCieplaO == 1 ? f43 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł gazowy";F43;0)

  let ac44 = ac43

  let e45 = e41
  let f45 = e45 * parameters().C45
  let ac45 = zrodlaCieplaO == 10 ? f45 : 0 //JEŻELI(Przedsiewziecia!C9="Powietrzna pompa ciepła";F45;0)

  let e46 = Math.round(
    ((Math.floor(c6 / 50) + 1) * parameters().Q60 * parameters().S60) /
      parameters().O60
  )
  let f46 = e46 * parameters().C46
  let ac46 = zrodlaCieplaW == 10 ? f46 : 0 //JEŻELI(Przedsiewziecia!C10="Powietrzna pompa ciepła";F46;0)

  let e47 = e41
  let f47 = e47 * parameters().C47
  let ac47 = zrodlaCieplaO == 11 ? f47 : 0 //JEŻELI(Przedsiewziecia!C9="Gruntowa pompa ciepła";F47;0)

  let ac48 = ac47

  let sumaAC41AC48 = ac41 + ac43 + ac45 + ac46 + ac47 //+ ac48;

  let l51 = k51 / parameters().I51
  let k41 =
    (k36(
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    ) +
    k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) +
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) +
    k39(powierzchniaPoj, miasto))/ parameters().I51 + l51 * 0
  let c17 = fc17(zrodloCiepla, wiekBudynku)
  let i41 = c17
  let l41 = k41 / i41
  //let h16 = H15+(c6-I15)*parameters().H14

  m = TABLE3()[miasto]

  //let tempM = m*parameters().C31/1000
  let r41 = fr41(
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    instalacjaGrzewcza,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  )
  let s41 = r41 / TABLE2_3()[3]
  let aa41 = l41 - s41
  let ae41 = zrodlaCieplaO == 3 ? aa41 : 0
  let c24 = TABLE2_10()[zrodloCiepla] //=WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;10;FAŁSZ)
  let p41 = (l41 * c24) / 1000
  let x41 = (s41 / 1000) * TABLE2_10()[3] //=S41/10^3*WYSZUKAJ.PIONOWO(U41;Tabele!$J$5:$T$18;10;FAŁSZ)
  let aj41 = ae41 != 0 ? p41 - x41 : 0 //=JEŻELI($AE41<>0;P41-X41;0)

  let ae42 = 0
  //JEŻELI(Przedsiewziecia!C10="Kocioł na biomasę (pelety)";AA42;0)*AL40
  let l42 = 0
  let s42 = 0
  let l54 = 0
  let k54 = parameters().M60 * b9 * parameters().C32
  if (zrodlaCieplaW == 3) {
    //let i54 = 39*0.01
    //let m60 = 20

    l54 = k54 / parameters().I54
    let k42 = l54
    let x = 0
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }

    let i42 = x
    let l42 = k42 / i42
    let r42 = r41
    let j42 = 0.8
    s42 = r42 / j42
    let aa42 = l42 - s42
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae42 = aa42 * al40
  }
  let c28 = TABLE2_10()[wodaUz] //=WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;10;FAŁSZ)
  let p42 = (l42 * c28) / 1000
  let x42 = (s42 / 1000) * TABLE2_10()[3] //=S42/10^3*WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;10;FAŁSZ)
  let aj42 = ae42 != 0 ? p42 - x42 : 0 //=JEŻELI($AE41<>0;P41-X41;0)

  let ae43 = 0
  let l43 = 0
  let s43 = 0
  if (zrodlaCieplaO == 1) {
    let i43 = fc17(zrodloCiepla, wiekBudynku)
    let k43 = k41
    l43 = k43 / i43
    let r43 = r41
    let j43 = TABLE2_3()[1]
    s43 = r43 / j43
    let aa43 = l43 - s43
    ae43 = aa43
  }
  let p43 = (l43 * c24) / 1000
  let x43 = (s43 / 1000) * TABLE2_10()[1]
  let aj43 = ae43 != 0 ? p43 - x43 : 0 //=JEŻELI($AE41<>0;P41-X41;0)

  let ae44 = 0
  let l44 = 0
  let s44 = 0
  if (zrodlaCieplaW == 5) {
    //kociol wegolwy
    // let i54 = 39*0.01
    // let m60 = 20
    let k44 = l54
    let x = 0
    //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)

    // x = TABLE2()[wodaUz]
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let i44 = x
    l44 = k44 / i44
    let r44 = s54
    let j44 = TABLE2_3()[1]
    s44 = r44 / j44
    let aa44 = l44 - s44
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae44 = aa44 * al40
  }
  let p44 = (l44 * c28) / 1000
  let x44 = (s44 / 1000) * TABLE2_10()[1]
  let aj44 = ae44 != 0 ? p44 - x44 : 0 //=JEŻELI($AE41<>0;P41-X41;0)

  let ae45 = 0
  let l45 = 0
  let s45 = 0
  if (zrodlaCieplaO == 10) {
    let k45 = k41
    let i45 = fc17(zrodloCiepla, wiekBudynku)
    l45 = k45 / i45
    let r45 = r41
    let j45 = TABLE2_3()[10]
    s45 = r45 / j45
    let aa45 = l45 - s45
    ae45 = aa45
  }
  let p45 = (l45 * c24) / 1000
  let x45 = (s45 / 1000) * TABLE2_10()[7]
  let aj45 = ae45 != 0 ? p45 - x45 : 0 //=JEŻELI($AE41<>0;P41-X41;0)

  let ae46 = 0
  //let m60 = 20
  let s46 = 0
  let l46 = 0
  let r54 = parameters().M60 * b9 * parameters().C32
  let s54 = r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
  if (zrodlaCieplaW == 10) {
    let k46 = s54
    let x = 0
    //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)

    //    x = TABLE2()[wodaUz]
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let i46 = x
    l46 = k46 / i46
    let r46 = s54
    let j46 = 3.2
    s46 = r46 / j46
    let aa46 = l46 - s46
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae46 = aa46 * al40
  }
  let p46 = (l46 * c28) / 1000
  let x46 = (s46 / 1000) * TABLE2_10()[7]
  let aj46 = ae46 != 0 ? p46 - x46 : 0

  let ae47 = 0
  let l47 = 0
  let s47 = 0
  if (zrodlaCieplaO == 11) {
    let k47 = k41
    let i47 = fc17(zrodloCiepla, wiekBudynku)
    l47 = k47 / i47
    let r47 = r41
    let j47 = 3.6
    s47 = r47 / j47
    let aa47 = l47 - s47
    ae47 = aa47
  }
  let p47 = (l47 * c24) / 1000
  let x47 = (s47 / 1000) * TABLE2_10()[7]
  let aj47 = ae47 != 0 ? p47 - x47 : 0

  let ae48 = 0
  let s48 = 0
  let l48 = 0
  k54 = parameters().M60 * b9 * parameters().C32
  l54 = k54 / parameters().I54
  if (zrodlaCieplaW == 11) {
    // let i54 = 39*0.01
    // let m60 = 20

    let k48 = l54
    let x = 0
    let i48 = efficiencyBefore({zrodloCiepla: wodaUz, wiekBudynku});
    l48 = k48 / i48
    let r48 = s54
    let j48 = 3.6
    s48 = r48 / j48
    let aa48 = l48 - s48
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae48 = aa48 * al40
  }
  let p48 = (l48 * c28) / 1000
  let x48 = (s48 / 1000) * TABLE2_10()[7]
  let aj48 = ae48 != 0 ? p48 - x48 : 0

  let aj49 = 0
  let aj50 = 0

  let ae51 = 0
  l51 = k51 / parameters().I51
  let r51 = k51
  let s51 = r51 / (instalacjaGrzewcza ? parameters().J51 : parameters().I51)
  let aa51 = l51 - s51
  ae51 = aa51
  let p51 = (l51 * c24) / 1000
  let x51 = (s51 * c24) / 1000
  let aj51 = ae51 !== 0 ? p51 - x51 : 0 

  let ae52 = 0
  let l52 = 0
  let s52 = 0
  if (wentylacja) {
    let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
    let m66 = parameters().C32 * b9
    let m67 = m66 * 3
    let m = 0
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = TABLE3()[miasto]

    let temp = (m * parameters().C31) / 1000
    let k52 = m67 * parameters().M64 * 0.34 * temp
    //let i51 = 61.6 * 0.01
    l52 = (k52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().I52)
    // let c32 = 1
    // let m66 = parameters().C32*b9
    //  let m67 = m66*3
    //let m64 = 0.5
    let r52 = (m67 * parameters().M64 * 0.34 * m * parameters().C31) / 1000
    //let j52 = 0.85
    s52 = (r52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().J52)
    let aa52 = l52 - s52
    ae52 = aa52
  }
  let p52 = (l52 * c24) / 1000
  let x52 = (s52 * c24) / 1000
  let aj52 = ae52 !== 0 ? p52 - x52 : 0

  let b7local = liczbaKondygnacji
  let f10 = b7local - Math.trunc(b7local)
  let c12 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj;
  let e53 = Math.ceil(
    (c12 * parameters().F12 * liczbaKondygnacji) / 10000
  )
  let m61 = 40
  let d67 = fotowoltanika ? m61 * c6 : 0
  let k53 = d67
  let r53 = k53
  let l53 = k53
  let p53 = (l53 / 1000) * TABLE2_10()[7] //WYSZUKAJ.PIONOWO($U$53;Tabele!$J$5:$T$18;10;FAŁSZ)
  let s53 = fotowoltanika ? r53 - e53 * (1000 - 70) : 0
  let x53 = (s53 / Math.pow(10, 3)) * TABLE2_10()[7]
  let aj53 = fotowoltanika ? p53 - x53 : 0 //=JEŻELI(Przedsiewziecia!C15="Tak";P53-X53;0)

  let c19 = 0
  // c19 = TABLE2()[wodaUz]
  if (wodaUz != 5) c19 = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        c19 = 0.6

        break
      case 2:
        c19 = 0.6

        break
      case 3:
        c19 = 0.6

        break
      case 4:
        c19 = 0.6

        break
      case 5:
        c19 = 0.65

        break
      case 6:
        c19 = 0.65

        break
      case 7:
        c19 = 0.82

        break
      case 8:
        c19 = 0.82

        break
      case 9:
        c19 = 0.82

        break
      case 10:
        c19 = 0.82

        break
      case 11:
        c19 = 0.82

        break
      default:
        c19 = 0
    }
  }
  let p54 = (l54 * c28) / c19 / 1000
  let x54 =
    (s54 / (1000 * table2_3_func(zrodlaCieplaW, wodaUz))) *
    c28 //=S54/(10^3*WYSZUKAJ.PIONOWO($E$17;Tabele!$J$5:$T$18;3;FAŁSZ))*WYSZUKAJ.PIONOWO($U$54;Tabele!$J$5:$T$18;10;FAŁSZ)
  let u54 = zrodlaCieplaW
  let aa54 = l54 - s54
  let ae54 = instalacjaCieplekWodUz ? /*l54 - s54*/ aa54 : 0

  let aj54 = ae54 != 0 ? p54 - x54 : 0

  let sumaAJ41AJ54 =
    aj41 +
    aj42 +
    aj43 +
    aj44 +
    aj45 +
    aj46 +
    aj47 +
    aj48 +
    aj51 + 
    aj52 + 
    aj53 +
    aj54

  let ae40 = 0
  let l40 = 0
  let s40 = 0
  //let j54 = 0.56
  if (kolektory) {
    let r54 = parameters().M60 * b9 * parameters().C32
    let s54 =
      r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
    let k40 = s54
    let i40 = TABLE2()[zrodloCiepla]
    let j40 = TABLE2()[12] // kolektor słoneczny
    l40 = zrodlaCieplaW === 12 ? (k40 * (1- j40)) / i40 : k40 / i40
    let r40 = s54
    s40 = r40 * (1 - j40) / i40
    let aa40 = l40 - s40
    ae40 = aa40
  }
  let p40 = (l40 * c28) / 1000
  let x40 = (s40 * c28) / 1000
  let aj40 = ae40 != 0 ? p40 - x40 : 0 //=JEŻELI($AE40<>0;P40-X40;0)

  let ae36 = 0
  let x = 0
  x = table2_9_func(zrodlaCieplaO, zrodloCiepla)
  //////////////console.log('----------------------2938-----------------------------')
  let l36 = fl36(
    zrodloCiepla,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  )
  let s36 = 0
  if (oc(ocieplenie, 1)) {
    //let i51 = 61.6 * 0.01
    let x = 0
    //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
    if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }

    let c9 = Math.sqrt(powierzchniaPoj)
    let c8 = c9
    let b7 = liczbaKondygnacji
    let c11 = (2 * c9 + 2 * c8) * 3 * b7
    let e8 = parameters().E7 + (c6 - parameters().F7) * parameters().E6
    let b14 = Math.round(c6 * e8 * 10) / 10
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
    let c13 = b14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let e36 = c11 - b14 - c14
    let j36 = table6_func(wiekBudynku, 3)
    let r36 = e36 * j36 * 1 * tempM
    x = 0
    //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)

    s36 = r36 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))

    let aa36 = l36 - s36
    ae36 = aa36
  }
  let p36 = (l36 * c24) / 1000
  let x36 = (s36 / 1000) * table2_10_func(zrodlaCieplaO, zrodloCiepla) //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;10;FAŁSZ)
  let aj36 = ae36 != 0 ? p36 - x36 : 0 //=JEŻELI($AE36<>0;P36-X36;0)

  let ae37 = 0
  let l37 = 0
  let s37 = 0
  let c9 = Math.sqrt(powierzchniaPoj)
  let c8 = c9
  let b7 = liczbaKondygnacji
  let c11 = (2 * c9 + 2 * c8) * 3 * b7
  let e8 = parameters().E7 + (c6 - parameters().F7) * parameters().E6
  let b14 = Math.round(c6 * e8 * 10) / 10
  if (wymianaOkien) {
    let x = 0
    /* for(let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
                if (k == zrodloCiepla) x = TABLE2()[k]
            }*/
    if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    l37 =
      k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) /
      (parameters().I51 * x)
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
    let c13 = b14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let e37 = c13 + c14
    let j37 = table8_func(wiekBudynku, 2)
    let r37 = e37 * j37 * 1 * tempM
    /* for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
                if (k == 0) x = TABLE2_3()[k]
            }*/
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
    s37 = r37 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : 61.6 * 0.01))
    let aa37 = l37 - s37
    ae37 = aa37
  }
  let p37 = (l37 * c24) / 1000
  let x37 = (s37 / 1000) * table2_10_func(zrodlaCieplaO, zrodloCiepla)
  let aj37 = ae37 != 0 ? p37 - x37 : 0 //=JEŻELI($AE36<>0;P36-X36;0)

  let ae38 = 0
  x = 0
  let s38 = 0
  x =efficiencyBefore({zrodloCiepla, wiekBudynku});
  let l38 =
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) /
    (parameters().I51 * x)
  if (oc(ocieplenie, 2)) {
    x = 0
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)

    let b7local = liczbaKondygnacji
    let f10 = b7local - Math.trunc(b7local)
    let e38 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj //c12
    let j38 = table7_func(wiekBudynku, 3)
    let r38 = e38 * j38 * 0.8 * tempM
    s38 = r38 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))
    let aa38 = l38 - s38
    ae38 = aa38
  }
  let p38 = (l38 * c24) / 1000
  let x38 = (s38 / 1000) * table2_10_func(zrodlaCieplaO, zrodloCiepla)
  let aj38 = ae38 != 0 ? p38 - x38 : 0 //=JEŻELI($AE36<>0;P36-X36;0)

  let ae39 = fae39(
    ocieplenie,
    zrodloCiepla,
    powierzchniaPoj,
    miasto,
    instalacjaGrzewcza,
    zrodlaCieplaO,
    wiekBudynku
  )
  let l39 = fl39({ocieplenie, zrodloCiepla, powierzchniaPoj, miasto, wiekBudynku})
  let s39 = fs39(
    ocieplenie,
    zrodloCiepla,
    powierzchniaPoj,
    miasto,
    instalacjaGrzewcza,
    zrodlaCieplaO,
    wiekBudynku
  )
  let p39 = (l39 * c24) / 1000
  let x39 = (s39 / 1000) * table2_10_func(zrodlaCieplaO, zrodloCiepla)
  let aj39 = ae39 != 0 ? p39 - x39 : 0 //=JEŻELI($AE36<>0;P36-X36;0)

  let sumaAJ36AJ39 = aj36 + aj37 + aj38 + aj39
  let aj55 =
    sumaAC41AC48 > 0
      ? aj40 + sumaAJ41AJ54
      : sumaAJ36AJ39 + aj40 + aj49 + aj50 + aj51 + aj52 + aj53 + aj54 //=JEŻELI(SUMA($AC41:$AC48)>0;AJ40+SUMA(AJ41:AJ54);SUMA(AJ36:AJ39)+AJ40+SUMA(AJ49:AJ54))
  
  let aj60 = aj55 - aj53
  let f74 = aj60 + aj53
  //d74 - lzobef
  let e74 = d74 - f74
  return e74
}

export function noxDiffer ({
  miasto,
  kolektory,
  typOkien,
  powierzchniaPoj,
  liczbaKondygnacji,
  wiekBudynku,
  stopienOciepleniaZew,
  ocieplenie,
  stopienOciepleniaDachu,
  okna,
  wymianaOkien,
  zrodlaCieplaO,
  zrodlaCieplaW,
  zrodloCiepla,
  wodaUz,
  instalacjaCieplekWodUz,
  fotowoltanika,
  wentylacja,
  instalacjaGrzewcza
}) {
  let m = 0
  m = TABLE3()[miasto]

  let tempM = (m * parameters().C31) / 1000
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
  let c6 = b9

  let ax13 = 0 //WYSZUKAJ.POZIOMO(AX18;AX18:AX29;AK17+1)*AT11
  ax13 = fax13(
    wiekBudynku,
    stopienOciepleniaZew,
    ocieplenie,
    stopienOciepleniaDachu,
    okna,
    wymianaOkien
  )

  let e41 = Math.ceil(((c6 * ax13) / 1000) * 2) / 2
  //////console.log('e41',e41, 'c6',c6,'ax13',ax13)
  let f41 = e41 * parameters().C41
  //////console.log('f41',f41)
  //////console.log('C41',C41)
  let ac41 = zrodlaCieplaO == 3 ? f41 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł na biomasę (pelety)";F41;0)
  ////////////////////console.log('ac41',ac41)
  let ac42 = ac41

  let e43 = e41
  let f43 = e43 * parameters().C43
  let ac43 = zrodlaCieplaO == 1 ? f43 : 0 //JEŻELI(Przedsiewziecia!C9="Kocioł gazowy";F43;0)

  let ac44 = ac43

  let e45 = e41
  let f45 = e45 * parameters().C45
  let ac45 = zrodlaCieplaO == 10 ? f45 : 0 //JEŻELI(Przedsiewziecia!C9="Powietrzna pompa ciepła";F45;0)

  let e46 =
    ((Math.floor(c6 / 50) + 1) * parameters().Q60 * parameters().S60) /
    parameters().O60
  let f46 = e46 * parameters().C46
  //////console.log('f46',f46,'e46',e46,'C46',parameters().C46)
  let ac46 = zrodlaCieplaW == 10 ? f46 : 0 //JEŻELI(Przedsiewziecia!C10="Powietrzna pompa ciepła";F46;0)

  let e47 = e41
  let f47 = e47 * parameters().C47
  let ac47 = zrodlaCieplaO == 11 ? f47 : 0 //JEŻELI(Przedsiewziecia!C9="Gruntowa pompa ciepła";F47;0)

  let ac48 = ac47

  let sumaAC41AC48 = ac41 + ac43 + ac45 + ac46 + ac47

  //////////////////////////////////////////////////////////////////////////////////////

  let ae36 = 0
  let s36 = 0
  //////////console.log('lolo',oc(ocieplenie,1))
  if (oc(ocieplenie, 1)) {
    //////////console.log('shallow')
    //let i51 = 61.6 * 0.01
    let x = 0
    //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
    if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }

    let l36 = fl36(
      zrodloCiepla,
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    )
    let c9 = Math.sqrt(powierzchniaPoj)
    let c8 = c9
    let b7 = liczbaKondygnacji
    let c11 = (2 * c9 + 2 * c8) * 3 * b7
    let e8 = parameters().E7 + (c6 - parameters().F7) * parameters().E6
    let b14 = Math.round(c6 * e8 * 10) / 10
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
    let c13 = b14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let e36 = c11 - b14 - c14
    let j36 = table6_func(wiekBudynku, 3)

    let r36 = e36 * j36 * 1 * tempM
    // for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
    //    if (k == zrodlaCieplaO)
    x = 0
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
    //}
    s36 = r36 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))
    let aa36 = l36 - s36
    ae36 = aa36
  }
  let c29 = TABLE2_11()[wodaUz]
  let c25 = TABLE2_11()[zrodloCiepla]
  let l36 = fl36(
    zrodloCiepla,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  )
  let q36 = (l36 * c25) / 1000
  let y36 = (s36 / 1000) * table2_11_func(zrodlaCieplaO, zrodloCiepla) //=S36/10^3*WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak36 = ae36 != 0 ? q36 - y36 : 0 //=JEŻELI($AE40<>0;Q40-Y40;0)

  let ae37 = 0
  let s37 = 0
  if (wymianaOkien) {
    let x = 0
    //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
    if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let b14 =
      Math.round(
        b9 * (parameters().E7 + (b9 - parameters().F7) * parameters().E6) * 10
      ) / 10
    let c13 = b14
    let h16 = parameters().H15 + (c6 - parameters().I15) * parameters().H14
    let c14 = Math.round(c13 * h16 * 10) / 10
    let l37 =
      k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) /
      (parameters().I51 * x)
    let e37 = c13 + c14
    let j37 = table8_func(wiekBudynku, 2)
    let r37 = e37 * j37 * 1 * tempM
    x = 0
    //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)

    s37 = r37 / (x * (instalacjaGrzewcza ? parameters().J51 : parameters().I51))
    let aa37 = l37 - s37
    //////////////console.log('l37',l37,'s37',s37,'e37',e37,'j37',j37,'r37',r37,'x',x, 'j51',J51,'I51',parameters().I51)
    ae37 = aa37
  }
  let x = 0
  //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T18;2;FAŁSZ)
  if (zrodloCiepla != 5) x = TABLE2()[zrodloCiepla]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        x = 0.6

        break
      case 2:
        x = 0.6

        break
      case 3:
        x = 0.6

        break
      case 4:
        x = 0.6

        break
      case 5:
        x = 0.65

        break
      case 6:
        x = 0.65

        break
      case 7:
        x = 0.82

        break
      case 8:
        x = 0.82

        break
      case 9:
        x = 0.82

        break
      case 10:
        x = 0.82

        break
      case 11:
        x = 0.82

        break
      default:
        x = 0
    }
  }
  let l37 =
    k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) /
    (parameters().I51 * x)
  let q37 = (l37 * c25) / 1000
  let y37 = (s37 / 1000) * table2_11_func(zrodlaCieplaO, zrodloCiepla) //=S36/10^3*WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak37 = ae37 != 0 ? q37 - y37 : 0 //=JEŻELI($AE40<>0;Q40-Y40;0)

  let ae38 = 0
  let s38 = 0
  if (oc(ocieplenie, 2)) {
    let x = 0
    x= efficiencyBefore({zrodloCiepla, wiekBudynku});
    let l38 =
      k38(
        liczbaKondygnacji,
        powierzchniaPoj,
        stopienOciepleniaDachu,
        miasto,
        wiekBudynku
      ) /
      (parameters().I51 * x)
    // for(let k in TABLE2_3) { //WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;3;FAŁSZ
    //   if (k == 0)
    x = 0
    x = table2_3_func(zrodlaCieplaO, zrodloCiepla)
    //}
    //////console.log('ae38',ae38,'l38',l38,'x',x)
    let b7 = liczbaKondygnacji
    let f10 = b7 - Math.trunc(b7)
    let e38 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj //c12
    let j38 = table7_func(wiekBudynku, 3)
    let r38 = e38 * j38 * 0.8 * tempM
    s38 = r38 / (x * (instalacjaGrzewcza ? 87.4 * 0.01 : parameters().I51))
    let aa38 = l38 - s38
    ae38 = aa38
  }
  x = efficiencyBefore({zrodloCiepla, wiekBudynku});
  let l38 =
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) /
    (parameters().I51 * x)
  let q38 = (l38 * c25) / 1000
  let y38 = (s38 / 1000) * table2_11_func(zrodlaCieplaO, zrodloCiepla) //=S36/10^3*WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak38 = ae38 != 0 ? q38 - y38 : 0 //=JEŻELI($AE40<>0;Q40-Y40;0)

  let ae39 = fae39(
    ocieplenie,
    zrodloCiepla,
    powierzchniaPoj,
    miasto,
    instalacjaGrzewcza,
    zrodlaCieplaO,
    wiekBudynku
  )
  let l39 = fl39({ocieplenie, zrodloCiepla, powierzchniaPoj, miasto, wiekBudynku})
  let s39 = fs39(
    ocieplenie,
    zrodloCiepla,
    powierzchniaPoj,
    miasto,
    instalacjaGrzewcza,
    zrodlaCieplaO,
    wiekBudynku
  )
  let q39 = (l39 * c25) / 1000
  let y39 = (s39 / 1000) * table2_11_func(zrodlaCieplaO, zrodloCiepla) //=S36/10^3*WYSZUKAJ.PIONOWO($E$15;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak39 = ae39 != 0 ? q39 - y39 : 0 //=JEŻELI($AE40<>0;Q40-Y40;0)
  /////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////
  let ae40 = 0

  let r54 = parameters().M60 * b9 * parameters().C32
  let s54 = r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
  let k40 = s54    
  let i40 = TABLE2()[zrodloCiepla]


  let r40 = s54
  let j40 = TABLE2()[12] // kolektor słoneczny
  let l40 = zrodlaCieplaW === 12 ? (k40 * (1- j40)) / i40 : k40 / i40
  let s40 = r40 * (1 - j40) / i40
  if (kolektory) {
    let aa40 = l40 - s40
    ae40 = aa40
  }
  //let c29 = table2_11_func(zrodlaCieplaW,wodaUz)
  let q40 = (l40 * c29) / 1000
  let y40 = (s40 * c29) / 1000
  let ak40 = ae40 != 0 ? q40 - y40 : 0 //=JEŻELI($AE40<>0;Q40-Y40;0)

  let c17 = fc17(zrodloCiepla, wiekBudynku)

  let i41 = c17
  let k51 = fk51({
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  })

  let l51 = k51 / parameters().I51
  let k41 =
    (k36(
      powierzchniaPoj,
      liczbaKondygnacji,
      stopienOciepleniaZew,
      miasto,
      wiekBudynku
    ) +
    k37(typOkien, powierzchniaPoj, liczbaKondygnacji, miasto, wiekBudynku) +
    k38(
      liczbaKondygnacji,
      powierzchniaPoj,
      stopienOciepleniaDachu,
      miasto,
      wiekBudynku
    ) +
    k39(powierzchniaPoj, miasto))/ parameters().I51 + l51 * 0
  let l41 = k41 / i41
  //let h16 = H15+(c6-I15)*parameters().H14
  //let m= 0
  // for(let k in TABLE3) { //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
  //  if (k == miasto)
  m = TABLE3()[miasto]
  //  }

  //let tempM = m*parameters().C31/1000
  let r41 = fr41(
    wymianaOkien,
    typOkien,
    stopienOciepleniaDachu,
    instalacjaGrzewcza,
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    wiekBudynku
  )
  let s41 = r41 / TABLE2_3()[3]
  let aa41 = l41 - s41
  let ae41 = zrodlaCieplaO == 3 ? aa41 : 0

  //i41 = c17
  // l41= k41/i41
  // r41 = fr41(wymianaOkien, typOkien, stopienOciepleniaDachu,instalacjaGrzewcza, ocieplenie, powierzchniaPoj, liczbaKondygnacji, stopienOciepleniaZew, miasto,wiekBudynku)
  //let s41 = r41/TABLE2_3()[3]
  let q41 = (l41 * c25) / 1000
  let y41 = (s41 / 1000) * TABLE2_11()[3] //S41/10^3*WYSZUKAJ.PIONOWO(U41;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak41 = ae41 != 0 ? q41 - y41 : 0 //=JEŻELI($AE40<>0;Q40-Y40;0)

  let k54 = parameters().M60 * b9 * parameters().C32
  let l54 = k54 / parameters().I54
  let k42 = l54
  x = 0
  //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)

  if (wodaUz != 5) x = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        x = 0.6

        break
      case 2:
        x = 0.6

        break
      case 3:
        x = 0.6

        break
      case 4:
        x = 0.6

        break
      case 5:
        x = 0.65

        break
      case 6:
        x = 0.65

        break
      case 7:
        x = 0.82

        break
      case 8:
        x = 0.82

        break
      case 9:
        x = 0.82

        break
      case 10:
        x = 0.82

        break
      case 11:
        x = 0.82

        break
      default:
        x = 0
    }
  }
  let i42 = x
  let l42 = k42 / i42
  let r42 = s54
  let j42 = 0.8
  let s42 = r42 / j42
  let aa42 = l42 - s42
  let e40 = 1
  let c40 = 10000
  let f40 = e40 * c40
  let ac40 = kolektory ? f40 : 0
  let al40 = ac40 > 0 ? 0 : 1
  //////////////////console.log('ac40',ac40,'al40',al40)
  let ae42 = (zrodlaCieplaW == 3 ? aa42 : 0) * al40
  let q42 = (l42 * c29) / 1000
  let y42 = (s42 / 1000) * TABLE2_11()[3] //S42/10^3*WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak42 = ae42 != 0 ? q42 - y42 : 0 //=JEŻELI($AE42<>0;Q42-Y42;0)

  let ae43 = 0
  let l43 = 0
  let s43 = 0
  if (zrodlaCieplaO == 1) {
    let i43 = fc17(zrodloCiepla, wiekBudynku)
    let k43 = k41
    l43 = k43 / i43
    let r43 = r41
    let j43 = TABLE2_3()[1]
    s43 = r43 / j43
    let aa43 = l43 - s43
    ae43 = aa43
  }
  let q43 = (l43 * c25) / 1000
  let y43 = (s43 / 1000) * TABLE2_11()[1] //=S43/10^3*WYSZUKAJ.PIONOWO(U43;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak43 = ae43 != 0 ? q43 - y43 : 0

  let ae44 = 0
  let l44 = 0
  let s44 = 0
  if (zrodlaCieplaW == 1) {
    // let i54 = 39*0.01
    // let m60 = 20
    let k44 = l54
    let x = 0
    //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)

    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }

    let i44 = x
    l44 = k44 / i44
    let r44 = s54
    let j44 = TABLE2_3()[1]
    s44 = r44 / j44
    let aa44 = l44 - s44
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae44 = aa44 * al40
  }
  let q44 = (l44 * c29) / 1000
  let y44 = (s44 / 1000) * TABLE2_11()[3] //S42/10^3*WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak44 = ae44 != 0 ? q44 - y44 : 0 //=JEŻELI($AE42<>0;Q42-Y42;0)

  let ae45 = 0
  let l45 = 0
  let s45 = 0
  if (zrodlaCieplaO == 10) {
    let k45 = k41
    let i45 = fc17(zrodloCiepla, wiekBudynku)
    l45 = k45 / i45
    let r45 = r41
    let j45 = TABLE2_3()[10]
    s45 = r45 / j45
    let aa45 = l45 - s45
    ae45 = aa45
  }
  let q45 = (l45 * c25) / 1000
  let y45 = (s45 / 1000) * TABLE2_11()[7] //S42/10^3*WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak45 = ae45 != 0 ? q45 - y45 : 0 //=JEŻELI($AE42<>0;Q42-Y42;0)

  let ae46 = 0
  let l46 = 0
  //let m60 = 20
  //let r54 = parameters().M60 * b9 * parameters().C32
  //let s54 = r54 / (instalacjaCieplekWodUz == 1 ? parameters().J54 : parameters().I54)
  let s46 = 0
  if (zrodlaCieplaW == 10) {
    let k46 = s54
    let x = 0
    /*for (let k in TABLE2) { //WYSZUKAJ.PIONOWO(Dane!B16;Tabele!J5:T18;2;FAŁSZ)
        if (k == wodaUz)
            x = TABLE2()[k]
    }*/
    if (wodaUz != 5) x = TABLE2()[wodaUz]
    else {
      switch (Number(wiekBudynku)) {
        case 1:
          x = 0.6

          break
        case 2:
          x = 0.6

          break
        case 3:
          x = 0.6

          break
        case 4:
          x = 0.6

          break
        case 5:
          x = 0.65

          break
        case 6:
          x = 0.65

          break
        case 7:
          x = 0.82

          break
        case 8:
          x = 0.82

          break
        case 9:
          x = 0.82

          break
        case 10:
          x = 0.82

          break
        case 11:
          x = 0.82

          break
        default:
          x = 0
      }
    }
    let i46 = x
    l46 = k46 / i46
    let r46 = s54
    let j46 = 3.2
    s46 = r46 / j46
    let aa46 = l46 - s46
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae46 = aa46 * al40
  }
  let q46 = (l46 * c29) / 1000
  let y46 = (s46 / 1000) * TABLE2_11()[7] //S42/10^3*WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak46 = ae46 != 0 ? q46 - y46 : 0 //=JEŻELI($AE42<>0;Q42-Y42;0)

  let ae47 = 0
  let l47 = 0
  let s47 = 0
  if (zrodlaCieplaO == 11) {
    let k47 = k41
    let i47 = fc17(zrodloCiepla, wiekBudynku)
    l47 = k47 / i47
    let r47 = r41
    let j47 = 3.6
    s47 = r47 / j47
    let aa47 = l47 - s47
    ae47 = aa47
  }
  let q47 = (l47 * c25) / 1000
  let y47 = (s47 / 1000) * TABLE2_11()[7] //S42/10^3*WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak47 = ae47 != 0 ? q47 - y47 : 0 //=JEŻELI($AE42<>0;Q42-Y42;0)

  let ae48 = 0
  let l48 = 0
  let s48 = 0
  //let l54 = k54 / parameters().I54
  if (zrodlaCieplaW == 11) {
    // let i54 = 39*0.01
    // let m60 = 20
    let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
    let k54 = parameters().M60 * b9 * parameters().C32

    let m66 = parameters().C32 * b9
    let m67 = m66 * 3
    let m = 0
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = TABLE3()[miasto]

    let temp = (m * parameters().C31) / 1000
    let k52 = m67 * parameters().M64 * 0.34 * temp
    let k48 = l54
    let x = 0
    let i48 = efficiencyBefore({zrodloCiepla: wodaUz, wiekBudynku});
    l48 = k48 / i48
    let r48 = s54
    let j48 = 3.6
    s48 = r48 / j48
    let aa48 = l48 - s48
    let e40 = 1
    let c40 = 10000
    let f40 = e40 * c40
    let ac40 = kolektory ? f40 : 0
    let al40 = ac40 > 0 ? 0 : 1
    ae48 = aa48 * al40
  }
  let q48 = (l48 * c29) / 1000
  let y48 = (s48 / 1000) * TABLE2_11()[7] //S42/10^3*WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak48 = ae48 != 0 ? q48 - y48 : 0 //=JEŻELI($AE42<>0;Q42-Y42;0)

  let ak49 = 0
  let ak50 = 0

  let ae51 = 0
  //let l51 = 0
  let s51 = 0
  if (instalacjaGrzewcza) {
    //let i51 = 61.6 * 0.01
    l51 = k51 / parameters().I51
    let r51 = k51
    s51 = r51 / (instalacjaGrzewcza ? parameters().J51 : parameters().I51)
    let aa51 = l51 - s51
    ae51 = aa51
    ////////////////console.log('aaee51',ae51)
  }
  let q51 = (l51 * c25) / 1000

  let y51 = (s51 * c25) / 1000 //S42/10^3*WYSZUKAJ.PIONOWO($U$51;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak51 = ae51 != 0 ? q51 - y51 : 0 //=JEŻELI($AE42<>0;Q42-Y42;0)

  let ae52 = 0
  let l52 = 0
  let s52 = 0
  if (wentylacja) {
    //let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)
    //   let m66 = parameters().C32*b9
    //   let m67 = m66*3
    let m = 0
    //WYSZUKAJ.PIONOWO(Dane!B6;Tabele!V5:X64;2;FAŁSZ)
    m = TABLE3()[miasto]

    let temp = (m * parameters().C31) / 1000
    let m66 = parameters().C32 * b9
    let m67 = m66 * 3
    let k52 = m67 * parameters().M64 * 0.34 * temp
    let i51 = 61.6 * 0.01
    l52 = (k52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().I52)
    // let c32 = 1

    //let m64 = 0.5
    let r52 = (m67 * parameters().M64 * 0.34 * m * parameters().C31) / 1000
    //let j52 = 0.85
    s52 = (r52 / fc17(zrodloCiepla, wiekBudynku)) * (1 - parameters().J52)
    let aa52 = l52 - s52
    ae52 = aa52
  }
  let q52 = (l52 * c25) / 1000
  let y52 = (s52 * c25) / 1000 //S42/10^3*WYSZUKAJ.PIONOWO(U42;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak52 = ae52 != 0 ? q52 - y52 : 0 //=JEŻELI($AE42<>0;Q42-Y42;0)
  //////////////////////////////////////////////////////////////////////////////////

  let b7 = liczbaKondygnacji
  let f10 = b7 - Math.trunc(b7)
  let c12 = f10 > 0 ? powierzchniaPoj * Math.sqrt(2) : powierzchniaPoj;
  let e53 = Math.ceil(
    (c12 * parameters().F12 * liczbaKondygnacji) / 10000
  )
  let m61 = 40
  let d67 = fotowoltanika ? m61 * c6 : 0
  let k53 = d67
  let r53 = k53
  let l53 = k53
  let q53 = (l53 / 1000) * TABLE2_11()[7] //=L53/10^3*WYSZUKAJ.PIONOWO($U$53;Tabele!$J$5:$T$18;11;FAŁSZ)
  let s53 = fotowoltanika ? r53 - e53 * (1000 - 70) : 0
  let y53 = (s53 / Math.pow(10, 3)) * TABLE2_11()[7]
  let ak53 = fotowoltanika ? q53 - y53 : 0 //=JEŻELI(Przedsiewziecia!C15="Tak";Q53-Y53;0)

  let u54 = zrodlaCieplaW
  // let k54 = parameters().M60 * b9 * parameters().C32
  // let l54 = k54 / parameters().I54
  // let r54 = parameters().M60 * b9 * parameters().C32
  // let s54 = r54 / (instalacjaCieplekWodUz ? parameters().J54 : parameters().I54)
  let aa54 = l54 - s54
  let ae54 = instalacjaCieplekWodUz ? aa54 : 0
  let c19 = 0
  // c19 = TABLE2()[wodaUz]
  if (wodaUz != 5) c19 = TABLE2()[wodaUz]
  else {
    switch (Number(wiekBudynku)) {
      case 1:
        c19 = 0.6

        break
      case 2:
        c19 = 0.6

        break
      case 3:
        c19 = 0.6

        break
      case 4:
        c19 = 0.6

        break
      case 5:
        c19 = 0.65

        break
      case 6:
        c19 = 0.65

        break
      case 7:
        c19 = 0.82

        break
      case 8:
        c19 = 0.82

        break
      case 9:
        c19 = 0.82

        break
      case 10:
        c19 = 0.82

        break
      case 11:
        c19 = 0.82

        break
      default:
        c19 = 0
    }
  }
  let q54 = (l54 * c29) / c19 / 1000
  let y54 =
    (s54 / (1000 * table2_3_func(zrodlaCieplaW, wodaUz))) *
    c29 //S54/(10^3*WYSZUKAJ.PIONOWO($E$17;Tabele!$J$5:$T$18;3;FAŁSZ))*WYSZUKAJ.PIONOWO($U$54;Tabele!$J$5:$T$18;11;FAŁSZ)
  let ak54 = ae54 != 0 ? q54 - y54 : 0 //JEŻELI($AE54<>0;Q54-Y54;0)

  let sumaAK4154 =
    ak41 +
    ak42 +
    ak43 +
    ak44 +
    ak45 +
    ak46 +
    ak47 +
    ak48 +
    ak51 +
    ak52 +
    ak53 +
    ak54
  
  let sumaAK49AK54 = ak49 + ak50 + ak51 + ak52 + ak53 + ak54
  let sumaak3639 = ak36 + ak37 + ak38 + ak39
  let ak55 =
    sumaAC41AC48 > 0 ? ak40 + sumaAK4154 : sumaak3639 + ak40 + sumaAK49AK54 // JEŻELI(SUMA($AC41:$AC48)>0;AK40+SUMA(AK41:AK54);SUMA(AK36:AK39)+AK40+SUMA(AK49:AK54))
 
  let ak60 = ak55 - ak53
  let f75 = ak60 + ak53
  return f75 //noxdiff
}
export function balony (lzodiff) {
  let be3 = 22.4
  let h83 = 5
  let bd11 = (12 * 12 + 10 * 1) / be3
  let be11 = 0.05

  let bd12 = (13 * 12 + 10 * 1) / be3
  let be12 = 0.05

  let bd13 = (14 * 12 + 10 * 1) / be3
  let be13 = 0.05

  let bd14 = (16 * 12 + 10 * 1) / be3
  let be14 = 0.05

  let bd15 = (18 * 12 + 12 * 1) / be3
  let be15 = 0.05

  let bd16 = (20 * 12 + 12 * 1) / be3

  let bd17 = (22 * 12 + 12 * 1) / be3
  let be17 = 0.05

  let bd18 = (22 * 12 + 14 * 1) / be3
  let be18 = 0.05

  let bd19 = (4 * 12 + 4 * 1 + 16) / be3
  let be19 = 0.05

  let be16 = 1 - (be11 + be12 + be13 + be14 + be15 + be17 + be19 + be18)

  let tabeleBD10 =
    Math.round(
      (be11 * bd11 +
        be12 * bd12 +
        be13 * bd13 +
        be14 * bd14 +
        be15 * bd15 +
        be16 * bd16 +
        be17 * bd17 +
        be18 * bd18 +
        be19 * bd19) *
        100
    ) / 100
  let f83 = Math.ceil((lzodiff * 1000) / (h83 * tabeleBD10))
  return f83
}

export function noxBefore ({
  wodaUz,
  ocieplenie,
  powierzchniaPoj,
  liczbaKondygnacji,
  stopienOciepleniaZew,
  miasto,
  typOkien,
  stopienOciepleniaDachu,
  zrodloCiepla,
  wiekBudynku,
  wymianaOkien,
  fotowoltanika,
  instalacjaCieplekWodUz,
  zrodlaCieplaW
}) {
  let d61 = fd61({
    ocieplenie,
    powierzchniaPoj,
    liczbaKondygnacji,
    stopienOciepleniaZew,
    miasto,
    typOkien,
    stopienOciepleniaDachu,
    zrodloCiepla,
    wiekBudynku,
    wymianaOkien,
})
  let b9 = lacznaPowOgrzewana(powierzchniaPoj, liczbaKondygnacji)

  let d65 = fd65({
    wodaUz,
    zrodlaCieplaW,
    zrodloCiepla,
    instalacjaCieplekWodUz,
    powierzchniaPoj,
    liczbaKondygnacji,
    wiekBudynku
  })
  let z = 0 //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T19;11;FAŁSZ)
  z = TABLE2_11()[zrodloCiepla]
  let w = 0 //WYSZUKAJ.PIONOWO(Dane!B15;Tabele!J5:T19;11;FAŁSZ)
  w = TABLE2_11()[wodaUz]

  let c6 = b9
  let m61 = 40
  let d67 = fotowoltanika ? m61 * c6 : 0
  let tabeleT8 = TABLE2_11()[9]

  let d75 = (d61 * z + d65 * w + d67 * tabeleT8) / 1000

  return d75
}

export function noxAfter (d75, f75) {
  let e75 = d75 - f75 //d77=noxbef
  return e75
}

export function wiaderka (pylDiff) {
  let f73 = pylDiff

  let f78 = Math.ceil((f73 * 1000) / (parameters().J78 * parameters().H78)) //ZAOKR.W.GÓRĘ.MATEMATYCZNE(F73*1000/($J$78*$H$78);1)
  return f78
}

export function oddechy (noxDiff) {
  let be3 = 22.4
  let f75 = noxDiff
  let bd6 = (14 + 16) / be3
  let be6 = 0.5
  let bd7 = (14 + 2 * 16) / be3
  let be7 = 0.25
  let bd8 = (2 * 14 + 16) / be3
  let be8 = 1 - (be6 + be7)
  let tabeleBD9 = Math.round((bd6 * be6 + bd7 * be7 + bd8 * be8) * 100) / 100 //=ZAOKR(SUMA(BD6*BE6;BD7*BE7;BD8*BE8);2)
  let f85 = Math.ceil((f75 * 1000) / (parameters().H85 * tabeleBD9)) //=ZAOKR.W.GÓRĘ.MATEMATYCZNE(F75*1000/($H$85*Tabele!BD9);1)
  return f85
}

export function valid (x) {
  // if (this.state.stopienOciepleniaZew == "")
  //  this.state.stopienOciepleniaZewValid = false;
  alert(x, 'nie')
}
