import React from 'react';
import './FormSelect.css';
import Tooltip from 'react-tooltip-lite';
import tp from './images/tooltip.png'

const FormSelect = props => {
    let vC = "col-2"
    let i=0
    if (props.valid) vC = "col-2"; else vC = "col-2 error"
return ( 
        <div className = "form-select">
            <div className="row">
                <div className="col-1">
                    <label htmlFor={props.id}>{props.label}
                        
                        <Tooltip content={props.tooltip} direction="left" className="tooltipIco" > 
                              <img src={tp} alt="tooltip"/>
                        </Tooltip>
                    </label>
                </div>
                <div className={vC}>
                    <select 
                        id={props.id}
                        name={props.id}
                        defaultValue={props.choosen}
                        
                    /* onChange={props.handleChange}*/
                    onChange={ e => props.onInputChange(e)}
                    >
                        {props.options.map(el => {
                            i++;
                            //if (el[0] == props.choosen)
                            //    return <option value={el[0]} selected>{el[1]}</option>
                           // else
                                return <option value={el[0]} key={i}>{el[1]}</option>
                            })
                        }
                        
                    </select>
                    <div className="error-info">Wybierz opcję</div>
                </div>
            </div>
        </div>
)
    
}

export default FormSelect;