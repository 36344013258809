import React, { Component } from 'react';
//import FormSelect from './FormSelect';
import MasterForm from './MasterForm'
import Papa from "papaparse";

import './App.css'
import {updateDataProvider} from "./dataProvider";
class App extends Component {
  
  getIinit
  
  constructor( props ) {
    super(props);
    
    this.state = { loaded: false };
    this.loadData();
  }
  
  async loadData() {
    const files = [
      {file: 'parameters', map: true},
      {file: 'table_3', map: true},
      {file: 'table_12'},
      {file: 'table_a', map: true},
      {file: 'table_b', map: true},
      {file: 'table_c', map: true},
      {file: 'table_d', map: true},
      
      {file: 'type_of_heating_after', map: true},
      {file: 'type_of_heating_before', map: true},
      {file: 'type_of_heating_emission_co2', map: true},
      {file: 'type_of_heating_emission_lzo', map: true},
      {file: 'type_of_heating_emission_lzo_2', map: true},
      {file: 'type_of_heating_emission_pm', map: true},
      {file: 'type_of_heating_fuel', map: true},
    ];
    
    await Promise.all(files.map(async (file) => {
      let response = await fetch(process.env.PUBLIC_URL + '/assets/'+file.file+'.csv');
      const content = await response.text();
      
      Papa.parse(content, {complete: ( results ) => {
          // this.setParsedData( results.data );
          if( file.map ) {
            const mappedData = Object.assign(...results.data.filter(value => (value !== '' && value.length > 1 )).map(([v, k]) => {
              if( isNaN(Number(k)) && k !== '' ) {
                console.warn( 'Wrong numeric value! ', k, v, results.data );
              } else {
                k = Number(k);
              }
              
              return ({ [v]: k });
            }));
            updateDataProvider( file.file, mappedData );
          } else {
            const values = results.data.map( value => Number(value) );
            
            updateDataProvider( file.file, values );
          }
        }})
    }));
    
    this.setState( {loaded: true} );
  }
  
    render() {
      
      
        return (
            <div className="appContainer">
                
                <MasterForm currentStep={1}/>
                <div class="ver">1.0.0</div>
            </div>
        )
    }
}

export default App;
