import React, { Component } from 'react';
import FormSelect from './FormSelect'
import FormSelectT from './FormSelectT'
import FormInput from './FormInput'
import {lacznaPowOgrzewana} from './utils'
import './Step1.css'
  
class Step1 extends Component {
      
   
    render() {
      
      if (this.props.currentStep !== 1) { // Prop: The current step
        return null
      }
      let lacznaPowOg = lacznaPowOgrzewana(this.props.powierzchniaPoj,this.props.liczbaKondygnacji)
      if (lacznaPowOg <= 0) {lacznaPowOg=""}
      // The markup for the Step 1 UI
      return(
        
            
            <div className="form-group1">
            
            <div className = "formSelects">
                        {/*<FormSelect 
                            label = "Typ budynku"
                            id = "typBudynku"
                            options = {[
                                [0, "wybierz"],
                                [1, "Mieszkanie/Segment"],
                                [2, "Dom jednorodzinny"],
                            ]}
                            valid = {this.props.typBudynkuValid}
                            choosen = {this.props.typBudynku}
                            onInputChange = {this.props.handleChange} 
                            
                        />*/}
                        <FormSelect 
                            label = "Wiek budynku"
                            id = "wiekBudynku"
                            options = {[
                                [0, "wybierz"],
                                [1, "wybudowany do 1945 roku"],
                                [2, "wybudowany w latach 1945 - 1955"],
                                [3, "wybudowany w latach 1956 - 1970"],
                                [4, "wybudowany w latach 1971 - 1981"],
                                [5, "wybudowany w latach 1982 - 1990"],
                                [6, "wybudowany w latach 1991 - 1994"],
                                [7, "wybudowany w latach 1995 - 2007"],
                                [8, "wybudowany w latach 2008 - 2013"],
                                [9, "wybudowany w roku 2014 lub później"],
                                [10, "Dom energooszczędny NF40"],
                                [11, "Dom pasywny NF15"],
                            ]}
                            
                            choosen = {this.props.wiekBudynku}
                            onInputChange = {this.props.handleChange}
                            valid = {this.props.wiekBudynkuValid}
                        />
                        <FormSelect 
                            label = "Miasto/miejscowość (lub najbliższe Twojej lokalizacji)"
                            id = "miasto"
                            options = {[
                                [0, "wybierz"],
                                [1, "Białystok"],
                                [2, "Bielsko Biała"],
                                [3, "Bydgoszcz"],
                                [4, "Chojnice"],
                                [5, "Częstochowa"],
                                [6, "Elbląg"],
                                [7, "Gdańsk"],
                                [8, "Gorzów Wlkp."],
                                [9, "Hel"],
                                [10, "Jelenia Góra"],
                                [11, "Kalisz"],
                                [12, "Katowice"],
                                [13, "Kętrzyn"],
                                [14, "Kielce"],
                                [15, "Kłodzko"],
                                [16, "Koło"],
                                [17, "Kołobrzeg"],
                                [18, "Koszalin"],
                                [19, "Kraków"],
                                [20, "Krosno"],
                                [21, "Legnica"],
                                [22, "Lesko"],
                                [23, "Leszno"],
                                [24, "Lębork"],
                                [25, "Lublin"],
                                [26, "Łeba"],
                                [27, "Łódź"],
                                [28, "Mikołajki"],
                                [29, "Mława"],
                                [30, "Nowy Sącz"],
                                [31, "Olsztyn"],
                                [32, "Opole"],
                                [33, "Ostrołęka"],
                                [34, "Piła"],
                                [35, "Płock"],
                                [36, "Poznań"],
                                [37, "Przemyśl"],
                                [38, "Racibórz"],
                                [39, "Resko"],
                                [40, "Rzeszów"],
                                [41, "Sandomierz"],
                                [42, "Siedlce"],
                                [43, "Słubice"],
                                [44, "Sulejów"],
                                [45, "Suwałki"],
                                [46, "Szczecin"],
                                [47, "Szczecinek"],
                                [48, "Świnoujście"],
                                [49, "Tarnów"],
                                [50, "Terespol"],
                                [51, "Toruń"],
                                [52, "Ustka"],
                                [53, "Warszawa"],
                                [54, "Wieluń"],
                                [55, "Włodawa"],
                                [56, "Wrocław"],
                                [57, "Zakopane"],
                                [58, "Zamość"],
                                [59, "Zielona Góra"],
                                
                            ]}
                            
                            choosen = {this.props.miasto}
                            onInputChange = {this.props.handleChange}
                            valid = {this.props.miastoValid}
                        />
                        <FormSelect 
                            label = {["Liczba kondygnacji ogrzewanych",<p class="min">Dachy płaskie i nieznacznie nachylone – liczby całkowite<br/>
                            Dachy skośne i pomieszczenia ogrzewane na poddaszu w skosach dachu 
                            – liczby z połówkami</p>]}
                            id = "liczbaKondygnacji"
                            options = {[
                                [0, "wybierz"],
                                [1, "1"],
                                [1.5, "1,5"],
                                [2, "2"],
                                [2.5, "2,5"],
                                [3, "3"],
                            ]}
                            
                            choosen = {this.props.liczbaKondygnacji}
                            onInputChange = {this.props.handleChange}
                            valid = {this.props.liczbaKondygnacjiValid}
                        />
                        <FormInput
                            label = {["Powierzchnia ogrzewana pojedynczej kondygnacji (m", <sup  key={99}>2</sup>,  ")"]}
                            id = "powierzchniaPoj"
                           
                            tooltip = {["Do obliczeń potrzebna jest tzw ", <strong  key={0}>powierzchnia ogrzewana</strong>,  " należy zatem wykluczyć piwnice, strychy, balkony i powierzchnie pomieszczeń nie przeznaczonych na pobyt ludzi."]}
                            choosen = {this.props.powierzchniaPoj}
                            onInputChange = {this.props.handleChange}
                            valid = {this.props.powierzchniaPojValid}
                        />
                        <div className = "form-select">
                            <div className="row">
                                <div className="col-1">
                                    {["Łączna powierzchnia ogrzewana (m",<sup>2</sup>,")"]}
                                    
                                </div>
                                <div className="col-2">
                                    <div className="tempSel">
                                        {lacznaPowOg} 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormSelectT 
                            label = "Stopień ocieplenia ścian zewnętrznych"
                            id = "stopienOciepleniaZew"
                            options = {[
                                [0, "wybierz"],
                                [1, "Nieocieplone"],
                                [2, "Częściowo ocieplone"],
                                [3, "Całkowicie ocieplone"],
                            ]}
                            tooltip = {[<b key={1}>Nieocieplone:</b>," żadna ze ścian zwnętrznych nie posiada izolacji cieplnej z materiałów takich jak wełna mineralna lub styropian (EPS, XPS, etc.)",<br   key={2}></br>,<b   key={3}>Częściowo ocieplone: </b>, "niektóre ze ścian zewnętrznych (ale nie wszystkie) posiadają izolację cieplną z materiałów takich jak wełna mineralna lub styropian (EPS, XPS, etc.).",<br   key={4}></br>
                            ,<b   key={5}>Całkowicie ocieplone: </b> ,"wszystkie ściany zewnętrzne posiadają izolację cieplną z materiałów takich jak wełna mineralna lub styropian (EPS, XPS, etc.)"]}
                            choosen = {this.props.stopienOciepleniaZew}
                            onInputChange = {this.props.handleChange}
                            valid = {this.props.stopienOciepleniaZewValid}
                        />
                        <FormSelectT 
                            label = "Stopień ocieplenia dachu/stropu nad najwyższą kondygnacją"
                            id = "stopienOciepleniaDachu"
                            options = {[
                                [0, "wybierz"],
                                [1, "Nieocieplony"],
                                [2, "Częściowo ocieplony"],
                                [3, "Całkowicie ocieplony"],
                            ]}
                            tooltip = {[<b key={1}>Nieocieplone:</b>,"  strop nad najwyższą kondygnacją/dach nie posiada izolacji cieplnej z materiałów takich jak wełna mineralna lub styropian (EPS, XPS, etc.)",<br   key={2}></br>,<b   key={3}>Częściowo ocieplone: </b>, "fragmenty stropu nad najwyższą kondygnacją/dachu posiadają izolację cieplną z materiałów takich jak wełna mineralna lub styropian (EPS, XPS, etc.).",<br   key={4}></br>
                            ,<b   key={5}>Całkowicie ocieplone: </b> ,"cały strop nad najwyższą kondygnacją/dach posiada izolację cieplną z materiałów takich jak wełna mineralna lub styropian (EPS, XPS, etc.)"]}
                            
                            choosen = {this.props.stopienOciepleniaDachu}
                            onInputChange = {this.props.handleChange}
                            valid = {this.props.stopienOciepleniaDachuValid}
                        />
                        <FormSelectT 
                            label = "Typ/rodzaj okien"
                            id = "typOkien"
                            options = {[
                                [0, "wybierz"],
                                [1, "Okna starego typu (skrzynkowe, i/lub skręcane)"],
                                [2, "Okna częściowo wymienione na nowoczesne"],
                                [3, "Nowoczesne okna dwu- lub trzyszybowe"]
                            ]}
                            tooltip = {[<b key={1}>Okna częściowo wymienione na nowoczesne:</b>," niektóre okna zostały niedawno wymienione na nowoczesne (np. dwuszybowe PVC o wsp. U - 1,1 W/m²K lub niższym)",<br  key={2}></br>,
                                <b key={3}>Nowoczesne okna dwu- lub trzyszybowe:</b>," Wszystkie okna są nowoczesne (np. dwu- lub trzyszybowe PVC w wsp. U - 1,1 W/m²K lub niższym)"]}
                            choosen = {this.props.typOkien}
                            onInputChange = {this.props.handleChange}
                            valid = {this.props.typOkienValid}
                        />
                        <FormSelectT 
                            label = "Źródła ciepła do ogrzewania"
                            id = "zrodloCiepla"
                            options = {[
                                [0, "wybierz"],
                                [1, "Kocioł gazowy"],
                                [2, "Kocioł olejowy"],
                                [3, "Kocioł na biomasę (pelety)"],
                                [4, "Kocioł na gaz płynny LPG"],
                                [5, "Kocioł węglowy"],
                                [6, "Kocioł węglowy - ekogroszek"],
                                [7, "Energia elektryczna"],
                                [8, "Ciepło sieciowe"],
                                [9, "Pompa ciepła"],
                                [10, "Powietrzna pompa ciepła"],
                                [11, "Gruntowa pompa ciepła"]
                            ]}
                            tooltip = "Wybierz rodzaj kotła lub urządzenia grzewczego wytwarzającego ciepło do ogrzewania budynku."
                            choosen = {this.props.zrodloCiepla}
                            onInputChange = {this.props.handleChange}
                            valid = {this.props.zrodloCieplaValid}
                        />
                        <FormSelectT 
                            label = "Sposób przygotowania ciepłej wody użytkowej"
                            id = "wodaUz"
                            options = {[
                            
                                [0, "wybierz"],
                                [1, "Kocioł gazowy"],
                                [2, "Kocioł olejowy"],
                                [3, "Kocioł na biomasę (pelety)"],
                                [4, "Kocioł na gaz płynny LPG"],
                                [5, "Kocioł węglowy"],
                                [6, "Kocioł węglowy - ekogroszek"],
                                [7, "Energia elektryczna"],
                                [8, "Ciepło sieciowe"],
                                [9, "Pompa ciepła"],
                                [10, "Powietrzna pompa ciepła"],
                                [11, "Gruntowa pompa ciepła"],
                                [12,  "Kolektor słoneczny"]
                            ]}
                            tooltip = "Wybierz rodzaj kotła lub urządzenia grzewczego wytwarzającego ciepło do podgrzewania ciepłej wody użytkowej."
                            choosen = {this.props.wodaUz}
                            onInputChange = {this.props.handleChange}
                            valid = {this.props.wodaUzValid}
                        />
                    {this.previousButton}
             {this.nextButton} 
            
        
             
                    </div>
            </div>
        
      )
    }
  }
  export default Step1;