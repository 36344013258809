import React  from 'react';
import './FormClickBox.css'

const FormClickSelectBlock = props => {
    //console.log(props)
    let cB = false;
    if (props.clickedBox > 0) cB = true; else cB = false;
    return (
        
        <div className = {cB ? 'FormClickBlock clicked fullw': 'FormClickBlock fullw'}>
            <div class="rowBox">
            <div class="colBox cbi">
                    <img src={props.img} alt={props.name}/>
                </div>
                <div class="colBox cbs">
                <label htmlFor={props.id}>{props.name}
                        
                        </label>
                    <select 
                        id={props.id}
                        name={props.id}
                        multiple = {props.multiple}
                    /* onChange={props.handleChange}*/
                    //onChange={ e => props.onInputChange(e)}
                    //onClick = {e =>  props.onBoxClick(props.id)}
                    onChange = {e =>  props.onInputBoxChange(e)}
                    >
                        {props.options.map(el => {
                            if (el[0] == props.choosen)
                                return <option value={el[0]} selected>{el[1]}</option>
                            else
                                return <option value={el[0]}>{el[1]}</option>
                            })
                        }
                        
                    </select>
                </div>
                
            </div>
        </div>
        
    )
}

export default FormClickSelectBlock;