const dataProvider = {};

export const getDataProvider = () => {
  return dataProvider;
};

export const updateDataProvider = ( key, value ) => {
  dataProvider[key] = value;
  // console.log( 'Updating data provider', key, value, dataProvider );
};

export const getDataProviderValue = ( key ) => {
  // console.log( 'dataProvider', dataProvider );
  
  if( !dataProvider[key] ) {
    console.warn( 'Wrong provider', key );
  }
  
  
  return dataProvider[key];
};

export const getParameter = ( id ) => {
  const parameters = getDataProviderValue( 'parameters' );
  return parameters[id];
};
