import React, { Component } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

const Option = props => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label>{props.label}</label>
    </components.Option>
  </div>
);

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

class MySelect extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.node,
        label: PropTypes.node
      })
    ).isRequired,
    onChangeCallback: PropTypes.func.isRequired
  };

  static defaultProps = {
    options: []
  };

  render() {
    const { options, onChangeCallback, clickedBox,  id, name, img/*, ...otherProps*/ } = this.props;
    let cB = false;
    if (clickedBox > 0) cB = true; else cB = false;
    return (
      <div className = {cB ? 'FormClickBlock clicked fullw': 'FormClickBlock fullw'}>
            <div class="rowBox">
            <div class="colBox cbi">
                    <img src={img} alt={name}/>
                </div>
                <div class="colBox cbs">
                    <label htmlFor={id}>{name}
                        
                    </label>
                
      <Select
        closeMenuOnSelect={false}
        isMulti
        components={{ Option, MultiValue }}
        options={options}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        onChange={e => onChangeCallback(e)}
        placeholder = "wybierz"
      //  {...otherProps}
      />
      </div>
                
            </div>
        </div>
    );
  }
}

export default MySelect;