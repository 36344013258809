import React, { Component } from "react";
import Chart from "react-apexcharts";

class Donut extends Component {

  constructor(props) {
    super(props);
    
    //this.updateCharts = this.updateCharts.bind(this);

    this.state = {
          
      series: this.props.wart,
      options: {
        chart: {
          type: 'radialBar',
          offsetY: -20
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 15,
              size: "40%"
            },
            dataLabels: {
              name: {
                fontSize: '16px',
                color: 'red',
                offsetY: 120
              },
              value: {
                offsetY: -20,
                fontSize: '22px',
                fontWeight: 'bold',
                color: undefined,
                formatter: function (val) {
                  return Math.round(val)  + "%";
                }
              }
            }
          }
        },
        fill: {
          
          colors: ['#17A096']
        },
        stroke: {
          show: false,
          curve: 'smooth',
          lineCap: 'butt',
          //colors: ['#fff'],
          width: 5,
          dashArray: 4    
      },
        labels: [' '],
      },
    
    
    };
  }

 componentDidUpdate(prevProps, prevState) {
    // Typowy sposób użycia (nie zapomnij porównać właściwości):
    if (this.props.wart !== prevProps.wart) {
      this.setState({
      
      series: [this.props.wart]
    });
    }
  }
 /* updateCharts() {
    

    this.setState({
      
      seriesRadial: [Math.floor(Math.random() * (90 - 50 + 1)) + 50]
    });
  }*/


  render() {
    return (
      <div className="donut">
        <Chart options={this.state.options} series={this.state.series} type="radialBar" width="380" />
      </div>
    );
  }
}

export default Donut;