import React, { Component } from 'react';
import FormClickBlock from './FormClickBlock'
import FormClickSelectBox from './FormClickSelectBox'
import {oc, energyBefore, energyAfter, energyCostBef, energyCostSavings, smartfony, co2Before,co2After, drzewka, pylBefore, pylAfter, lzoBefore, lzoAfter, balony, noxAfter,noxBefore, wiaderka, oddechy, robocza, robocza1, noxDiffer, robocza2} from './utils'
import './Step2.css'
//import { Link } from 'react-router-dom';
import MySelect from "./MySelect";

import f from './images/fotowolatnika.png'
import icwuz from './images/instalacjaCieplejWodyUz.png'
import ig from './images/instalacjaGrzewcza.png'
import k from './images/kolektory.png'
import o_dach from './images/ocieplenie_dach.png'
import o_podloga from './images/ocieplenie_podloga.png'
import o_scian from './images/ocieplenie_scian.png'
import w from './images/wentylacja.png'
import wo from './images/wymianaOkien.png'
import zco from './images/zrodloCieplaO.png'
import zcw from './images/zrodloCieplaW.png'


import dach from './images/dom/Dach.png'
import dom from './images/dom/DOM.png'
import fotowoltaik from './images/dom/Fotovoltaika.png'
import gruntowaPompaCiepla from './images/dom/GruntowaPompaCiepla.png'
import instalacjaGrzewcza from './images/dom/InstalacjaGrzewcza.png'
import istniejaceZrodloCiepla from './images/dom/IstniejaceZrodloCiepla.png'
import kociolBiomasa from './images/dom/KociolBiomasa.png'
import kociolGaz from './images/dom/KociolGaz.png'
import kocioOlej from './images/dom/KocioOlej.png'
import kolektory from './images/dom/Kolektory.png'
import oknaDrzwi from './images/dom/OknaDrzwi.png'
import podlogaNaGruncieStrop from './images/dom/PodlogaNaGruncieStrop.png'
import powietrznaPompaCiepla from './images/dom/PowietrznaPompaCiepla.png'
import scianyZewnetrzne from './images/dom/ScianyZewnetrzne.png'
import wentylacja from './images/dom/Wentylacja.png'
import wezelCieplny from './images/dom/WezelCieplny.png'

import ikona from './images/ikona.png'

import smartfon from './images/smartfon.png'
import wiaderko from './images/wiaderko.png'
import drzewko from './images/drzewko.png'
import balon from './images/balon.png'
import oddech from './images/oddech.png'

import Donut from './Chart'
import leg1 from './images/leg1.png'
import leg2 from './images/leg2.png'
//import MasterForm from './MasterForm';

//import pdfFileLink from './pdfFileLink';

//import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import PDF from './pdfCreation';


//import dom from './images/dom/DOM.png'
class Step2 extends Component {
  
    render() {
      if (this.props.currentStep !== 2) { // Prop: The current step
        return null
      }
      let d = this.dom();
      let enBef = energyBefore(this.props)
      //let d61 = fd61(powierzchniaPoj, liczbaKondygnacji, stopienOciepleniaZew, miasto, typOkien, stopienOciepleniaDachu, zrodloCiepla)
      let enAft = Math.round(energyAfter(this.props))
      let enDiff = enBef - enAft
      let enDiffDis = '';
      if (enDiff <= 0)
        enDiffDis = "brak oszczędności"
      else
        enDiffDis = (Math.round(enDiff*100)/100).toLocaleString('pl')
      
     // let sm = smartfony(this.props.zrodlaCieplaO,this.props.wiekBudynku, this.props.kolektory, this.props.instalacjaCieplekWodUz, this.props.zrodlaCieplaW, this.props.wodaUz, this.props.wentylacja, this.props.instalacjaGrzewcza, this.props.ocieplenie, this.props.powierzchniaPoj, this.props.liczbaKondygnacji, this.props.stopienOciepleniaZew, this.props.miasto, this.props.zrodloCiepla, this.props.wymianaOkien, this.props.typOkien, this.props.stopienOciepleniaDachu)
     // enBef = enBef.toLocaleString('pl')
     // enAft = enAft.toLocaleString('pl')
      let enCostBef = energyCostBef(this.props)
      let enCostSav = energyCostSavings(this.props)
      let enCostDiff = enCostBef - enCostSav
      
     // enCostBef = enCostBef.toLocaleString('pl')
     // enCostSav = enCostSav.toLocaleString('pl')
     // enCostDiff = enCostDiff.toLocaleString('pl')
     let co2Bef = co2Before(this.props)
     let co2Aft = co2After({
      ...this.props,
      d69: co2Bef
     })
     let co2Diff = co2Bef - co2Aft
     let sm = smartfony(enDiff)
     let buki = drzewka(co2Aft)

     let pylBef = pylBefore(this.props)
     let pylAft = pylAfter(pylBef, this.props)
     let pylDiff = pylBef - pylAft

     let lzoBef = lzoBefore(this.props)
     let lzoAft = lzoAfter(lzoBef, this.props)
     let lzoDiff = lzoBef - lzoAft

     let bal = balony(lzoDiff)

     let noxBef = noxBefore(this.props)
     let noxDiff = noxDiffer(this.props)
     let noxAft = noxAfter(noxBef,noxDiff)

     let od = oddechy(noxDiff)
     let wiad = wiaderka(pylDiff)

     let sav = enDiff + enCostSav + co2Aft + pylDiff + lzoDiff + noxDiff
     sav = Math.round(sav*100)/100
     if (sav < 0) sav = 0

    let oszczEn = (enDiff / enBef) * 100
    if (oszczEn < 0)  oszczEn = 0

    let oszczK = (enCostSav / enCostBef) * 100
    if (oszczK < 0)  oszczK = 0

     let options_ocieplenie = [
      [0, "wybierz"],
      [1, "Ściany zewnętrzne"],
      [2, "Ocieplenie dachu/stropu nad najwyższą kondygnacją "],
      [3, "Ocieplenie podłogi"]
      ]
     if (this.props.stopienOciepleniaZew === 3) {
      options_ocieplenie = [
        [0, "wybierz"],
        //[1, "Ściany zewnętrzne"],
       // [2, "Ocieplenie dachu/stropu nad najwyższą kondygnacją "],
        [3, "Ocieplenie podłogi"]
        ]
     }
     let oBc = this.props.handleClick
     let cBx = this.props.kolektory
     /*if (this.props.wodaUz == 12){
        oBc = this.props.handleClickDis
        cBx = true
        
     }*/
    let options_ocieplenie1 = [
     // { value: 0, label: "wybierz" },
      { value: 1, label: "Ściany zewnętrzne" },
      { value: 2, label: "Ocieplenie dachu/stropu nad najwyższą kondygnacją" },
      { value: 3, label: "Ocieplenie podłogi" },
      
    ];
    if (this.props.stopienOciepleniaZew === 3) {
      options_ocieplenie1 = [
       // { value: 0, label: "wybierz" },
       // { value: 1, label: "Ściany zewnętrzne" },
       // { value: 2, label: "Ocieplenie dachu/stropu nad najwyższą kondygnacją" },
        { value: 3, label: "Ocieplenie podłogi" },
        
      ];
    }
     let options_ogrzewanie = [
      //[-1, "wybierz"],
      [0, "Istniejące źródło ciepła do ogrzewania"],
      [3, "Kocioł na biomasę (pelety)"],
      [1, "Kocioł gazowy"],
      [10, "Powietrzna pompa ciepła"],
      [11, "Gruntowa pompa ciepła"]
      ]
      if (this.props.zrodloCiepla === 9 || this.props.zrodloCiepla === 10 || this.props.zrodloCiepla === 11) {
        options_ogrzewanie = [
          //[-1, "wybierz"],
          [0, "Istniejące źródło ciepła do ogrzewania"],
          [3, "Kocioł na biomasę (pelety)"],
          [1, "Kocioł gazowy"],
          //[10, "Powietrzna pompa ciepła"],
         // [11, "Gruntowa pompa ciepła"]
          ]
      }
      let options_woda = [
        //[-1, "wybierz"],
        [0, "Istniejące źródło ciepła do ogrzewania"],
       //? [1, "Kocioł na biomasę (pelety)"],
       //? [2, "Kocioł gazowy"],
        [10, "Powietrzna pompa ciepła"],
      //?  [11, "Gruntowa pompa ciepła"]
        ]
        if (this.props.wodaUz === 9 || this.props.wodaUz === 10 || this.props.wodaUz === 11) {
          options_woda = [
            //[-1, "wybierz"],
            [0, "Istniejące źródło ciepła do ogrzewania"],
           //? [1, "Kocioł na biomasę (pelety)"],
          // ? [2, "Kocioł gazowy"],
            //[3, "Powietrzna pompa ciepła"],
           // [4, "Gruntowa pompa ciepła"]
            ]
        }
     return(
        <div>
          <div class="row2">
            <div className="form-group bigBlock">
              
              <div className = "formBlocks">
              {this.previousButton}
                
              {options_ocieplenie1.map((item) =>
                <FormClickBlock
                  name = {item.label}
                  onBoxClick = {() => this.props.handleClickSelectCh(item.value)}
                  clickedBoxFun = {() => oc(this.props.ocieplenie, item.value)}
                  img = {item.value === 1 ? o_scian : item.value === 2 ? o_dach : o_podloga}
                  id="ocieplenie"
                />
              )}
                       
              <FormClickSelectBox
                            name = "Źródła ciepła do ogrzewania"
                            id = "zrodlaCieplaO"
                            options = {options_ogrzewanie}
                            choosen = {this.props.zrodlaCieplaO}
                            onInputBoxChange = {this.props.handleClickSelect}
                            clickedBox = {this.props.zrodlaCieplaO}
                            img = {zco}
                            
                        />
                        <FormClickSelectBox
                            name = "Źródła ciepła dla c.w.u"
                            id = "zrodlaCieplaW"
                            options = {options_woda}
                            choosen = {this.props.zrodlaCieplaW}
                            onInputBoxChange = {this.props.handleClickSelect}
                            clickedBox = {this.props.zrodlaCieplaW}
                            img = {zcw}
                            
                        />
                        
                        <FormClickBlock
                            name = "Wymiana okien i drzwi"
                            id = "wymianaOkien"
                            onBoxClick = {this.props.handleClick}
                            clickedBox = {this.props.wymianaOkien}
                            img = {wo}
                            
                        />
                        
                          <FormClickBlock
                            name = "Instalacja grzewcza"
                            id = "instalacjaGrzewcza"
                            onBoxClick = {this.props.handleClick}
                            clickedBox = {this.props.instalacjaGrzewcza}
                            img = {ig}
                            
                        />
                          <FormClickBlock
                            name = "Instalacja ciepłej wody użytkowej"
                            id = "instalacjaCieplekWodUz"
                            onBoxClick = {this.props.handleClick}
                            clickedBox = {this.props.instalacjaCieplekWodUz}
                            img = {icwuz}
                            
                        />
                        <FormClickBlock
                            name = "Wentylacja"
                            id = "wentylacja"
                            onBoxClick = {this.props.handleClick}
                            clickedBox = {this.props.wentylacja}
                            img = {w}
                            
                        />
                        <FormClickBlock
                            name = "Instalacja fotowoltaiczna"
                            id = "fotowoltanika"
                            onBoxClick = {this.props.handleClick}
                            clickedBox = {this.props.fotowoltanika}
                            img = {f}
                            
                        />
                        <FormClickBlock
                            name = "Termiczne kolektory słoneczne"
                            id = "kolektory"
                            onBoxClick = {oBc}
                            clickedBox = {cBx}
                            img = {k}
                            
                        />
                    </div>
            </div>
            
              <div class="image">
                  {d}
                  <div class="cena"><div className="cenacc"><div className="ikona"><img src={ikona}/></div><div className="cenac">Oszczędności<p>{robocza1(enCostSav)} zł rocznie</p></div></div></div>
              </div>
           
            <div class="wykres">
              <div class="chart" id="chart">
                <div class="c1">
                <p className="pierw">OSZCZĘDNOŚĆ<br/>ENERGII</p>
                <Donut  type="radialBar" wart = {[oszczEn]} />
                <img src={leg1}/>
                </div>
                <div class="c1">
                <p className="drug">OBNIŻENIE<br/>KOSZTÓW ENERGII</p>
                <Donut  type="radialBar" wart = {[oszczK]} />
                <img src={leg2}/>
                </div>
                

              </div>
            </div>
          </div>
          <div className="nomob">
            <div ><h2>KORZYŚCI DLA TWOJEGO PORTFELA i ŚRODOWISKA</h2></div>
            <div className="tabela">
              <div>
                          <table>
                            <tr><th></th><th>Przed modernizacją</th><th>Po modernizacji</th><th>Korzyści</th><th></th></tr>
                            <tr><td>Zużycie energii (kWh/rok)</td><td>{robocza(enBef,"oszczędności")}</td><td>{robocza(enAft,"oszczędności")}</td><td>{robocza(enDiff,"oszczędności")}</td><td rowspan="2"><table><tr><td><img src={smartfon} alt="smartfon"/></td><td><span className="wyr"> x {robocza2(sm)}</span></td><td>Tyle razy naładowałbyś swój smartfon, gdyby zaoszczędzona energia była energią elektryczną. <span class="gr">*</span></td></tr></table></td></tr>
                            <tr><td>Koszt energii (zł/rok)</td><td>{robocza(enCostBef,"oszczędności")}</td><td>{robocza(enCostDiff,"oszczędności")}</td><td>{robocza(enCostSav,"oszczędności")}</td></tr>
                            <tr><td>Zmniejszenie emisji CO2 (ton/rok)</td><td>{robocza(co2Bef,"oszczędności")}</td><td>{robocza(co2Diff,"emisji")}</td><td>{robocza(co2Aft,"oszczędności")}</td><td><table><tr><td><img src={drzewko} alt="drzewko"/></td><td><span className="wyr"> x { robocza2(buki)}</span></td><td>Taka liczba buków jest w stanie zaabsorbować rocznie ilość CO2, o jaką zmniejszono emisję. <span class="gr">**</span></td></tr></table></td></tr>
                            <tr><td>Zmniejszenie emisji pyłu (kg/rok)</td><td>{robocza(pylBef,"oszczędności")}</td><td>{robocza(pylAft,"emisji")}</td><td>{robocza(pylDiff,"oszczędności")}</td><td><table><tr><td><img src={wiaderko} alt="wiaderko"/></td><td><span className="wyr"> x { robocza2(wiad)}</span></td><td>Taką liczbę wiaderek wypełni ilość pyłu, o jaką zmniejszono emisję.</td></tr></table></td></tr>
                            <tr><td>Zmniejszenie emisji LZO (kg/rok)</td><td>{robocza(lzoBef,"oszczędności")}</td><td>{robocza(lzoAft,"emisji")}</td><td>{robocza(lzoDiff,"oszczędności")}</td><td><table><tr><td><img src={balon} alt="balon"/></td><td><span className="wyr"> x { robocza2(bal)}</span></td><td>Taką liczbę balonów wypełni
  LZO, o jaką zmniejszono emisję.</td></tr></table></td></tr>
                            <tr><td>Zmniejszenie emisji NOx (kg/rok) </td><td>{robocza(noxBef,"oszczędności")}</td><td>{robocza(noxAft,"oszczędności")}</td><td>{robocza(noxDiff,"oszczędności")}</td><td><table><tr><td><img src={oddech} alt="smaroddechtfon"/></td><td><span className="wyr"> x { robocza2(od)}</span></td><td>Tyle pełnych oddechów czystym powietrzem odpowiada objętości nie wyemitowanego NOx. <span class="gr">***</span>
  </td></tr></table></td></tr>
                          
                          
                          </table>
              </div>
            </div>
          </div>
            <div className="mob">
              <h2>KORZYŚCI DLA TWOJEGO PORTFELA</h2>
              <div className="mobBlock">
                
                            
                                <p className="ktitle">Zaoszczędzona energia (kWh/rok)</p>
                                <p className="kcontent">{robocza(enDiff,"oszczędności")}</p>
                                <p className="ktitle">Koszt zaoszczędzonej energii (zł/rok)</p>
                                <p className="kcontent">{robocza(enCostSav,"oszczędności")}</p>
                                <table><tr><td><img src={smartfon} alt="smartfon"/></td><td><span className="wyr"> x {( sm > 0) ? robocza1(sm.toLocaleString('pl')) : 0}</span></td></tr></table>
                                <p className="kinfo">Tyle razy naładowałbyś swój smartfon, gdyby zaoszczędzona energia była energią elektryczną. <span class="gr">*</span></p>
              </div>
              <h2>KORZYŚCI DLA ŚRODOWISKA</h2>
              <div className="mobBlock">
                <p className="ktitle">Zmniejszenie emisji CO2 (ton/rok)</p>
                <p className="kcontent">{robocza(co2Aft,"oszczędności")}</p>
                <table><tr><td><img src={drzewko} alt="drzewko"/></td><td><span className="wyr"> x { ( buki > 0) ? robocza2(buki) : 0}</span></td></tr></table>
                <p className="kinfo">Taka liczba buków jest w stanie zaabsorbować rocznie ilość CO2, o jaką zmniejszono emisję. <span class="gr">**</span></p>

                <p className="ktitle">Zmniejszenie emisji pyłu (kg/rok)</p>
                <p className="kcontent">{robocza(pylDiff,"oszczędności")}</p>
                <table><tr><td><img src={wiaderko} alt="wiaderko"/></td><td><span className="wyr"> x { ( wiad > 0) ? robocza2(wiad) : 0}</span></td></tr></table>
                <p className="kinfo">Taką liczbę wiaderek wypełni ilość pyłu, o jaką zmniejszono emisję.</p>

                <p className="ktitle">Zmniejszenie emisji LZO (kg/rok)</p>
                <p className="kcontent">{robocza(lzoDiff,"zmniejszenia emisji")}</p>
                <table><tr><td><img src={balon} alt="balon"/></td><td><span className="wyr"> x { ( bal > 0) ? robocza2(bal) : 0}</span></td></tr></table>
                <p className="kinfo">Taką liczbę balonów wypełni LZO, o jaką zmniejszono emisję.</p>

                <p className="ktitle">Zmniejszenie emisji NOx (kg/rok)</p>
                <p className="kcontent">{robocza(noxDiff,"oszczędności")}</p>
                <table><tr><td><img src={oddech} alt="smaroddechtfon"/></td><td><span className="wyr"> x { ( od > 0) ? robocza2(od) : 0}</span></td></tr></table>
                <p className="kinfo">Tyle pełnych oddechów czystym powietrzem odpowiada objętości nie wyemitowanego NOx.</p>

              </div>
            
            </div>
          
          <div className = "infoUnder">
            <div className="infoUt">
              <div className="savinfo">Inwestycja w wybrane technologie przyniesie oszczędności w wysokości:  <span className="wyr">{enCostSav > 0 ? robocza1(enCostSav) : 0} zł rocznie</span></div>
              <div className="legend">      <span class="gr">*</span> Obliczenia dla przyjętej pojemności baterii w smartfonie 4200 mAh<br/>
              <span className="gr">**</span>Obliczenia dla pięćdziesięcioletniego lasu bukowego<br/>
              <span className="gr">***</span>Obliczenia dla przyjętej objętości pełnego wdechu 4 litry<br/>
              <br/>
  Wyniki obliczeń wykonanych przy użyciu kalkulatora mają charakter orientacyjny i w żadnym wypadku nie mogą być użyte do sporządzania audytów energetycznych lub świadectw charakterystyki energetycznej budynków/mieszkań
              </div>
            </div>
            <div className="pdfLink">
            <div>
               <PDF
                x={[this.props.liczbaKondygnacji,this.props.miasto,this.props.powierzchniaPoj ,this.props.stopienOciepleniaDachu ,this.props.stopienOciepleniaZew ,this.props.typBudynku ,this.props.typOkien ,this.props.wiekBudynku ,this.props.wodaUz ,this.props.zrodloCiepla]}
                y={[this.props.fotowoltanika ,this.props.instalacjaCieplekWodUz ,this.props.instalacjaGrzewcza ,this.props.kolektory ,this.props.wentylacja ,this.props.wymianaOkien ,this.props.zrodlaCieplaO ,this.props.zrodlaCieplaW,this.props.ocieplenie]}
                z={[robocza(enDiff,"oszczędności"),robocza(enCostSav,"oszczędności"),robocza(co2Aft,"oszczędności"),robocza(pylDiff,"oszczędności"),robocza(lzoDiff,"zmniejszenia emisji"),robocza(noxDiff,"oszczędności"),robocza2(sm),robocza2(buki),robocza2(wiad),robocza2(bal),robocza2(od)]}
              />
              
            </div>
            
            </div>
          </div>
          
        </div>
      )
    }
 
  dom() {
    let d = [<img className="fake" src={dom} key={1564564}/>,<img src={dom} key={1}/>];
    if (oc(this.props.ocieplenie,1))
      d.push(<img src={scianyZewnetrzne} key={2}/>);
    if (oc(this.props.ocieplenie,2))
      d.push(<img src={dach} key={3}/>);
    if (oc(this.props.ocieplenie,3))
      d.push(<img src={podlogaNaGruncieStrop } key={4}/>);
    if (this.props.wymianaOkien)
      d.push(<img src={oknaDrzwi } key={5}/>);
    if (Number(this.props.zrodlaCieplaO) == 0)
      d.push(<img src={istniejaceZrodloCiepla } key={6}/>);
    if (Number(this.props.zrodlaCieplaO) == 3)
      d.push(<img src={kociolBiomasa } key={7}/>);
    if (Number(this.props.zrodlaCieplaO) == 1)
      d.push(<img src={kociolGaz } key={8}/>);
    if (Number(this.props.zrodlaCieplaO) == 10)
      d.push(<img src={powietrznaPompaCiepla } key={9}/>);
    if (Number(this.props.zrodlaCieplaO) == 11)
      d.push(<img src={gruntowaPompaCiepla } key={10}/>);
    if (Number(this.props.zrodlaCieplaW)== 0)
      d.push(<img src={istniejaceZrodloCiepla} key={11}/>);
    if (Number(this.props.zrodlaCieplaW) == 3)
      d.push(<img src={kociolBiomasa} key={12}/>);
    if (Number(this.props.zrodlaCieplaW) == 1)
      d.push(<img src={kociolGaz} key={13}/>);
    if (Number(this.props.zrodlaCieplaW) == 10)
      d.push(<img src={powietrznaPompaCiepla} key={14}/>);
    if (Number(this.props.zrodlaCieplaW) == 11)
      d.push(<img src={gruntowaPompaCiepla} key={15}/>);
    if (this.props.instalacjaGrzewcza)
      d.push(<img src={instalacjaGrzewcza } key={16}/>);
    if (this.props.instalacjaCieplekWodUz)
      d.push(<img src={wezelCieplny } key={17}/>);
    if (this.props.wentylacja)
      d.push(<img src={wentylacja } key={18}/>);
    if (this.props.fotowoltanika)
      d.push(<img src={fotowoltaik } key={19}/>);
    if (this.props.kolektory)
      d.push(<img src={kolektory } key={20}/>);
    return d;
  }
  }
  export default Step2;
