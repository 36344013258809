import React, { Component } from 'react';
import Step1 from './Step1'
import Step2 from './Step2'
import {valid} from './utils'
import './MasterForm.css'
const initialState = {
  /* etc */
};

class MasterForm extends Component {
  
    constructor(props) {
      super(props)
      // Set the initial input values
      this.state = {
        currentStep: 1, // Default is Step 1
        typBudynku: 2,
        typBudynkuValid: true,
        wiekBudynkuValid: true,
        miastoValid: true,
        liczbaKondygnacjiValid: true,
        powierzchniaPojValid: true,
        stopienOciepleniaZewValid: true,
        stopienOciepleniaDachuValid: true,
        typOkienValid: true,
        zrodloCieplaValid: true,
        wodaUzValid: true,
        ocieplenieValid: true,
        wiekBudynku: "",
        miasto: "",
        liczbaKondygnacji: "",
        powierzchniaPoj: "",
        stopienOciepleniaZew: "",
        stopienOciepleniaDachu: "",
        typOkien: "",
        zrodloCiepla: "",
        wodaUz: "",
        ocieplenie: "",
        wymianaOkien: false,
        instalacjaGrzewcza: false,
        wentylacja: false,
        kolektory: false,
        instalacjaCieplekWodUz: false,
        zrodlaCieplaW: 0,
        zrodlaCieplaO: 0,
        fotowoltanika: "",
        formErrors: {typBudynku: '', wiekBudynku: ''},
    //typBudynkuValid: true,
    //wiekBudynkuValid: true,
    formValid: false
        
        
        
      }
    //  this.baseState = this.state 
     
      this.handleChange = this.handleChange.bind(this)
      this.handleClick = this.handleClick.bind(this)
      this.handleClickDis = this.handleClickDis.bind(this)
      this.handleClickSelect = this.handleClickSelect.bind(this)
      this.handleClickSelectCh = this.handleClickSelectCh.bind(this)
     // this.valid = this.valid.bind(this)
      
     

      this._next = this._next.bind(this)
      this._prev = this._prev.bind(this)
      this.refr = this.refr.bind(this)
      //this._res = this._res.bind(this)
    }
    /*_res = () => {
    //this.setState({})
    this.setState(initialState);
     
      console.log('test')

    }*/
    refr() {
      window.location.reload(false)
    }
    _next() {
        let currentStep = this.state.currentStep

        // If the current step is 1 or 2, then add one on "next" button click

        if (!this.state.formValid) 
        {
          //valid()
          this.valid();
          //alert("Uzupełnij pola")
          
         // let fieldValidationErrors = this.state.formErrors;
         // this.state.typBudynkuValid = false
        // this.state.typBudynkuValid =  this.state.typBudynku > 0;
//this.state.fieldValidationErrors.typBudynku =  this.state.typBudynkuValid ? '' : ' is invalid';
        }
        else {
        currentStep = currentStep >= 2? 3: currentStep + 1}
        this.setState({
          currentStep: currentStep
        })
      }
        
  
      _prev() {
        let currentStep = this.state.currentStep
        // If the current step is 2 or 3, then subtract one on "previous" button click
        currentStep = currentStep <= 1? 1: currentStep - 1
        this.setState({
          currentStep: currentStep
        })
      }

      get previousButton(){
        let currentStep = this.state.currentStep;
        // If the current step is not 1, then render the "previous" button
        if(currentStep !==1){
          return (
            <button 
              className="btn btn-secondary" 
              type="button" onClick={this._prev}>
            Wstecz
            </button>
          )
        }
        // ...else return nothing
        return null;
      }
    
      get nextButton(){
        let currentStep = this.state.currentStep;
        // If the current step is not 2, then render the "next" button
        if(currentStep <2){
          return (
            <div className="buttons">
              <button 
          type="button" onClick = {this.refr}>Wyczyść</button>  
          
              <button 
                className="btn btn-primary float-right" 
                type="button" onClick={this._next}>
              Zatwierdź
              </button>    
               
            </div> 
          )
        }
        // ...else render nothing
        return null;
      }

    
    handleChange(event) {
      //this.valid();
      const {name, value} = event.target
      this.setState({
        [name]: value},
        () => { this.validateField(name, value) });
        
      }  
  validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let typBudynkuValid = this.state.typBudynkuValid;
        let wiekBudynkuValid = this.state.wiekBudynkuValid;
        let miastoValid = this.state.miastoValid
        let liczbaKondygnacjiValid = this.state.liczbaKondygnacjiValid
        let powierzchniaPojValid = this.state.powierzchniaPojValid
        let stopienOciepleniaZewValid = this.state.stopienOciepleniaZewValid
        let stopienOciepleniaDachuValid = this.state.stopienOciepleniaDachuValid
        let typOkienValid = this.state.typOkienValid
        let zrodloCieplaValid = this.state.zrodloCieplaValid
        let wodaUzValid = this.state.wodaUzValid
        let ocieplenieValid = this.state.ocieplenieValid
      
        switch(fieldName) {
          case 'typBudynku':
            typBudynkuValid = value > 0;
            fieldValidationErrors.typBudynku = typBudynkuValid ? '' : ' is invalid';
            break;
          case 'wiekBudynku':
            wiekBudynkuValid = value > 0;
            fieldValidationErrors.wiekBudynku = wiekBudynkuValid ? '': ' is too short';
            break;
          case 'miasto':
            miastoValid = value > 0;
              fieldValidationErrors.miasto = miastoValid ? '': ' is too short';
              break;
          case 'liczbaKondygnacji':
            liczbaKondygnacjiValid = value > 0;
            fieldValidationErrors.liczbaKondygnacji = liczbaKondygnacjiValid ? '': ' is too short';
            break;
          case 'powierzchniaPoj':
            powierzchniaPojValid = value >= 50 && value <= 300;
            fieldValidationErrors.powierzchniaPoj = powierzchniaPojValid ? '': ' is too short';
            break;
          case 'stopienOciepleniaZew':
            stopienOciepleniaZewValid = value > 0;
            fieldValidationErrors.stopienOciepleniaZew= stopienOciepleniaZewValid ? '': ' is too short';
            break;
          case 'stopienOciepleniaDachu':
            stopienOciepleniaDachuValid = value > 0;
            fieldValidationErrors.stopienOciepleniaDachu = stopienOciepleniaDachuValid ? '': ' is too short';
            break;
          case 'typOkien':
            typOkienValid = value > 0;
            fieldValidationErrors.typOkien = typOkienValid ? '': ' is too short';
            break;
          case 'zrodloCiepla':
            zrodloCieplaValid = value > 0;
            fieldValidationErrors.zrodloCiepla = zrodloCieplaValid ? '': ' is too short';
            break;
          case 'wodaUz':
          wodaUzValid = value > 0;
            fieldValidationErrors.wodaUz = wodaUzValid ? '': ' is too short';
            break;
          case 'ocieplenie':
            ocieplenieValid = value > 0;
                fieldValidationErrors.ocieplenie = ocieplenieValid ? '': ' is too short';
                break;
          default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
          typBudynkuValid: typBudynkuValid,
          wiekBudynkuValid: wiekBudynkuValid,
          miastoValid: miastoValid,
          liczbaKondygnacjiValid: liczbaKondygnacjiValid,
          powierzchniaPojValid: powierzchniaPojValid,
          stopienOciepleniaZewValid: stopienOciepleniaZewValid,
          stopienOciepleniaDachuValid: stopienOciepleniaDachuValid,
          typOkienValid: typOkienValid,
          zrodloCieplaValid: zrodloCieplaValid,
          wodaUzValid: wodaUzValid,
          ocieplenieValid: ocieplenieValid
                      }, this.validateForm);
      }
      
      validateForm() {
        this.setState({formValid: this.state.typBudynkuValid && this.state.wiekBudynkuValid && this.state.miastoValid && this.state.liczbaKondygnacjiValid && this.state.powierzchniaPojValid && this.state.stopienOciepleniaZew && this.state.stopienOciepleniaDachuValid && this.state.typOkienValid && this.state.zrodloCieplaValid && this.state.wodaUzValid && this.state.ocieplenieValid});
      }  
    
      
    
    valid() {
      if (this.state.typBudynku == "")
        this.state.typBudynkuValid = false;
      else
        this.state.typBudynkuValid = true;
      if (this.state.wiekBudynku == "")
        this.state.wiekBudynkuValid = false;
      else
        this.state.wiekBudynkuValid = true;
      if (this.state.miasto == "")
        this.state.miastoValid = false;
      else
        this.state.miastoValid = true;
      if (this.state.liczbaKondygnacji == "")
        this.state.liczbaKondygnacjiValid = false;
      else
        this.state.liczbaKondygnacjiValid = true;
      if (this.state.powierzchniaPoj < 50 || this.state.powierzchniaPoj < 300)
        this.state.powierzchniaPojValid = false;
      else
        this.state.powierzchniaPojValid = true;
      if (this.state.stopienOciepleniaZew == "")
        this.state.stopienOciepleniaZewValid = false;
      else
        this.state.stopienOciepleniaZewValid = true;
      if (this.state.stopienOciepleniaDachu == "")
        this.state.stopienOciepleniaDachuValid = false;
      else
        this.state.stopienOciepleniaDachuValid = true;
      if (this.state.typOkien == "")
        this.state.typOkienValid = false;
      else
        this.state.typOkienValid = true;
      if (this.state.zrodloCiepla == "")
        this.state.zrodloCieplaValid = false;
      else
        this.state.zrodloCieplaValid = true;
      if (this.state.wodaUz == "")
        this.state.wodaUzValid = false;
      else
        this.state.wodaUzValid = true;
    }
  
    handleClick(event) {
      
      const currentState = this.state[event]
      //console.log(currentState)
        this.setState({ [event]: !currentState });
    }

    handleClickDis(event) {
      
      const currentState = true
      //console.log(currentState)
        this.setState({ [event]: currentState });
    }

    handleClickSelect(event) {
      const {name, value} = event.target
      this.setState({
        [name]: value
      }) 
    
        
     
    }

    handleClickSelectCh(value) {
      let ociep = this.state.ocieplenie || []
      if (ociep.includes(value)) {
        ociep = ociep.filter(item => item !== value)
      }
      else {
        ociep.push(value)
      }
      this.setState({
        ocieplenie: ociep
      })       
    }

    // Trigger an alert on form submission
    handleSubmit = (event) => {
      event.preventDefault()
      const { typBudynku } = this.state
      alert(`Your registration detail: \n 
        Email: ${typBudynku} \n 
        `)
    }
    
   
    render() {   
      let klasa = "";
      let klasaC = "";
      let subtitle =""
        if (this.state.currentStep == 1) 
        {
          klasa = "step1"; 
          klasaC = "step1Container"; 
          subtitle = "KROK 1: wybierz CECHY SWOJEJ NIERUCHOMOŚCI"
        } else 
        {
          klasa = "step2";
          klasaC = "step2Container"; 
          subtitle = "KROK 2: wybierz technologie DLA SWOJEJ NIERUCHOMOŚCI" 
        }
        return (
          <div className="container">
            <h1><span>Kalkulator oszczędności energii i&nbsp;wpływu na środowisko</span></h1>
            <h2>{subtitle}</h2>
            <div className={klasaC}>
              <div className={klasa}   >         
              
              {this.previousButton}
              <form onSubmit={this.handleSubmit}>
            
                <Step1 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  typBudynku={this.state.typBudynku}
                  wiekBudynku={this.state.wiekBudynku}
                  miasto={this.state.miasto}
                  liczbaKondygnacji={this.state.liczbaKondygnacji}
                  powierzchniaPoj={this.state.powierzchniaPoj}
                  stopienOciepleniaZew={this.state.stopienOciepleniaZew}
                  stopienOciepleniaDachu={this.state.stopienOciepleniaDachu}
                  typOkien={this.state.typOkien}
                  zrodloCiepla={this.state.zrodloCiepla}
                  wodaUz={this.state.wodaUz}
                  typBudynkuValid={this.state.typBudynkuValid}
                  wiekBudynkuValid={this.state.wiekBudynkuValid}
                  miastoValid={this.state.miastoValid}
                  liczbaKondygnacjiValid={this.state.liczbaKondygnacjiValid}
                  powierzchniaPojValid={this.state.powierzchniaPojValid}
                  stopienOciepleniaZewValid={this.state.stopienOciepleniaZewValid}
                  stopienOciepleniaDachuValid={this.state.stopienOciepleniaDachuValid}
                  typOkienValid={this.state.typOkienValid}
                  zrodloCieplaValid={this.state.zrodloCieplaValid}
                  wodaUzValid={this.state.wodaUzValid}
                  ocieplenieValid={this.state.ocieplenieValid}
                />
                
              
                <Step2 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  handleClick={this.handleClick}
                  handleClickDis={this.handleClickDis}
                  handleClickSelect={this.handleClickSelect}
                  handleClickSelectCh={this.handleClickSelectCh}

                  typBudynku={this.state.typBudynku}
                  wiekBudynku={this.state.wiekBudynku}
                  miasto={this.state.miasto}
                  liczbaKondygnacji={this.state.liczbaKondygnacji}
                  powierzchniaPoj={this.state.powierzchniaPoj}
                  stopienOciepleniaZew={this.state.stopienOciepleniaZew}
                  stopienOciepleniaDachu={this.state.stopienOciepleniaDachu}
                  typOkien={this.state.typOkien}
                  zrodloCiepla={this.state.zrodloCiepla}
                  wodaUz={this.state.wodaUz}

                  ocieplenie = {this.state.ocieplenie}
                  wymianaOkien = {this.state.wymianaOkien}
                  instalacjaGrzewcza = {this.state.instalacjaGrzewcza}
                  wentylacja = {this.state.wentylacja}
                  kolektory = {this.state.kolektory}
                  instalacjaCieplekWodUz = {this.state.instalacjaCieplekWodUz}
                  zrodlaCieplaW = {this.state.zrodlaCieplaW}
                  zrodlaCieplaO = {this.state.zrodlaCieplaO}
                  fotowoltanika = {this.state.fotowoltanika}
                  
                />
                
            
                
                {this.nextButton}     
            
              </form>
            </div>
            </div>
          </div>
        )
        }
  }
  export default MasterForm;