import React from 'react';
import { Page, Text, View, Document, StyleSheet,PDFDownloadLink, Image, Font } from '@react-pdf/renderer';
import logo from './images/logah.png'
import logaFooter from './images/loga.png'
/*import polref from './images/PolREFF.png'

import smartfon from './images/minPDF/smartfon.png'
import wiaderko from './images/minPDF/wiaderko.png'
import drzewko from './images/minPDF/drzewko.png'
import balon from './images/minPDF/balon.png'
import oddech from './images/minPDF/oddech.png'

import f from './images/minPDF/fotowoltaika.png'
import icwuz from './images/minPDF/cwu.png'
import ig from './images/minPDF/instalacjaGrzewcza.png'
import k from './images/minPDF/kolektory.png'
import o from './images/minPDF/ocieplenie.png'
import w from './images/minPDF/wentylacja.png'
import wo from './images/minPDF/okna.png'
import zco from './images/minPDF/ogrzewanie.png'
import zcw from './images/minPDF/zrodloCWU.png'
*/
import robot from './images/Roboto.ttf'
import { balony, oddechy } from './utils';

Font.register({ family: 'Roboto', src: robot });
// Create styles
const newstyles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    flexWrap: "wrap",
    padding:20,
    fontFamily: "Roboto",
    fontSize:"12pt",
    width:"100%"
  },
  logo: {
    height:"auto",
    width:"95%"
  },
  
  section: {
    
    
    width:"48%",
    padding:"2%"
  },
  section1: {
    
    
    width:"96%",
    padding:"0 2%",
  },
  image: {
   width:"43px"
   
  },
  image1: {
    width:"52px"
    
   },
  text13:{
    //width:"85%"
  },
  /*table: { display: "flex", width: "100%", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0, borderColor:"#C4C4C4" }, 
  tableRow: { margin: "auto", flexDirection: "row", alignItems:"center",}, 
  tableCol: { borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor:"#C4C4C4", alignContent:"center", alignItems:"center",alignSelf:"center" , height:100, display:"flex", flexDirection:"column"}, 
  tableCell: { margin: "auto", marginTop: 3, fontSize: 10, alignContent:"center", padding:5, display:"flex", flexWrap: "wrap", alignItems:"center",},
  */
 /*table: { display: "flex", width: "100%", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0, borderColor:"#C4C4C4" }, 
 tableRow: { margin: "auto", flexDirection: "row"}, 
 tableCol: { borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderColor:"#C4C4C4",  height:100}, 
 tableCell: { margin: "auto", marginTop: 3, fontSize: 10, padding:5},*/
  /*table: {
    display: "table",
    borderStyle: "solid",
    flexWrap: "wrap",
  //flexDirection: "column",
  },
  
  tableRow: {
    flex: 1,
    display: "flex",
    flexDirection:"row"
  },
  tableRow1: {
    flex: 1,
    display: "flex",
    
  },

  tableRow2: {
    
    //display: "flex",
    flex: "1 1 100%",
    border: "solid",
    padding: "1em",
    border: "1px solid #444",
    padding: "1em",
    width:"20%"
  },
  
  tableCol:{
    border: "1px solid #444",
    padding: "1em",
    
    
  },*/
  fullc:{
    width:"100%",
    display:"flex",
    flexDirection:"row",
    fontSize:"12pt",
  },
  ziel:{
    color: "#17A096",
    
    fontSize:11,
  },
  textf:{
    fontSize:8,
    fontWeight:"bold"
  },
  
  fullw3:{width:"100%",
    flexDirection:"row",
    alignItems:"center",
    fontWeight:"bold"
  },
    col1: {//width:"15%", paddingRight:"8%"
  },
    col2:{
      paddingLeft:"10pt"
    },
  fullw:{width:"100%"},
  fullw4:{width:"100%",
    display:"flex",
    flexWrap:"wrap",
  flexDirection:"row",
alignItems:"center",
alignContent:"center"
},
fullw5:{width:"100%",
    display:"flex",
    flexWrap:"wrap",
  flexDirection:"row",
alignItems:"center",
},

  fullw1:{width:"100vw",
  
    borderBottomWidth:"2pt",
    borderBottomColor: "#17A096",
    borderBottomStyle:"solid",
    marginBottom:"10pt",
    paddingBottom:"10pt",
    marginRight:"-200pt"
  },
  fullwn:{width:"100vw",
  
    borderBottomWidth:"2pt",
    borderBottomColor: "#17A096",
    borderBottomStyle:"solid",
    marginBottom:"10pt",
    paddingBottom:"10pt",
    marginRight:"-200pt",
    display:"flex",
    justifyContent:"flex-end"
  },
  fullwf:{width:"100vw",
  
  borderBottomWidth:"2pt",
  borderBottomColor: "#17A096",
  borderBottomStyle:"solid",
  marginBottom:"10pt",
  paddingBottom:"10pt",
  marginRight:"-200pt"
  },
  polref:{
    
    width:"70",
    height:"auto",
   
  },
  nagl:{
    color:"#17A096",
    textAlign:"center",
    fontSize:"15pt",
    paddingTop:"15pt",
    paddingBottom:"10pt"
  },
  nagl2:{
    textTransform:"uppercase",
    fontWeight:"bolder",
    textAlign:"center",
    fontSize:"14pt",
    paddingTop:"10pt",
    paddingBottom:5
    
  },
  text1:{
    fontSize:"8pt",
    paddingBottom:8
  },
  coll:{
    width:"39%",
    paddingRight:"1%",
    alignSelf:"center",
    textAlign:"center",
    height:38,
    alignContent:"center"
  },
  colp:{
    width:"60%",
    alignSelf:"center",
    height:38,
    alignContent:"center"
  }
  //  image: { flexGrow: 8, backgroundColor: 'grey', padding: 10 },
});
function miasto(x){
  let wynik = "";
  switch(x){
    case 1:
      wynik = "Białystok";
      break;
    case 2:
      wynik = "Bielsko Biała";
      break;
    case 3:
      wynik = "Bydgoszcz";
      break;
    case 4:
      wynik = "Chojnice";
                                case 5: wynik = "Czętochowa"; break;
                                case 6: wynik = "Elbląg"; break;
                                case 7: wynik = "Gdańsk"; break;
                                case 8: wynik = "Gorzów Wlkp."; break;
                                case 9: wynik = "Hel"; break;
                                case 10: wynik = "Jelenia Góra"; break;
                                case 11: wynik = "Kalisz"; break;
                                case 12: wynik = "Katowice"; break;
                                case 13: wynik = "Kętrzyn"; break;
                                case 14: wynik = "Kielce"; break;
                                case 15: wynik = "Kłodzko"; break;
                                case 16: wynik = "Koło"; break;
                                case 17: wynik = "Kołobrzeg"; break;
                                case 18: wynik = "Koszalin"; break;
                                case 19: wynik = "Kraków"; break;
                                case 20: wynik = "Krosno"; break;
                                case 21: wynik = "Legnica"; break;
                                case 22: wynik = "Lesko"; break;
                                case 23: wynik = "Leszno"; break;
                                case 24: wynik = "Lębork"; break;
                                case 25: wynik = "Lublin"; break;
                                case 26: wynik = "Łeba"; break;
                                case 27: wynik = "Łódź"; break;
                                case 28: wynik = "Mikołajki"; break;
                                case 29: wynik = "Mława"; break;
                                case 30: wynik = "Nowy Sącz"; break;
                                case 31: wynik = "Olsztyn"; break;
                                case 32: wynik = "Opole"; break;
                                case 33: wynik = "Ostrołęka"; break;
                                case 34: wynik = "Piła"; break;
                                case 35: wynik = "Płock"; break;
                                case 36: wynik = "Poznań"; break;
                                case 37: wynik = "Przemyśl"; break;
                                case 38: wynik = "Racibórz"; break;
                                case 39: wynik = "Resko"; break;
                                case 40: wynik = "Rzeszów"; break;
                                case 41: wynik = "Sandomierz"; break;
                                case 42: wynik = "Siedlce"; break;
                                case 43: wynik = "Słubice"; break;
                                case 44: wynik = "Sulejów"; break;
                                case 45: wynik = "Suwałki"; break;
                                case 46: wynik = "Szczecin"; break;
                                case 47: wynik = "Szczecinek"; break;
                                case 48: wynik = "Świnoujście"; break;
                                case 49: wynik = "Tarnów"; break;
                                case 50: wynik = "Terespol"; break;
                                case 51: wynik = "Toruń"; break;
                                case 52: wynik = "Ustka"; break;
                                case 53: wynik = "Warszawa"; break;
                                case 54: wynik = "Wieluń"; break;
                                case 55: wynik = "Włodawa"; break;
                                case 56: wynik = "Wrocław"; break;
                                case 57: wynik = "Zakopane"; break;
                                case 58: wynik = "Zamość"; break;
                                case 59: wynik = "Zielona Góra"; break;
   
  }
  return wynik;
}
function stopienOciepleniaDachu(x){
  let wynik = "";
  switch(x){
    case 1: wynik = "Nieocieplony"; break;
    case 2: wynik = "Częściowo ocieplony"; break;
    case 3: wynik = "Całkowicie ocieplony"; break;
  }
  return wynik;
}
function stopienOciepleniaZ(x){
  let wynik = "";
  switch(x){
    case 1: wynik = "Nieocieplone"; break;
    case 2: wynik = "Częściowo ocieplone"; break;
    case 3: wynik = "Całkowicie ocieplone"; break;
  }
  return wynik;
}
function typBudynku(x){
  let wynik = "";
  switch(x){
    case 1: wynik = "Mieszkanie/Segment"; break;
    case 2: wynik = "Dom jednorodzinny"; break;
    
  }
  return wynik;
}
function typOkien(x){
  let wynik = "";
  switch(x){
    case 1: wynik = "Okna starego typu (skrzynkowe, i/lub skręcane)"; break;
    case 2: wynik = "Okna częściowo wymienione na nowoczesne"; break;
    case 3: wynik = "Nowoczesne okna dwu- lub trzyszybowe"; break;
    
  }
  return wynik;
}
function wiekB(x){
  let wynik = "";
  switch(x){
    case 1: wynik =  "wybudowany do 1945 roku"; break;
                                case 2: wynik =  "wybudowany w latach 1945 - 1955"; break;
                                case 3: wynik =  "wybudowany w latach 1956 - 1970"; break;
                                case 4: wynik =  "wybudowany w latach 1971 - 1981"; break;
                                case 5: wynik =  "wybudowany w latach 1982 - 1990"; break;
                                case 6: wynik =  "wybudowany w latach 1995 - 2007"; break;
                                case 7: wynik =  "wybudowany w latach 2008 - 2013"; break;
                                case 8: wynik =  "wybudowany w roku 2014 lub później"; break;
                                case 9: wynik =  "Dom energooszczędny NF40"; break;
                                case 10: wynik =  "Dom pasywny NF15"; break;
  }
  return wynik;
}
function zrodloGrzania(x){
  let wynik = "";
  switch(x){
    case 0: wynik =  "wybierz";break;
                                case 1: wynik =  "Kocioł gazowy";break;
                                case 2: wynik =  "Kocioł olejowy";break;
                                case 3: wynik =  "Kocioł na biomasę (pelety)";break;
                                case 4: wynik =  "Kocioł na gaz płynny LPG";break;
                                case 5: wynik =  "Kocioł węglowy";break;
                                case 6: wynik =  "Kocioł węglowy - ekogroszek";break;
                                case 7: wynik =  "Energia elektryczna";break;
                                case 8: wynik =  "Ciepło sieciowe";break;
                                case 9: wynik =  "Pompa ciepła";break;
                                case 10: wynik =  "Powietrzna pompa ciepła";break;
                                case 11: wynik =  "Gruntowa pompa ciepła";break;
                                case 12: wynik =   "Kolektor słoneczny";break
  }
  return wynik;
}
function zrodloGrzania2(x){
  let wynik = "";
  switch (x){
    case 0: wynik =  "Istniejące źródło ciepła do ogrzewania";break;
    case 1: wynik =  "Kocioł na biomasę (pelety)";break;
    case 2: wynik =  "Kocioł gazowy";break;
    case 3: wynik =  "Powietrzna pompa ciepła";break;
    case 4: wynik =  "Gruntowa pompa ciepła";break;
  }
  return wynik;
}
function ociepp(x){
  
  let wynik = "";
  let z = ""
  if (x!=""){
  for(let i = 0; i < x.length; i++){
    switch(Number(x[i])){
      case 1: 
        z="Ściany zewnętrzne";
        break;
      case 2:
        z = "Ocieplenie dachu/stropu nad najwyższą kondygnacją";
        break;
      case 3:
        z = "Ocieplenie podłogi";
        break;
    }
    if (i == 0) wynik+=z
    else wynik+=", "+z
  }
}
  return wynik;
}

const MyDoc = props => {
  
  let kondygnacje = props.txt1[0]
  let m = miasto(Number(props.txt1[1]));
  let powPoj = props.txt1[2]
  let laczna = kondygnacje*powPoj
  let dach = stopienOciepleniaDachu(Number(props.txt1[3]))
  let zew = stopienOciepleniaZ(Number(props.txt1[4]))
  let budynek = typBudynku(Number(props.txt1[5]))
  let okna = typOkien(Number(props.txt1[6]))
  let wiek = wiekB(Number(props.txt1[7]))
  let cieplo = zrodloGrzania(Number(props.txt1[8]))
  let woda = zrodloGrzania(Number(props.txt1[9]))
  let fotowoltanika = (props.txt2[0] ? "TAK" : "NIE")
  let cieplaWUZ = (props.txt2[1] ? "TAK" : "NIE")
  let instalacjaGrzewcza = (props.txt2[2] ? "TAK" : "NIE")
  let kolektory = (props.txt2[3] ? "TAK" : "NIE")
  let wentylacja = (props.txt2[4] ? "TAK" : "NIE")
  let wymianaOkien = (props.txt2[5] ? "TAK" : "NIE")
  let cieplo2 = zrodloGrzania2(Number(props.txt2[6]))
  let woda2 = zrodloGrzania2(Number(props.txt2[7]))
  let ociep = ociepp(props.txt2[8])

  let enDiff = props.txt3[0]
  
  let enCostDiff =  props.txt3[1]
  
  let co2Diff =  props.txt3[2]
  
  let pylDiff = props.txt3[3]
 
  let lzoDiff = props.txt3[4]
 
  let noxDiff = props.txt3[5]

  let sm = props.txt3[6]
  let buki = props.txt3[7]
  let wiad = props.txt3[8]
  let bal = props.txt3[9]
  let od = props.txt3[10]

  //let oszcz = ( props.txt3[23] > 0) ? props.txt3[23].toLocaleString('pl') : 0
  return (
  
  <Document>
    <Page size="A4" style={newstyles.page}>
      <View  style={newstyles.fullwn}>
      <Image
        style={newstyles.logo}
        src={logo}
      />
     
      </View>
      <View style={newstyles.fullw}>
        <Text style={newstyles.nagl}>Kalkulator oszczędności energii i wpływu na środowisko</Text>
        <Text style={newstyles.nagl2}>CHARAKTERYSTYKA NIERUCHOMOŚCI</Text>
        
      </View>
      <View style={newstyles.section}>
        <Text style={newstyles.text1}>Typ budynku: {budynek}</Text>
        <Text style={newstyles.text1}>Wiek budynku: {wiek}</Text>
        <Text style={newstyles.text1}>Miasto/miejscowość: {m}</Text>
        <Text style={newstyles.text1}>Liczba kondygnacji ogrzewanych: {kondygnacje}</Text>
        <Text style={newstyles.text1}>Powierzchnia ogrzewana poj. kondygnacji (m²): {powPoj}</Text>
        <Text style={newstyles.text1}>Łączna powierzchnia ogrzewana (m²): {laczna}</Text>
      </View>
      <View style={newstyles.section}>
        <Text style={newstyles.text1}>Stopień ocieplenia ścian zewnętrznych: {zew}</Text>
        <Text style={newstyles.text1}>Stopień ocieplenia dachu/stropu: {dach}</Text>
        <Text style={newstyles.text1}>Typ/rodzaj okien: {okna}</Text>
        <Text style={newstyles.text1}>Źródło ciepła do ogrzewania:  {cieplo}</Text>
        <Text style={newstyles.text1}>Sposób przygotowania ciepłej wody użytkowej: {woda}</Text>
        
      </View>
      <View style={newstyles.section1}>
        <View style={newstyles.fullw}>
          <Text style={newstyles.nagl2}>PLANOWANA MODERNIZACJA</Text>
                  
        </View>
        <View style={newstyles.fullw}>
          
              <Text style={newstyles.text1}>Ocieplenie: {ociep}</Text>
          
        </View>
        <View style={newstyles.fullw}>
          
              <Text style={newstyles.text1}>
                Źródło ciepła do ogrzewania: {cieplo2}
              </Text>  
          
        </View>
        <View style={newstyles.fullw}>
            
                <Text style={newstyles.text1}>
                  Źródło ciepła dla c.w.u.: {woda2}
                </Text> 
              
        </View>
      </View>
      <View style={newstyles.section}>
          
            <Text style={newstyles.text1}>
              Wymiana okien i drzwi: {wymianaOkien}
            </Text> 
        
            <Text style={newstyles.text1}>
              Instalacja grzewcza:  {instalacjaGrzewcza}
            </Text> 
           
            <Text style={newstyles.text1}>
              Instalacja c.w.u: {cieplaWUZ}
            </Text> 
           
                
      </View>
      <View style={newstyles.section}>
         
            <Text style={newstyles.text1}>
              Wentylacja: {wentylacja}
            </Text> 
            
            <Text style={newstyles.text1}>
              Instalacja fotowoltaiczna: {fotowoltanika}
            </Text>
            
            <Text style={newstyles.text1}>
              Termiczne kolektory słoneczne: {kolektory}
            </Text>
                           
      </View>
     
        <View style={newstyles.fullw}>
          <Text style={newstyles.nagl2}>KORZYŚCI DLA TWOJEGO PORTFELA</Text>
                  
        </View>  
     
      <View style={newstyles.section1}>        
        <View style={newstyles.fullw5}>
          <View>
          <Text style={[newstyles.text1,{paddingTop:10}]}>
            Inwestycja w wybrane technologie przyniesie roczne oszczędności w wysokości: </Text>
          </View>
          <View>
            <Text style={newstyles.ziel}>{enCostDiff} zł rocznie</Text>
          </View>
        </View>
      </View> 
      <View style={newstyles.fullw}>
        <Text style={newstyles.nagl2}>KORZYŚCI DLA ŚRODOWISKA</Text>
                
      </View> 
      <View style={newstyles.section}>
        <View style={newstyles.fullw4}><View style={newstyles.colp}><Text style={newstyles.text1}>Zmniejszenie zużycia energii (kWh/rok):"</Text></View><View style={newstyles.coll}><Text style={newstyles.ziel}> {enDiff}</Text></View></View>
        <View style={newstyles.fullw4}><View style={newstyles.colp}><Text style={newstyles.text1}>Zmniejszenie emisji CO2 (ton/rok):</Text></View><View style={newstyles.coll}><Text style={newstyles.ziel}> {co2Diff}</Text></View></View>
        <View style={newstyles.fullw4}><View style={newstyles.colp}><Text style={newstyles.text1}>Zmniejszenie emisji pyłu (kg/rok):</Text></View><View style={newstyles.coll}><Text style={newstyles.ziel}> {pylDiff}</Text></View></View>
        <View style={newstyles.fullw4}><View style={newstyles.colp}><Text style={newstyles.text1}>Zmniejszenie emisji LZO (kg/rok):</Text></View><View style={newstyles.coll}><Text style={newstyles.ziel}> {lzoDiff}</Text></View></View>
        <View style={newstyles.fullw4}><View style={newstyles.colp}><Text style={newstyles.text1}>Zmniejszenie emisji NOx (kg/rok):</Text></View><View style={newstyles.coll}><Text style={newstyles.ziel}> {noxDiff}</Text></View></View>
      </View>
      <View style={newstyles.section}>
        <View style={newstyles.fullw4}><View style={newstyles.coll}><Text style={newstyles.ziel}>{sm} </Text></View><View style={newstyles.colp}><Text style={newstyles.text1}>Tyle razy naładowałbyś swój smartfon, gdyby zaoszczędzona energia była energią elektryczną. *</Text></View></View>
        <View style={newstyles.fullw4}><View style={newstyles.coll}><Text style={newstyles.ziel}>{buki} </Text></View><View style={newstyles.colp}><Text style={newstyles.text1}>Taka liczba buków jest w stanie zaabsorbować rocznie ilość CO2, o jaką zmniejszono emisję. **</Text></View></View>
        <View style={newstyles.fullw4}><View style={newstyles.coll}><Text style={newstyles.ziel}>{wiad} </Text></View><View style={newstyles.colp}><Text style={newstyles.text1}>Taką liczbę wiaderek wypełni ilość pyłu, o jaką zmniejszono emisję.</Text></View></View>
        <View style={newstyles.fullw4}><View style={newstyles.coll}><Text style={newstyles.ziel}>{bal} </Text></View><View style={newstyles.colp}><Text style={newstyles.text1}>Taką liczbę 5-litrowych balonów wypełni LZO, o jaką zmniejszono emisję.</Text></View></View>
        <View style={newstyles.fullw4}><View style={newstyles.coll}><Text style={newstyles.ziel}>{od} </Text></View><View style={newstyles.colp}><Text style={newstyles.text1}>Tyle pełnych oddechów czystym powietrzem odpowiada objętości nie wyemitowanego NOx. ***</Text></View></View>

        
      </View>


      <View  style={newstyles.fullwf}>
      
        
      </View>
      <View>
        <Text style={[newstyles.ziel, {fontWeight:"bold"}, {fontSize:9}]}>PolREFF</Text>
        <Text style={[newstyles.ziel, {fontSize:8}, {marginBottom:5}]}>Polski Program Finansowania Efektywności Energetycznej w Budynkach Mieszkalnych </Text>
        <Text style={newstyles.textf}>
          ul. J. Rymera 3/4, 40-048 Katowice
        </Text>
        <Text style={newstyles.textf}>
        e-mail: biuro@polreff.org, www.polreff.org
        </Text>
      </View>
    </Page>
  </Document>
)
  }

const PDF = props => {
    return (
      <div>
        <PDFDownloadLink document={<MyDoc txt1={props.x} txt2={props.y} txt3={props.z}/>} fileName="Kalkulator.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Generowanie PDF....." : "Wygeneruj PDF"
          }
        </PDFDownloadLink>
      </div>
    );
  };
export default PDF;